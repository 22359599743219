import React, { useEffect } from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterFour from '../common/footer/FooterFour';


import AstralContactt from '../elements/astralservice/astralconta';
function GetOffer  ()  {

    return (
        <>
            <SEO title="Teklif Al" />
            <main className="page-wrapper">
               
  <div>
  <HeaderTopBar />
  <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
</div>
 
  
            {/* End Slider Area  */}
               {/* Start Service Area  */}
               <div className="rn-service-area" style={{ paddingTop: '80px' }}>
               <div className="container">
                   <div className="row">
                       <AstralContactt></AstralContactt>
                       
                   </div>
                 
           
               </div>
               </div>
    

               <FooterFour/>
               </main>
        </>
    )
}

export default GetOffer;

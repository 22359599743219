import React, { useEffect,useContext } from 'react';
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterFour from '../common/footer/FooterFour';
import LanguageContext from '../LanguageContext';

function AirFre  ()  {
  const { translations } = useContext(LanguageContext);

    return (
        <>
        <div className="page-wrapper" >
        <div>
        <HeaderTopBar />
        <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
      </div>
    <div>
    <div style={{minHeight:"100vh" ,paddingTop:70}}>
    <div className="container">
    <div className="row">
      <div className="col-md-1 zra zcsp">
      <div class="vertical-bar">
      <div class="moving-square"></div>
    </div>
      </div>
  
      <div className="col-md-11">
        <div className="text-content">
          <h3 className="newakefont styleone13x" >{translations.navtwotwo}</h3>
          <p className="newakefont" style={{fontSize:"19px",paddingBottom:0,marginBottom:0}}>
          {translations.servshipexp}
          </p>
        </div>
      </div>
  

    </div>
  </div>
      <div style={{width:"100%",textAlign:"center",marginTop:"-15px"}}>
      <div> <h1 className='newakefont outline-text textrab'>{translations.bls}</h1></div>
      <div className='' style={{width:"100%",display:"flex",justifyContent:"center" }}> <div className='cesitli-koseler styleone12x' ><img className='virab ' src={process.env.PUBLIC_URL + '/images/bg/ship.png'} alt="Plane" /></div> </div>

  
    </div>
    </div>
  
  </div>
  <FooterFour></FooterFour>

      </div>

        </>
    )
}

export default AirFre;

import React, { useEffect, useRef, useContext } from "react";
import LanguageContext from "../LanguageContext";
import { WindowSharp } from "@mui/icons-material";

function ResponsiveText() {
  const textRef = useRef(null);
  const { translations, language } = useContext(LanguageContext);

  useEffect(() => {
    const adjustFontSize = () => {
      const element = textRef.current;
      if (!element) return;

      // Metin uzunluğunu kontrol et
      const textLength =
        (translations.maintitle?.length || 0) +
        (translations.maintitlecontinue?.length || 0);
        let fontSize;
        let Width;
         fontSize = textLength > 42 ? 115 : 135; // Daha uzun metinlerde daha küçük font başlat
         Width = textLength > 42 ? 56 : 56; // Daha uzun metinlerde daha küçük font başlat
        if(window.screen.width>1440&&window.screen.width<1650)
          {
            fontSize = textLength > 42 ? 115 : 125  ; // Daha uzun metinlerde daha küçük font başlat
            Width = textLength > 42 ? 56 : 56; // Daha uzun metinlerde daha küçük font başlat
          }
          if(window.screen.width>1368&&window.screen.width<1441)
          {
            fontSize = textLength > 42 ? 110 : 110  ; // Daha uzun metinlerde daha küçük font başlat
            Width = textLength > 42 ? 56 : 56; // Daha uzun metinlerde daha küçük font başlat
          }
          if(window.screen.width>1280&&window.screen.width<1369)
          {
            fontSize = textLength > 42 ? 100 : 105  ; // Daha uzun metinlerde daha küçük font başlat
            Width = textLength > 42 ? 56 : 56; // Daha uzun metinlerde daha küçük font başlat
          }
          if(window.screen.width>1024&&window.screen.width<1280)
          {
            fontSize = textLength > 42 ? 100 : 105  ; // Daha uzun metinlerde daha küçük font başlat
            Width = textLength > 42 ? 56 : 56; // Daha uzun metinlerde daha küçük font başlat
          }
          if(window.screen.width>900&&window.screen.width<1025)
          {
            fontSize = textLength > 42 ? 90 : 90  ; // Daha uzun metinlerde daha küçük font başlat
            Width = textLength > 42 ? 56 : 56; // Daha uzun metinlerde daha küçük font başlat
          }
          if(window.screen.width>768&&window.screen.width<901)
          {
            fontSize = textLength > 42 ? 90 : 90  ; // Daha uzun metinlerde daha küçük font başlat
            Width = textLength > 42 ? 100 : 100; // Daha uzun metinlerde daha küçük font başlat
          }
          if(window.screen.width>100&&window.screen.width<769)
          {
            fontSize = textLength > 42 ? 45 : 50  ; // Daha uzun metinlerde daha küçük font başlat
            Width = textLength > 42 ? 100 : 100; // Daha uzun metinlerde daha küçük font başlat
          }
      // Başl
      // Başlangıç font boyutunu metin uzunluğuna göre ayarla


      element.style.fontSize = `${fontSize}px`;
      element.style.width = `${Width}%`;

      while (
        element.scrollHeight > element.offsetHeight ||
        element.scrollWidth > element.offsetWidth
      ) {
        fontSize -= 2;
        element.style.fontSize = `${fontSize}px`;
        if (fontSize <= 10) break; // Minimum font boyutu
      }
    };

    adjustFontSize();
    window.addEventListener("resize", adjustFontSize);

    return () => {
      window.removeEventListener("resize", adjustFontSize);
    };
  }, [translations]);

  return (
    <div
      ref={textRef}
      style={{
        height: "100%",
        position: "relative",
        overflow: "hidden",
        lineHeight: "1.2",
        wordWrap: "break-word",
      }}
    >
      <h1
        className="newakefontbold raxxxx"
        style={{
          marginBottom: "10px",
          fontSize: "inherit", // Dinamik boyut
        }}
      >
        {translations.maintitle}
      </h1>
      <h1
        className="newakefontitalicregular raxxxx"
        style={{
          width: "auto",
          fontSize: "inherit", // Dinamik boyut
        }}
      >
        {translations.maintitlecontinue}
        <span
          className="newakefontitalicregular 123"
          style={{
            marginLeft: "10px",
            color:"red",
            display: language === "zh" ? "none" : "inline", // "zh" dilindeyse gizle
          }}
        >
          .
        </span>
      </h1>
      <h1
        style={{
          width: "auto",
          position: "absolute",
          top: "10px",
          fontSize: "inherit", // Dinamik boyut
        }}
      ></h1>
    </div>
  );
}

export default ResponsiveText;

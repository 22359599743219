import React, { useState, useEffect } from "react";
import BlogListAstral from './BlogListAstral';
import { FaSpinner } from "react-icons/fa";
import filters from "./Filters";
import example1 from "../../../assets/images/examle1.jpg"


const BlogProp = ({ column, StyleVarProp }) => {
    const [getAllItems, setGetAllItems] = useState([]);
    const [dataVisibleCount, setDataVisibleCount] = useState(6);
    const [dataIncrement] = useState(3);
    const [activeFilter, setActiveFilter] = useState("");
    const [visibleItems, setVisibleItems] = useState([]);
 
    useEffect(() => {
      // fetchData(); // Gerçek API çağrısını yorum satırına aldık

      // Yerel veri
      const localData = [
        {
            id: 1,
            title: "Dünyanın En Büyük Yelkenli Yük Gemisi Tanıtıldı",
            body: `<p>Berge Bulk isimli firma, Berge Olympus adını verdiği yeni kargo gemisini tanıttı.<br>
                Sürdürülebilirlik konusu uzun zamandır üzerinde ciddi para ve zaman harcanan bir konu başlığı olarak öne çıkıyor. 
                Bu noktada lojistik firmaları, özellikle de deniz taşımacılığı yapan firmalar farklı alternatifler sunmaya çalışıyor. 
                Bu firmalardan biri de Berge Bulk.</p>
                <p>Ve şimdi, bu şirketten dikkat çeken bir hamle geldi. Berge Bulk, "Berge Olympus" adını verdiği son teknoloji kargo gemisini tanıttı. 
                Bu devasa yük gemisi, çok daha küçük yatlarda kullanılan yelkenleri kullanıyor. Berge Bulk, yeni gemisinde kullandığı teknoloji ile 
                lojistik sektöründe sürdürülebilirliği sağlayabileceğine inanıyor.</p>
                <p>Berge Olympus'un ilk yolculuğu Brezilya ile Çin arasında gerçekleştirilecek. Bu rotanın seçilmiş olma nedeni ise iki ülke arasındaki 
                deniz yolunun rüzgâr açısından oldukça verimli olması. Böylece yelkenler daha verimli kullanılabilecek.</p>`,
            image: "images/blog/dunyanin-en-buyuk-yelkenli-yuk-gemisi-tanitildi3.png",
            date: "2025-01-23",
        },
        {
            id: 2,
            title: "Akdeniz'deki kruvaziyerler rotayı Türkiye’ye çevirdi",
            body: `<p>Hamas güçleri ile İsrail arasındaki savaş hali nedeniyle Doğu Akdeniz’de seyir yapan gemilerde güvenlik en üst düzey olan ‘seviye 3’e 
                çıkarılırken, dev yolcu gemileri de rotalarını yeniden oluşturuyor. Kruvaziyer operatörleri gemileri İsrail'den, özellikle de Aşdod limanından 
                uzaklaştırmaya başladı. Bu gemilerin önemli bir kısmı İsrail limanları yerine rotayı Türkiye’ye çevirirken, bazıları ise Mısır ve Kıbrıs’a yöneldi.</p>
                <p>Celebrity Cruises’un 130.800 GT’lik yolcu gemisi Celebrity Apex, savaşın patladığı sırada İsrail’in Aşdod Limanı’na uğramak üzereydi. 
                Ancak gemi güvenlik endişeleri nedeniyle dev gemi yön değiştirerek pazar günü Kıbrıs'ın Limasol kentine yanaştı.</p>`,
            image: "images/blog/cruise2-k6o9_cover.jpg",
            date: "2025-01-22",
        },
        {
            id: 3,
            title: "Gemiler kızağa çekiliyor",
            body: `<p>Küresel ekonomideki yavaşlamanın konteyner taşımacılığına etkisi derinleşiyor. Bazı rotalarda navlun 7 yıl önceki seviyeye geriledi. 
                Dev konteyner şirketleri, özellikle belirli hatlarda sefer iptallerini artırırken, maliyetleri azaltmak için bazı gemileri geçici olarak 
                hizmet dışına aldı. Böylece eylül ayında küresel konteyner kapasitesi yıllık bazda yüzde 7 azaldı.</p>
                <p>Drewry Dünya Konteyner Endeksi, 5 Ekim haftasında yüzde 1,1 düşüşle 40’lık konteyner başına 1.389,5 dolara geriledi. 
                Yıllık bazda yüzde 62’nin üzerinde gerileyen endeks, Ocak 2022’ye göre yüzde 85’ten fazla düşüş gösterdi.</p>`,
            image: "images/blog/navlun2-lkmk_cover.jpg",
            date: "2025-01-21",
        },
    ];
    

      setVisibleItems(localData.slice(0, 3)); // İlk 3 blog yazısını alıyoruz
  }, []);
    
      const fetchData = async () => {
        try {
          const response = await fetch("https://mrtportservices.com:5000/getData?blog=blog&origin=startlog"); // API endpointinizi buraya ekleyin
          const result = await response.json();
          setVisibleItems(result.slice(0, 3));

        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };


    return (
        <>
            <div className="col-lg-12">
                <div className="row row--15">
                    {visibleItems.map((item) => (
                        <div key={item.id} className={column}>
                            <BlogListAstral StyleVar={StyleVarProp} data={item} />
                        </div>
                    ))}
                </div>
            </div>
           
        </>
    )
}

export default BlogProp;

import React, { useEffect, useState } from 'react';

const ShipsGoMap = () => {
    const [iframeSrc, setIframeSrc] = useState('https://shipsgo.com/iframe/where-is-my-container/default-container-code');

    useEffect(() => {
        // Dinamik olarak yeni konteyner kodunu yüklemek için event listener ekler.
        const ShipsgoMessagesListener = (event) => {
            if (event.data.Action === 'LoadNewContainerCode') {
                setIframeSrc(`https://shipsgo.com/iframe/where-is-my-container/${event.data.Parameters.ContainerCode}`);
            }
        };

        window.addEventListener('message', ShipsgoMessagesListener);

        // Component unmount olduğunda event listener'ı temizler.
        return () => {
            window.removeEventListener('message', ShipsgoMessagesListener);
        };
    }, []);

    useEffect(() => {
        // URL'deki query parametresini okur
        const urlParams = new URLSearchParams(window.location.search);
        let defaultQuery = urlParams.get('query');

        // Eğer query yoksa varsayılan container kodu
        if (!defaultQuery) {
            defaultQuery = 'default-container-code';
        }

        // İlk yüklendiğinde veya URL parametresi değiştiğinde iframe src'sini günceller.
        setIframeSrc(`https://shipsgo.com/iframe/where-is-my-container/${defaultQuery}`);
    }, []);

    return (
        <div style={{marginBottom:40,paddingTop:40}}>
                    <h1 className='newakefont' style={{fontSize:40,marginLeft:"5%"}}>Canlı Konteyner Takibi</h1>

            <div style={{display:"flex",width:"100%",justifyContent:"flex-start",flexDirection:"column",alignItems:"center"}}>

            <iframe
                src={iframeSrc}
                id="IframeShipsgoLiveMap"
                style={{ height: '550px', width: '90%',border:"4px solid red" }}
                title="ShipsGo Live Tracking Map"
            />
                    </div>

        </div>
    );
};

export default ShipsGoMap;

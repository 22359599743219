import React, { useContext } from "react";
import { Link } from "react-router-dom";
import LanguageContext from "../../LanguageContext.js";
import LanguageDropdown from "./LanguageSelect.js";
import { FiArrowRight  } from "react-icons/fi";
import { position } from "stylis";

const Nav = () => {
  const { language, changeLanguage } = useContext(LanguageContext);
  const { translations } = useContext(LanguageContext);
  const handleClick = () => {
    window.location.href = '/getoffer';
  };
  const handleLanguageChange = (event) => {
    changeLanguage(event.target.value);
  };
  return (
    <ul className="mainmenu">
      <li className="has-droupdown newakefont">
        <ul style={{ margin: 0 }} className="navwreck">
          <Link to="/">{translations.navone}</Link>
        </ul>
      </li>
      <li className="has-droupdown newakefont">
        <Link to="#">
          {translations.navtwo}
          <ul className="submenu">
            <li>
              <Link to="/air-freight">{translations.navtwoone}</Link>
            </li>
            <li>
              <Link to="/sea-freight">{translations.navtwotwo}</Link>
            </li>
            <li>
              <Link to="/road-freight">{translations.navtwothree}</Link>
            </li>
         
          </ul>
        </Link>
      </li>
      <li className="has-droupdown newakefont">
        <Link to="#">
          {translations.navthree}
          <ul className="submenu">
            <li>
              <Link to="/news">{translations.navthreeone}</Link>
            </li>
            <li>
              <Link to="/gallery">{translations.navthreetwo}</Link>
            </li>
          </ul>
        </Link>
      </li>
      <li className="has-droupdown newakefont">
        <Link to="#">
          {translations.navfour}
          <ul className="submenu">
            <li>
              <Link to="/tarihce">{translations.hak1}</Link>
            </li>
     
          </ul>
        </Link>
      </li>
    
      <li className="has-droupdown newakefont">
        <ul className="navwreck">
          <Link to="/iletisim">{translations.navfive}</Link>
        </ul>
      </li>
      <li className="has-droupdown newakefont">
        <div className="btn-cont" style={{ marginTop: "-5px" }}>
          <LanguageDropdown
            language={language}
            changeLanguage={changeLanguage}
          />
        </div>
      </li>
      <li></li>
      <Link to="/tracker">
      <div className="evotrackbutton">
        <span className="newakefont" style={{color:"white",fontSize:20}}>EvoTracking</span>
      </div>
      </Link>
      <div className="vikart" style={{marginTop:20}}>
        <button onClick={handleClick} className="divra">
          <span>{translations.navsix}</span>
          <FiArrowRight />
        </button>
      </div>
    </ul>
  );
};
export default Nav;

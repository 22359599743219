import React, { useState, useContext, useRef } from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterFour from '../common/footer/FooterFour';
import LanguageContext from '../LanguageContext';
import { useStore } from "context/store";
import Slider from "react-slick";
import { GiRotaryPhone } from "react-icons/gi";
import { IoMailSharp } from "react-icons/io5";
import { BiSend } from "react-icons/bi";
import { SportsVolleyballTwoTone } from '@mui/icons-material';

function Otherweb() {
  const { translations } = useContext(LanguageContext);
  const { isDay, setIsDay } = useStore();

  // Aktif slide'ı takip etmek için state
  const [activeSlide, setActiveSlide] = useState(0);

  // Slider kontrolü için useRef
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    arrows: false, 
    afterChange: current => setActiveSlide(current), // Slider değiştiğinde aktif slide'ı ayarla
  };



  return (
    <>
      <div className="page-wrapper">
        <HeaderTopBar />
        <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />

        <div className="main-content" style={{paddingTop:40}}>
        <span className='newakefont'  style={{fontWeight:700,fontSize:60,paddingLeft:40}}>
        {translations.othercomdsp}
</span>

          <div className="card-container newakefont">
            {/* Kart 1 */}
            <div className="card"                onClick={() => window.open("https://mitlog.com.tr/", '_blank')}
>
              <h1 className="card-title newakefont">Mitlog Logistics & Shipping</h1>
              <p className="card-description" style={{color:"var(--color-antidark)"}}>
              Taşımacılığı kendine görev olarak edinen Mitlog Lojistik 15 yıllık tecrübeye sahip, tüm kadrosu profesyonellerden oluşan bir ekip tarafından kurulan; hızla değişen ve gelişen lojistik ihtiyaçlara cevap verecek alt yapı ve ulaşım ağlarını her geçen yıl daha da genişleten güçlü ve güvenilir uluslararası lojistik firmasıdır.

Müşterileri ve iş ortakları için İstanbul / Kozyatağı’nda bulunan Merkez ofis ile birlikte Türkiye ofislerinden dünyaya açılan kapı rolünü üzerinde taşıyan MİTLOG Lojistik; İzmir, Mersin ve New Jersey – USA şubeleri ile birlikte Mitlog Air & Sea , We Ekspress iştirak firmaları ile gücüne güç katmıştır.
              </p>
              <div className="contact-info">
                <div className="contact-item">
                  <GiRotaryPhone color="var(--color-primary)" size={30} />
                  <a href="tel:+902165046930" style={{ marginLeft: 10 }}>+90 216 504 69 30</a>
                </div>
                <div className="contact-item">
                  <IoMailSharp color="var(--color-primary)" size={25} />
                  <a href="mailto:info@mitlog.com.tr" style={{ marginLeft: 10 }}>info@mitlog.com.tr</a>
                </div>
              </div>
              <button
                className="custom-button"
                onClick={() => window.open("https://mitlog.com.tr/", '_blank')}
                style={{ color: "var(--color-antidark)" }}

              >
                {translations.othercomdgo}
                <BiSend color="var(--color-primary)" size={20} style={{ marginLeft: 5 }} />
              </button>
            </div>

            {/* Kart 2 */}
            <div className="card newakefont"                 
>
              <h1 className="card-title newakefont">MTL SHIP INC</h1>
              <p className="card-description">
              </p>
              <div className="contact-info" style={{display:"none"}}>
                <div className="contact-item">
                  <GiRotaryPhone color="var(--color-primary)" size={30} />
                  <a href="tel:+1234567890" style={{ marginLeft: 10 }}></a>
                </div>
                <div className="contact-item">
                  <IoMailSharp color="var(--color-primary)" size={25} />
                  <a href="mailto:america@mitlog.com" style={{ marginLeft: 10 }}></a>
                </div>
              </div>
              <button
  className="custom-button"
  style={{ color: "var(--color-antidark)" }}
>
                {translations.othercomdgo}
                <BiSend color="var(--color-primary)" size={20} style={{ marginLeft: 5 }} />
              </button>
            </div>
          </div>
        </div>

        <FooterFour />
      </div>
    </>
  );
}

export default Otherweb;

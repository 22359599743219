import create from 'zustand';

export const useStore = create((set) => ({
  countA: 0,
  countB: 0,
  isDay:true,
  setCountA: (value) => set({ countA: value }),
  setCountB: (value) => set({ countB: value }),
  setIsDay: (value) => set({ isDay: value }),

}));

import React from "react";
import {
  FiFacebook,
  FiTwitter,
  FiInstagram,
  FiLinkedin,
  FiChevronRight,
  FiMapPin,
  FiPhone,
} from "react-icons/fi";
import { Link } from "react-router-dom";

const HeaderTopBar = () => {
  return (
    <div className="header-top-bar">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 col-md-12 col-12">
            <div className="header-left"></div>
          </div>
          <div className="col-lg-8 col-md-12 col-12">
            <div className="header-right">
              <div className="address-content">
              <div className="btn-cont" style={{lineHeight:"initial",height:"initial",fontSize:"15px"}}>
              <FiMapPin />

              <a href="https://maps.app.goo.gl/bJBYyuRfkvNcQeuYA" 
              target="_blank" rel="noopener noreferrer" className="btnn newakefont">
              Küçükçekmece / İSTANBUL
                <span className="line-2"></span>
                <span className="line-4"></span>
              </a>
            </div>

            <div className="btn-cont" style={{lineHeight:"initial",height:"initial",fontSize:"15px"}}>
            <FiPhone />

            <a href="tel:+902129998686" 
            target="_blank" rel="noopener noreferrer" className="btnn newakefont">
            +90 212 999 86 86
               <span className="line-2"></span>
              <span className="line-4"></span>
            </a>
          </div>
          
              </div>
              <div className="social-icon-wrapper">
                <ul className="social-icon social-default icon-naked">
                  <li>
                    <Link to="twitter.com">
                      <FiFacebook />
                    </Link>
                  </li>
                  <li>
                    <Link to="twitter.com">
                      <FiTwitter />
                    </Link>
                  </li>
                  <li>
                    <Link to="instagram.com">
                      <FiInstagram />
                    </Link>
                  </li>
                  <li>
                    <Link to="linkdin.com">
                      <FiLinkedin />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTopBar;

import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Map, Source, Layer } from 'react-map-gl';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import mapboxgl from 'mapbox-gl';

const ContactInfoCustom = () => {
    useEffect(() => {
        const selector = '#root > div > div.rwt-contact-area > div:nth-child(1) > div > div > div.mapboxgl-control-container > div.mapboxgl-ctrl-bottom-right > div';
    
        const checkAndRemoveElement = () => {
          const element = document.querySelector(selector);
          if (element) {
            element.remove();
            clearInterval(intervalId);  
          }
        };
    
        const intervalId = setInterval(checkAndRemoveElement, 100);
    
        return () => {
          clearInterval(intervalId);
        };
    }, []);

    const mapRef = useRef();
    const mapboxAccessToken = 'pk.eyJ1IjoiZGFkZHlvMTkwIiwiYSI6ImNscjd3YWxvajBzenkya3Bhbzhzampka2EifQ.FPUHXXSZnVRX1kSdyPiMTg';

    const [viewState, setViewState] = useState({
        longitude: 28.80667313188095,
        latitude: 41.03828206644254,
        zoom: 15.8,
        pitch: 55,
        bearing: 200.6
    });

    const onMapLoad = useCallback((event) => {
        const map = event.target;
        const modelOrigin = [28.80763136524977, 41.02942397843518 + 0.0003];
        const modelAltitude = 0;
        const modelRotate = [Math.PI / 2, 0, 0];  // Adjust rotation as necessary
        const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
            modelOrigin,
            modelAltitude
        );
        const scaleFactor = 10;  // Scale factor for the model

        const modelTransform = {
            translateX: modelAsMercatorCoordinate.x,
            translateY: modelAsMercatorCoordinate.y,
            translateZ: modelAsMercatorCoordinate.z,
            rotateX: modelRotate[0],
            rotateY: modelRotate[1],
            rotateZ: modelRotate[2],
            scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits() * scaleFactor
        };

        const customLayer = {
            id: '3d-model',
            type: 'custom',
            renderingMode: '3d',
            onAdd: function (map, gl) {
                this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
                this.scene = new THREE.Scene();
                this.scene.add(new THREE.AmbientLight(0xffffff, 1));
                this.scene.add(new THREE.HemisphereLight(0xffeeb1, 0x080820, 1));
                const loader = new GLTFLoader();
                loader.load('https://sunum.progressytems.com/images/gallery/scene2.gltf', (gltf) => {
                    this.scene.add(gltf.scene);
                });

                this.renderer = new THREE.WebGLRenderer({
                    canvas: map.getCanvas(),
                    context: gl,
                    antialias: true
                });
                this.renderer.autoClear = false;
            },
            render: function(gl, matrix) {
                const rotationX = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(1, 0, 0), modelTransform.rotateX);
                const rotationY = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(0, 1, 0), modelTransform.rotateY);
                const rotationZ = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(0, 0, 1), modelTransform.rotateZ);

                const m = new THREE.Matrix4().fromArray(matrix);
                const l = new THREE.Matrix4().makeTranslation(modelTransform.translateX, modelTransform.translateY, modelTransform.translateZ)
                    .scale(new THREE.Vector3(modelTransform.scale, -modelTransform.scale, modelTransform.scale))
                    .multiply(rotationX)
                    .multiply(rotationY)
                    .multiply(rotationZ);

                this.camera.projectionMatrix = m.multiply(l);
                this.renderer.resetState();
                this.renderer.render(this.scene, this.camera);
            }
        };

        map.addLayer(customLayer);
    }, []);



    return (
        <div className="gallery-containerxx" style={{ width: "100%",position:"relative",zIndex:5}}>
            <Map
                ref={mapRef}
                {...viewState}
                style={{ width: "100%", height: "500px", borderRadius: 20 }}
                mapStyle="mapbox://styles/daddyo190/clvwxvebe029j01oc1cpa6dxl"
                mapboxAccessToken={mapboxAccessToken}
                onMove={evt => setViewState(evt.viewState)}
                onLoad={onMapLoad}
            >
                <Source id="mapbox-dem" type="raster-dem" url="mapbox://mapbox.mapbox-terrain-dem-v1" tileSize={512} maxzoom={14} />
                <Layer id="terrain-data" type="hillshade" source="mapbox-dem" layout={{ visibility: 'visible' }} />
            </Map>
 
        </div>
    );
};

export default ContactInfoCustom;

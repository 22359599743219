import React, { useContext } from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import animationData from '../assets/images/ZdBrTv9nso.json';
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterFour from '../common/footer/FooterFour';
import LanguageContext from '../LanguageContext';
import { useStore } from "context/store";
import RightVideoComponent from './mainpagevideo';
import ErasableShape from 'elements/worldmaps/erase';
import ResponsiveText from './ResponsiveText';


function Componew() {
  const { translations } = useContext(LanguageContext);
  const { isDay, setIsDay } = useStore();

  return (
    <>
      <div className="page-wrapper raxfwe" style={{marginTop:"90px"}}>

        <div >
        <div className="PrimaryHero_container___A_Ix PrimaryHero_initialized__ckSyn snipcss-33Vaj"><svg className="PrimaryHero_darkMask__e3xdf">
        <defs>
            <clipPath id="darkMask">
                <rect className="PrimaryHero_darkRect__W6OBK" height="100%" x="0" y="0"></rect>
            </clipPath>
            <clipPath id="mask">
                <rect className="PrimaryHero_maskRect__ImGjV" height="100%" x="1007" y="0"></rect>
            </clipPath>
        </defs>
    </svg>
    <div className="PrimaryHero_content__2aJ0w">
        <div className="PrimaryHero_innerContent__P7eOi">
            <div className="PrimaryHero_darkContent__DO3cj">
                <div className="PrimaryHero_titleWrapper__mNnLi style-YHnwn" id="style-YHnwn">
                    <div className="WaveMask_outerContainer__yZ629">
                        <div className="WaveMask_contentWrapper__GohgS">
                        <div className='padxevolog carx32' style={{  zIndex: 100 }}>
        <div >
        <ResponsiveText></ResponsiveText>
   
        </div>
      </div>
                        </div>
                        <div className="WaveMask_waveWrapper__LIIIs style-QsnQQ" id="style-QsnQQ"><svg className="WaveMask_wave__1C3Fe" style={{bottom:0}}>
                                <path d="M1728 0.553101V1116H0L0 4.10889C9.50698 7.47876 18.6028 12.8157 27.2396 19.7252C38.4172 28.6673 48.1188 32 56.6198 32C65.1208 32 74.8224 28.6673 86 19.7252C101.489 7.33398 118.454 0 136.62 0C154.785 0 171.751 7.33398 187.24 19.7252C198.417 28.6673 208.119 32 216.62 32C225.121 32 234.822 28.6673 246 19.7252C261.489 7.33398 278.454 0 296.62 0C314.785 0 331.751 7.33398 347.24 19.7252C358.417 28.6673 368.119 32 376.62 32C385.121 32 394.822 28.6673 406 19.7252C421.489 7.33398 438.454 0 456.62 0C474.785 0 491.751 7.33398 507.24 19.7252C518.417 28.6673 528.119 32 536.62 32C545.121 32 554.822 28.6673 566 19.7252C581.489 7.33398 598.454 0 616.62 0C634.785 0 651.751 7.33398 667.24 19.7252C678.417 28.6673 688.119 32 696.62 32C705.121 32 714.822 28.6673 726 19.7252C741.489 7.33398 758.454 0 776.62 0C794.785 0 811.751 7.33398 827.24 19.7252C838.417 28.6673 848.119 32 856.62 32C865.121 32 874.822 28.6673 886 19.7252C901.489 7.33398 918.454 0 936.62 0C954.786 0 971.751 7.33398 987.24 19.7252C998.417 28.6673 1008.12 32 1016.62 32C1025.12 32 1034.82 28.6673 1046 19.7252C1061.49 7.33398 1078.45 0 1096.62 0C1114.79 0 1131.75 7.33398 1147.24 19.7252C1158.42 28.6673 1168.12 32 1176.62 32C1185.12 32 1194.82 28.6673 1206 19.7252C1221.49 7.33398 1238.45 0 1256.62 0C1274.79 0 1291.75 7.33398 1307.24 19.7252C1318.42 28.6673 1328.12 32 1336.62 32C1345.12 32 1354.82 28.6673 1366 19.7252C1381.49 7.33398 1398.45 0 1416.62 0C1434.79 0 1451.75 7.33398 1467.24 19.7252C1478.42 28.6673 1488.12 32 1496.62 32C1505.12 32 1514.82 28.6673 1526 19.7252C1541.49 7.33398 1558.45 0 1576.62 0C1594.79 0 1611.75 7.33398 1627.24 19.7252C1638.42 28.6673 1648.12 32 1656.62 32C1665.12 32 1674.82 28.6673 1686 19.7252C1699.01 9.31512 1713.07 2.47437 1728 0.553101Z" fill="currentColor"></path>
                            </svg></div>
                    </div>
                </div>
            </div>
            <div className="PrimaryHero_lightContent__kFeWr PrimaryHero_initialized__ckSyn">
                <div className="PrimaryHero_titleWrapper__mNnLi style-LkDLb" id="style-LkDLb">
                    <div className="WaveMask_outerContainer__yZ629">
                        <div className="WaveMask_contentWrapper__GohgS">
                        <div className='padxevolog' style={{ position: "relative", zIndex: 100 }}>
        <div className="basee-container">
          <h1 style={{ marginBottom: 12 }} className="newakefont ar">EVOLOG LOGISTICS</h1>
          <h1 className="newakefont bar">
            {translations.maintitle}
          </h1>
          <ErasableShape />
        </div>
      </div>
                        </div>
                        <div className="WaveMask_waveWrapper__LIIIs style-C2ERL" id="style-C2ERL"><svg className="WaveMask_wave__1C3Fe" style={{bottom:0}}>
                                <path d="M1728 0.553101V1116H0L0 4.10889C9.50698 7.47876 18.6028 12.8157 27.2396 19.7252C38.4172 28.6673 48.1188 32 56.6198 32C65.1208 32 74.8224 28.6673 86 19.7252C101.489 7.33398 118.454 0 136.62 0C154.785 0 171.751 7.33398 187.24 19.7252C198.417 28.6673 208.119 32 216.62 32C225.121 32 234.822 28.6673 246 19.7252C261.489 7.33398 278.454 0 296.62 0C314.785 0 331.751 7.33398 347.24 19.7252C358.417 28.6673 368.119 32 376.62 32C385.121 32 394.822 28.6673 406 19.7252C421.489 7.33398 438.454 0 456.62 0C474.785 0 491.751 7.33398 507.24 19.7252C518.417 28.6673 528.119 32 536.62 32C545.121 32 554.822 28.6673 566 19.7252C581.489 7.33398 598.454 0 616.62 0C634.785 0 651.751 7.33398 667.24 19.7252C678.417 28.6673 688.119 32 696.62 32C705.121 32 714.822 28.6673 726 19.7252C741.489 7.33398 758.454 0 776.62 0C794.785 0 811.751 7.33398 827.24 19.7252C838.417 28.6673 848.119 32 856.62 32C865.121 32 874.822 28.6673 886 19.7252C901.489 7.33398 918.454 0 936.62 0C954.786 0 971.751 7.33398 987.24 19.7252C998.417 28.6673 1008.12 32 1016.62 32C1025.12 32 1034.82 28.6673 1046 19.7252C1061.49 7.33398 1078.45 0 1096.62 0C1114.79 0 1131.75 7.33398 1147.24 19.7252C1158.42 28.6673 1168.12 32 1176.62 32C1185.12 32 1194.82 28.6673 1206 19.7252C1221.49 7.33398 1238.45 0 1256.62 0C1274.79 0 1291.75 7.33398 1307.24 19.7252C1318.42 28.6673 1328.12 32 1336.62 32C1345.12 32 1354.82 28.6673 1366 19.7252C1381.49 7.33398 1398.45 0 1416.62 0C1434.79 0 1451.75 7.33398 1467.24 19.7252C1478.42 28.6673 1488.12 32 1496.62 32C1505.12 32 1514.82 28.6673 1526 19.7252C1541.49 7.33398 1558.45 0 1576.62 0C1594.79 0 1611.75 7.33398 1627.24 19.7252C1638.42 28.6673 1648.12 32 1656.62 32C1665.12 32 1674.82 28.6673 1686 19.7252C1699.01 9.31512 1713.07 2.47437 1728 0.553101Z" fill="currentColor"></path>
                            </svg></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="PrimaryHero_imageWrapper__ZU_Yb PrimaryHero_initialized__ckSyn">
        <div className="WaveMaskLarge_outerContainer__3gngB largeWave">
            <div className="WaveMaskLarge_contentWrapper__bi7_8"><RightVideoComponent></RightVideoComponent></div>
            <div className="WaveMaskLarge_waveWrapper__pLOJP style-rIaaE" id="style-rIaaE"><svg className="WaveMaskLarge_wave__6XDQN" style={{bottom:0}}>
                    <path d="M0 972.344V0.67139C6.12691 0.225906 12.2916 0 18.4932 0C87.2135 0 151.392 27.7384 209.986 74.6043C252.271 108.425 288.972 121.03 321.131 121.03C353.29 121.03 389.991 108.425 432.275 74.6043C490.87 27.7384 555.048 0 623.768 0C692.489 0 756.667 27.7384 815.262 74.6043C857.546 108.425 894.247 121.03 926.406 121.03C943.588 121.03 962.066 117.432 982 108.922V1085.9C963.826 1089.91 945.287 1092 926.406 1092C857.686 1092 793.507 1064.26 734.913 1017.4C692.628 983.575 655.927 970.97 623.768 970.97C591.609 970.97 554.909 983.575 512.624 1017.4C454.029 1064.26 389.851 1092 321.131 1092C252.411 1092 188.232 1064.26 129.638 1017.4C87.3531 983.575 50.6522 970.97 18.4932 970.97C12.4894 970.97 6.32724 971.41 0 972.344Z" fill="currentColor"></path>
                </svg></div>
        </div>
    </div>
</div>
        </div>
      </div>
    </>
  );
}

export default Componew;

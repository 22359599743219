import React, { useState, useEffect } from "react";
import BlogList from './BlogList';
import { FaSpinner } from "react-icons/fa";
import filters from "./Filters";


const BlogProp = ({ column, StyleVarProp }) => {
    const [getAllItems, setGetAllItems] = useState([]);
    const [dataVisibleCount, setDataVisibleCount] = useState(6);
    const [dataIncrement] = useState(3);
    const [activeFilter, setActiveFilter] = useState("");
    const [visibleItems, setVisibleItems] = useState([]);
    useEffect(() => {
        // fetchData(); // Gerçek API çağrısını yorum satırına aldık
    
        // Yerel veri
        const localData = [
            {
                id: 1,
                title: "Dünyanın En Büyük Yelkenli Yük Gemisi Tanıtıldı",
                body: `<p>Berge Bulk isimli firma, Berge Olympus adını verdiği yeni kargo gemisini tanıttı.<br>
                    Sürdürülebilirlik konusu uzun zamandır üzerinde ciddi para ve zaman harcanan bir konu başlığı olarak öne çıkıyor. 
                    Bu noktada lojistik firmaları, özellikle de deniz taşımacılığı yapan firmalar farklı alternatifler sunmaya çalışıyor. 
                    Bu firmalardan biri de Berge Bulk.</p>`,
                image: "images/blog/dunyanin-en-buyuk-yelkenli-yuk-gemisi-tanitildi3.png",
                date: "2025-01-23",
            },
            {
                id: 2,
                title: "Akdeniz'deki kruvaziyerler rotayı Türkiye’ye çevirdi",
                body: `<p>Hamas güçleri ile İsrail arasındaki savaş hali nedeniyle Doğu Akdeniz’de seyir yapan gemilerde güvenlik en üst düzey olan ‘seviye 3’e 
                    çıkarılırken, dev yolcu gemileri de rotalarını yeniden oluşturuyor.</p>`,
                image: "images/blog/cruise2-k6o9_cover.jpg",
                date: "2025-01-22",
            },
            {
                id: 3,
                title: "Gemiler kızağa çekiliyor",
                body: `<p>Küresel ekonomideki yavaşlamanın konteyner taşımacılığına etkisi derinleşiyor.</p>`,
                image: "images/blog/navlun2-lkmk_cover.jpg",
                date: "2025-01-21",
            },
        ];
    
        setGetAllItems(localData);
        setVisibleItems(localData.slice(0, dataVisibleCount)); // İlk belirlenen kadar veriyi al
    
    }, []);
      const fetchData = async () => {
        try {
          const response = await fetch("https://mrtportservices.com:5000/getData?blog=blog&origin=mrtprtsvc"); // API endpointinizi buraya ekleyin
          const result = await response.json();
          setGetAllItems(result.slice()); // Veriyi çekip ters çevirme
          setVisibleItems(result.slice(0, dataVisibleCount));
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
    const handleScroll = () => {
        if (
            window.innerHeight + document.documentElement.scrollTop ===
            document.documentElement.offsetHeight
        ) {
            // Sayfanın sonuna gelindiğinde yeni veri yükle
            handleLoadmorebl();
        }
    };

    const handleLoadmorebl = () => {
        let tempCount = dataVisibleCount + dataIncrement;
        if (dataVisibleCount >= getAllItems.length) {
            // Tüm veriler görüntülendi, yeni veri eklenmiyor
            return;
        } else {
            setDataVisibleCount(tempCount);
            setVisibleItems(getAllItems.slice(0, tempCount));
        }
    };

    return (
        <>
            <div className="col-lg-12">
                <div className="row row--15">
                    {visibleItems.map((item) => (
                        <div key={item.id} className={column}>
                            <BlogList StyleVar={StyleVarProp} data={item} />
                        </div>
                    ))}
                </div>
            </div>
     
        </>
    )
}

export default BlogProp;

import PropTypes from "prop-types";
import React, { useRef, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useStore } from "context/store";

const Logo = ({ image }) => {
    const shapeRefe = useRef(null);
    const { countA, countB, setCountA, setCountB } = useStore();

    useEffect(() => {
        const shape = shapeRefe.current;
        if (shape) {
            const rect = shape.getBoundingClientRect();
            const actualLeft = rect.left + window.pageXOffset;
            const actualTop = rect.top + window.pageYOffset;

            setCountA(actualLeft); // Gerçek 'left' değerini kaydet
            setCountB(actualTop);  // Gerçek 'top' değerini kaydet
        }
    }, []);

    return (
        <div className="logo">
            <Link to={process.env.PUBLIC_URL + "/"}>
                <div style={{ display: "flex" }}>
                    <img className="logo-light" src={process.env.PUBLIC_URL + image} alt="Corporate Logo" />
                </div>
            </Link>
        </div>
    );
}

Logo.propTypes = {
    image: PropTypes.string
};

export default Logo;

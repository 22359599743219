import FooterFour from 'common/footer/FooterFour';
import React from 'react';
import { FiUser, FiCalendar } from "react-icons/fi";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Link } from 'react-router-dom';
function BlogDetailsContent({ id }) {
  const localData = [
    {
      id: 1,
      title: "Dünyanın En Büyük Yelkenli Yük Gemisi Tanıtıldı",
      body: `<p>Berge Bulk isimli firma, Berge Olympus adını verdiği yeni kargo gemisini tanıttı.<br>
            Sürdürülebilirlik konusu uzun zamandır üzerinde ciddi para ve zaman harcanan bir konu başlığı olarak öne çıkıyor. 
            Bu noktada lojistik firmaları, özellikle de deniz taşımacılığı yapan firmalar farklı alternatifler sunmaya çalışıyor. 
            Bu firmalardan biri de Berge Bulk.</p>
            <p>Ve şimdi, bu şirketten dikkat çeken bir hamle geldi. Berge Bulk, "Berge Olympus" adını verdiği son teknoloji kargo gemisini tanıttı. 
            Bu devasa yük gemisi, çok daha küçük yatlarda kullanılan yelkenleri kullanıyor. Berge Bulk, yeni gemisinde kullandığı teknoloji ile 
            lojistik sektöründe sürdürülebilirliği sağlayabileceğine inanıyor.</p>
            <p>Berge Olympus'un ilk yolculuğu Brezilya ile Çin arasında gerçekleştirilecek. Bu rotanın seçilmiş olma nedeni ise iki ülke arasındaki 
            deniz yolunun rüzgâr açısından oldukça verimli olması. Böylece yelkenler daha verimli kullanılabilecek.</p>`,
      image: "images/blog/dunyanin-en-buyuk-yelkenli-yuk-gemisi-tanitildi3.png",
      date: "2025-01-23",
    },
    {
      id: 2,
      title: "Akdeniz'deki kruvaziyerler rotayı Türkiye’ye çevirdi",
      body: `<p>Hamas güçleri ile İsrail arasındaki savaş hali nedeniyle Doğu Akdeniz’de seyir yapan gemilerde güvenlik en üst düzey olan ‘seviye 3’e 
            çıkarılırken, dev yolcu gemileri de rotalarını yeniden oluşturuyor. Kruvaziyer operatörleri gemileri İsrail'den, özellikle de Aşdod limanından 
            uzaklaştırmaya başladı. Bu gemilerin önemli bir kısmı İsrail limanları yerine rotayı Türkiye’ye çevirirken, bazıları ise Mısır ve Kıbrıs’a yöneldi.</p>
            <p>Celebrity Cruises’un 130.800 GT’lik yolcu gemisi Celebrity Apex, savaşın patladığı sırada İsrail’in Aşdod Limanı’na uğramak üzereydi. 
            Ancak gemi güvenlik endişeleri nedeniyle dev gemi yön değiştirerek pazar günü Kıbrıs'ın Limasol kentine yanaştı.</p>`,
      image: "images/blog/cruise2-k6o9_cover.jpg",
      date: "2025-01-22",
    },
    {
      id: 3,
      title: "Gemiler kızağa çekiliyor",
      body: `<p>Küresel ekonomideki yavaşlamanın konteyner taşımacılığına etkisi derinleşiyor. Bazı rotalarda navlun 7 yıl önceki seviyeye geriledi. 
            Dev konteyner şirketleri, özellikle belirli hatlarda sefer iptallerini artırırken, maliyetleri azaltmak için bazı gemileri geçici olarak 
            hizmet dışına aldı. Böylece eylül ayında küresel konteyner kapasitesi yıllık bazda yüzde 7 azaldı.</p>
            <p>Drewry Dünya Konteyner Endeksi, 5 Ekim haftasında yüzde 1,1 düşüşle 40’lık konteyner başına 1.389,5 dolara geriledi. 
            Yıllık bazda yüzde 62’nin üzerinde gerileyen endeks, Ocak 2022’ye göre yüzde 85’ten fazla düşüş gösterdi.</p>`,
      image: "images/blog/navlun2-lkmk_cover.jpg",
      date: "2025-01-21",
    },
  ];

  const [newData, setData] = React.useState({
    id: 0,
    title: "",
    body: "",
    date: "",
    image: "",
  });

  React.useEffect(() => {
    // fetch fonksiyonu yorum satırına alındı
    // fetchData();

    // ID'ye karşılık gelen veriyi localData'dan bul ve setData ile ata
    const localItem = localData.find(item => item.id === Number(id));
    console.log("Bulunan veri:", localItem);

    if (localItem) {
      setData(localItem);
    }
  }, [id]);

  return (
    <>
      <div className="pad50" style={{display:"flex",justifyContent:"flex-start",alignItems:"center",flexDirection:"column",width:"100vw"}}>
      <div > <h1  style={{maxHeight:"100%",overflow:"hidden"}} className='newakefont outline-text textrab'>{newData.title}</h1></div>
      <div  style={{ width: "100%", position: "relative" }}>
      <div style={{display:"flex",justifyContent:"center",alignItems:"center",position:"relative"}}>
      <Link to="/news">
                  <div className='arrowxasd321312' ><FaArrowLeftLong /></div>
                </Link>
                <div >
                    <h1 style={{margin:0,marginLeft:20}} className="newakefont vitangri">{newData.title}</h1>
                  </div>
      </div>
            
                <div className="content text-center">
         

                  <div style={{position:"relative",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",marginTop:20}}>



                    <img className='darimage'  src={`${process.env.PUBLIC_URL}/${newData.image}`} alt="Blog Images" />
                    <div className='newakefont' 
     style={{ 
       color: "var(--color-antidark)", 
       fontSize: "14px", 
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
       padding: "5px 10px", 
       borderRadius: "5px",
       whiteSpace: "nowrap",
       opacity:.5
     }}>
  <FiCalendar />
  <span style={{ marginLeft: "5px", fontWeight: "600" }}>{newData.date}</span>
                      </div>
                  </div>
                </div>
              </div>

              <div  className="dar23 content newakefont " dangerouslySetInnerHTML={{ __html: newData.body }} />

        <div style={{width:"100vw"}}>
        <FooterFour></FooterFour>
</div>
      </div>
    </>
  );
}

export default BlogDetailsContent;

import React, { useContext } from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import animationData from '../assets/images/ZdBrTv9nso.json';
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterFour from '../common/footer/FooterFour';
import LanguageContext from '../LanguageContext';
import { useStore } from "context/store";

function AirFre() {
  const { translations } = useContext(LanguageContext);
  const { isDay, setIsDay } = useStore();

  return (
    <>
      <div className="page-wrapper" >
        <HeaderTopBar />
        <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
        <div >
          <div className="container" style={{paddingTop:70}}>
            <div className="row">
              <div className="col-md-1 zra zcsp">
                <div className="vertical-bar">
                  <div className="moving-square"></div>
                </div>
              </div>
              <div className="col-md-11">
                <div className="text-content">
                  <h3 className="newakefont styleone13x">{translations.aboutustitle}</h3>
                  <p className="newakefont" style={{ fontSize: '19px', paddingBottom: 0, marginBottom: 0 }}>
                    {translations.aboutuscontent}
                  </p>
                  <div style={{display:"flex",}} className='newakefont'>
                    <p style={{ fontSize: '19px',color:'var(--color-primary)'}} >{translations.aboutuscontent2title}</p>
                    <p style={{ fontSize: '19px',marginLeft:5}} >{translations.aboutuscontent2}</p>

                  </div>
                  <div style={{display:"flex",}} className='newakefont'>
                    <p style={{ fontSize: '19px',color:'var(--color-primary)'}} >{translations.aboutuscontent3title}</p>
                    <p style={{ fontSize: '19px',marginLeft:5}} >{translations.aboutuscontent3}</p>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ width: '100%', textAlign: 'center', marginTop: '-15px',marginBottom:100 }}>
            <h1 className="newakefont outline-text textrab">{translations.aboutustitle}</h1>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
              <div className="cesitli-koseler styleone12x" >
              <video 
  src={isDay ?`${process.env.PUBLIC_URL}/images/bg/animationwhite3.mp4` : `${process.env.PUBLIC_URL}/images/bg/newanimationblack.mp4` }
        alt="Service Video" 
        loop 
        autoPlay 
        muted 
        style={{ width: "100%", height: "auto"
        }} 
      />              
      </div>
            </div>
          </div>
        </div>
        <FooterFour />
      </div>
    </>
  );
}

export default AirFre;

import React, { useContext } from "react";
import ContactForm from "./ContactForm";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import LanguageContext from "../../LanguageContext";
import ContactInfoCustom from "elements/worldmaps/contactinfo";
import { useStore } from "context/store";

const ContactOne = () => {
  const { translations } = useContext(LanguageContext);
  const { isDay, setIsDay } = useStore();

  return (
    <>
      <div className="row row--15">
        <div className="col-lg-12">
          <div className="rn-contact-address mt_dec--30">
            <div>
              <h1 className="newakefont gord">{translations.contacttitle}</h1>
            </div>
            <div class="cesitli-koseler">
              <div className="red-black-filter" style={{ overflow: "hidden" }}>
                <ContactInfoCustom></ContactInfoCustom>
              </div>
            </div>
            <div className="row"></div>
          </div>
        </div>
      </div>

      <div className="row mt--40 row--15">
        <div className="col-lg-7">
          <ContactForm formStyle="contact-form-1" />
        </div>
        <div className="col-lg-5 mt_md--30 mt_sm--30 arrayze">
          <div className="full-width-div" style={{ marginTop: "-50px" }}>
            <img
              className="side-image"
              src={process.env.PUBLIC_URL + "/images/bg/phonered.png"}
              style={{ width: "90px", height: "auto" }}
              alt="Background"
            />

            <div className="inner">
              <h4
            
                style={{
                    marginBottom: "5px",
                  color: "var(--color-darkwhite)",
                  ...(isDay ? { borderBottom: "2px solid red" } : {}),
                }}
              >
                {translations.contactphone}
              </h4>
              <p style={{ marginBottom: "0px" }}>
                <a href="tel:+902129998686">+90 212 999 86 86</a>

              </p>
        
            </div>
          </div>

          <div className="full-width-div">
            <img
              className="side-image"
              src={process.env.PUBLIC_URL + "/images/bg/email.png"}
              style={{ width: "90px", height: "auto" }}
              alt="Background"
            />

            <div className="inner">
              <h4
                className="title"
  
                style={{
                  marginTop:10,
                  marginBottom:5,
                  color: "var(--color-darkwhite)",
                  ...(isDay ? { borderBottom: "2px solid red" } : {}),
                }}
              >
                {translations.contactemail}
              </h4>
              <p>
                <a href="mailto:salesairsea@evolog.com.tr">
                salesairsea@evolog.com.tr
                </a>
              </p>
            </div>
          </div>

          <div className="full-width-div">
            <a
              href="https://www.google.com/maps/dir/41.0279982,28.6848108/Yal%C4%B1,+No:,+Ba%C4%9Flar+Cd.+No:86,+%C3%87ukurova+Tower+%2F+34873+Kartal%2F%C4%B0stanbul/@40.9070334,28.6502856,11.25z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x14cac4a560bb95cb:0x7de3c324ef5aadb7!2m2!1d29.2209021!2d40.8980465?entry=ttu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="side-image"
                src={process.env.PUBLIC_URL + "/images/bg/gps.png"}
                style={{ width: "80px", height: "auto" }}
                alt="Background"
              />
            </a>

            <div className="inner">
              <h4
                className="title"
                style={{
                  marginLeft:10,
                  marginBottom:10,
                  marginTop:15,

                  color: "var(--color-darkwhite)",
                  ...(isDay ? { borderBottom: "2px solid red" } : {}),
                }}
              >
                {translations.contactadress}
              </h4>
              <p  style={{marginLeft:10,marginTop:-5}}>
                {" "}
                <a
                  href="https://maps.app.goo.gl/bJBYyuRfkvNcQeuYA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                 Halkalı Merkez, Dereboyu Cd. No:56, 34303 
                    <br /> Küçükçekmece / İSTANBUL
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactOne;

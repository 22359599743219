import React, { useEffect, useContext, useState } from "react";
import { useSpring, animated } from 'react-spring';
import { Link } from 'react-router-dom';
import LanguageContext from '../../LanguageContext';
import { useStore } from "context/store";

const AstralServiceDsg = () => {
  const { translations } = useContext(LanguageContext);
  const [activeTab, setActiveTab] = useState(0);
  const { isDay, setIsDay } = useStore();

  const animationProps = useSpring({
    from: { opacity: 0, transform: 'translateX(40%)' },
    to: { opacity: 1, transform: 'translateX(0)' },
    config: { duration: 1000 },
  });

  useEffect(() => {
    const tabs = document.querySelectorAll('.tabs li');
    const contents = document.querySelectorAll('.tab-content');
  
    function reset() {
      tabs.forEach((tab, i) => {
        tab.style.zIndex = 0;
        tab.classList.remove('active');
        contents[i].classList.remove('active');
      });
    }
  
    function showTab(i) {
      reset();
      tabs[i].style.opacity = 1;
      tabs[i].style.zIndex = 5;
      tabs[i].classList.add('active');
      contents[i].classList.add('active');
    }
  
    showTab(activeTab);
  }, [activeTab]);

  const handleNext = () => {
    setActiveTab((prevTab) => (prevTab + 1) % 3);
  };

  const handlePrev = () => {
    setActiveTab((prevTab) => (prevTab - 1 + 3) % 3);
  };

  // Aktif sekmeye göre video kaynağını belirleme
  const videoSrc = () => {
    switch (activeTab) {
      case 0:
        return isDay 
          ? `${process.env.PUBLIC_URL}/images/bg/flywhite3.mp4`
          : `${process.env.PUBLIC_URL}/images/bg/fly.mp4`;

      case 1:
        return isDay 
        ? `${process.env.PUBLIC_URL}/images/bg/shipvideowhite.mp4`
        : `${process.env.PUBLIC_URL}/images/bg/shipvideo.mp4`;

      case 2:
        return isDay 
        ? `${process.env.PUBLIC_URL}/images/bg/truckwhite312.mp4`
        : `${process.env.PUBLIC_URL}/images/bg/truckblack312.mp4`;
      default:
        return isDay 
        ? `${process.env.PUBLIC_URL}/images/bg/flywhite3.mp4`
        : `${process.env.PUBLIC_URL}/images/bg/fly.mp4`;

    }
  };

  return (
    <div className="unordcontainer">
  
  <div className="arrow-containerx hiddenarrow">
                  <button onClick={handlePrev} className="arrow left-arrow">←</button>
                  <button onClick={handleNext} className="arrow right-arrow">→</button>
                </div>
      <video 
        className='virab' 
        src={videoSrc()} 
        alt="Service Video" 
        loop 
        autoPlay 
        muted 
      />
       
      <div className="redkit">
        <div className="lefttex">
          <span className="newakefont  orrxs" >{translations.servtitle}</span>
        </div>
        <div>
          <div className="moon">
            <section className="sec">
              <ul className='tabs lefttex'>
                <div className="arrow-container xrawe">
                  <button onClick={handlePrev} className="arrow left-arrow">←</button>
                  <button onClick={handleNext} className="arrow right-arrow">→</button>
                </div>
                <li className='tab xcsdqw' tyle={{marginTop:0}}>
                  <article className='tab-content'>
                    <h3 className="newakefont">{translations.navtwoone}</h3>
                    <p className="newakefont">{translations.servairexp}</p>
                    <div>
                      <Link to="/air-freight" style={{color:"white"}}>

                      <div className="custom-home-right">
  <svg style={{opacity:1,display:"none"}} className="custom-svg" width="50" height="51" viewBox="0 0 50 51" fill="#B1DD40" xmlns="http://www.w3.org/2000/svg" data-a-o="" >
    <path d="..."></path>
  </svg>
  <button style={{color:" var(--color-new)"}} className="custom-button" id="custom-style-YWPCB">
    <span className="custom-button-content-before">[</span>
    <span className="custom-button-content">
      <span className="custom-button-items">
        <span className="custom-button-text">{translations.Buttonmore}</span>
        <span className="custom-hover-text-wrapper">
          <span className="custom-hover-text">{translations.Buttonmore} {translations.Buttonmore} {translations.Buttonmore} {translations.Buttonmore}</span>
        </span>
      </span>
    </span> 
    <span className="custom-button-content-after">]</span>
  </button>
</div> 
                        </Link>

                    </div>
                  </article>
                </li>
                <li className='tab xcsdqw'>
                  <article className='tab-content'>
                    <h3 className="newakefont">{translations.navtwotwo}</h3>
                    <p className="newakefont">{translations.servshipexp}</p>
                    <div>
                        <Link to="/sea-freight" style={{color:"white"}}>
                        <div className="custom-home-right">
  <svg style={{opacity:1,display:"none"}} className="custom-svg" width="50" height="51" viewBox="0 0 50 51" fill="#B1DD40" xmlns="http://www.w3.org/2000/svg" data-a-o="" >
    <path d="..."></path>
  </svg>
  <button style={{color:"var(--color-new)"}} className="custom-button" id="custom-style-YWPCB">
    <span className="custom-button-content-before">[</span>
    <span className="custom-button-content">
      <span className="custom-button-items">
        <span className="custom-button-text">{translations.Buttonmore}</span>
        <span className="custom-hover-text-wrapper">
          <span className="custom-hover-text">{translations.Buttonmore} {translations.Buttonmore} {translations.Buttonmore} {translations.Buttonmore}</span>
        </span>
      </span>
    </span> 
    <span className="custom-button-content-after">]</span>
  </button>
</div> 
                        </Link>
                    </div>
                  </article>
                </li>
                <li className='tab xcsdqw' tyle={{marginTop:0}}>
                  <article className='tab-content '>
                    <h3 className="newakefont">{translations.navtwothree}</h3>
                    <p className="newakefont">{translations.servroadexp}</p>
                    <div>
                        <Link to="/road-freight" style={{color:"white"}}>
                        <div className="custom-home-right">
  <svg style={{opacity:1,display:"none"}} className="custom-svg" width="50" height="51" viewBox="0 0 50 51" fill="#B1DD40" xmlns="http://www.w3.org/2000/svg" data-a-o="" >
    <path d="..."></path>
  </svg>
  <button style={{color:"var(--color-new)"}} className="custom-button" id="custom-style-YWPCB">
    <span className="custom-button-content-before">[</span>
    <span className="custom-button-content">
      <span className="custom-button-items">
        <span className="custom-button-text">{translations.Buttonmore}</span>
        <span className="custom-hover-text-wrapper">
          <span className="custom-hover-text">{translations.Buttonmore} {translations.Buttonmore} {translations.Buttonmore} {translations.Buttonmore}</span>
        </span>
      </span>
    </span> 
    <span className="custom-button-content-after">]</span>
  </button>
</div> 
                        </Link>
                    </div>
                  </article>
                </li>
              </ul>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AstralServiceDsg;

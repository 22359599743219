import React,{useContext} from 'react'

import LanguageContext from '../../LanguageContext.js';
import { useStore } from "context/store";

const NewsletterOne = ({extraClass, newsletetrStyle}) => {
    const { translations } = useContext(LanguageContext);
    const { isDay, setIsDay } = useStore();

    return (
        <div style={{position:"relative",zIndex:"16"}} className={`newsletter-area ${newsletetrStyle}`}>
            <div className="container">
                <div className={`row row--0 newsletter-wrapper align-items-center ${extraClass}`}>
                    <div className="col-lg-4">
                        <div className="newsletter-section-title">
                            <h3 className={`title ${isDay ? "newakefont444" : "newakefont333"}`}>{translations.fottertitle}</h3>

                            <p className={`description ${isDay ? "newakefont444" : "newakefont333"}`}>{translations.fottercontent}</p>
                            
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <form className="rn-newsletter mt_md--20 mt_sm--20" action="#">
                            <div className="form-group ">
                                <input className={isDay ? "rexwhite newakefont444" : "rexblack newakefont333 "} type="email" placeholder="Email Address" />
                                
                            </div>
                            <div className="form-group ">
                                <button className={`btn-default ${isDay ? "newakefont444" : "newakefont333"}`}>{translations.fotterbutton}</button>
            

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NewsletterOne;

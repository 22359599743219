import React, { useEffect } from 'react';
import poster from "../assets/images/poster.png"
import evologvideo from "../assets/images/evologvideo.mp4"
import ResponsiveText from './ResponsiveText';



const RightVideoComponent = () => {
  useEffect(() => {
    const ditherImages = document.querySelectorAll('.ditherImage');

    function setDitherImageSizesToPixelRatio() {
      let size = 8 / window.devicePixelRatio;
      Array.from(ditherImages).forEach(img => {
        img.setAttribute('width', size);
        img.setAttribute('height', size);
      });
    }

    setDitherImageSizesToPixelRatio();
    window.addEventListener("resize", setDitherImageSizesToPixelRatio);

    return () => {
      window.removeEventListener("resize", setDitherImageSizesToPixelRatio);
    };
  }, []);

  return (
    <div className="video-container height4332" >
                <div className='padxevolog carx' style={{  zIndex: 100 }}>
        <div >
        <ResponsiveText></ResponsiveText>
   
        </div>
      </div>
      <video
        className="video-right"
        loop
        autoPlay
        muted
        preload="none"
        poster={poster}
        style={{
          filter: 'url(#filter2)',
          width: '100%',
          height:'100%',
          objectFit: 'cover',
          imageRendering: 'crisp-edges'
        }}
      >
        <source src={evologvideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default RightVideoComponent;

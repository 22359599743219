import React, { useRef, useState, useEffect } from 'react';

const ErasableShape = () => {
  const shapeRef = useRef(null);
  const [transform, setTransform] = useState('');
  const [hovered, setHovered] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [initialLeft, setInitialLeft] = useState(null);
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const shape = shapeRef.current;
    if (shape) {
      const rect = shape.getBoundingClientRect();
      setInitialLeft(rect.left); // Başlangıç 'left' değerini kaydet
    }
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    });

    // Window resize olduğunda genişliği güncellemek için event listener ekleyelim
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClick = () => {
    if (isAnimating) {
      console.log("Animasyon devam ediyor");
      return;
    }

    const shape = shapeRef.current;
    if (shape) {
      const rect = shape.getBoundingClientRect();
      if (rect.left === initialLeft) { // Eğer 'left' değeri başlangıç değeri ile aynıysa animasyonu başlat
        setIsAnimating(true);

        const additionalOffset = (() => {
          if (windowSize.width > 1400) {
              return 60;  // 1400 px'den büyükse
          } else if (windowSize.width > 1000) {
              return 27;  // 1000 px'den büyükse
          } else if (windowSize.width > 700) {
              return 20;  // 700 px'den büyükse
          } else if (windowSize.width > 500) {
              return 15;  // 500 px'den büyükse
          } else {
              return 10;  // 500 px'den küçükse
          }
      })();
        const additionalOffsetY = windowSize.width > 590 ? 5 :30;

        const translateX = -rect.left + additionalOffset;
        const translateY = -rect.top+ additionalOffsetY;

        // Şekli yukarı sol köşeye taşıyıp boyutunu küçültme
        setTransform(`translate(${translateX}px, ${translateY}px) scale(0.5)`);
        setHovered(true);

        // 1.5 saniye bekledikten sonra orijinal konuma geri dön
        setTimeout(() => {
          setTransform(''); // Orijinal konuma geri dön
          setHovered(false);

          setTimeout(() => {
            setIsAnimating(false); // Animasyonun tamamlandığını işaretle
          }, 2000); // Dönüş animasyonunun tamamlanması için 2 saniye bekleme süresi
        }, 5000); // Hedef konumda 1.5 saniye bekleme süresi
      } else {
        console.log("Öğe başlangıç konumunda değil, animasyon başlatılmadı.");
      }
    }
  };

  return (
    <div
      className="erasable-shape"
      style={{
        width: '300px',
        height: '200px',
        backgroundColor: 'transparent',
        transition: 'transform 2s ease',
        transform: transform,
      }}
      ref={shapeRef}
      onClick={handleClick} // Click ile aksiyonu tetikleme
    >
      <button
        className={`eraser ${hovered ? 'hovered' : ''}`}
        style={{
          width: hovered ? '90px' : '20px',
          height: hovered ? '90px' : '20px',
          backgroundColor: '#eb1f25',
          color: '#fff',
          border: 'none',
          borderRadius: '50%',
          cursor: 'pointer',
          transition: 'width 0.3s ease, height 0.3s ease',
        }}
      >
      </button>
    </div>
  );
};

export default ErasableShape;

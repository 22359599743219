import React from 'react';
import {slugify} from "../../../utils";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

import { useStore } from "context/store";

const BlogListAstral = ({ data, StyleVar }) => {
    function limitContentByWords(content, limit) {
        const words = content.split(' ');
        const limitedWords = words.slice(0, limit);
        return limitedWords.join(' ');
      }
      const { isDay, setIsDay } = useStore();

      // Kullanım örneği
      const originalContent = "Bu içerik örnek bir metin içerir ve 150 kelimeden fazla olabilir.";
      const limitedContent = limitContentByWords(data.body, 15);

    return (
        <div className={`rnn-card ${StyleVar}`}>
            <div className="inner">
                <div className="thumbnail">

                    <Link to={process.env.PUBLIC_URL + `/blog-detay/${data.id}`} className="image">
                    <img 
  src={`${process.env.PUBLIC_URL}/${data.image}`} 
  alt="Blog Image" 
  className={`innerbreaker ${isDay ? "grayscalefillfillred" : "grayscalefillfill"}`} 
/>             
                    </Link>
                    </div>

                <div className="content">
                    <ul className="rn-meta-list">
                   
                        <li className='newakefont' style={{fontSize:"18px"}}>{data.date}</li>
                    </ul>
                    <h4 className="title newakefont">
                        <Link style={{color:"var(--color-body2rd)"}} to={process.env.PUBLIC_URL  + `/blog-detay/${data.id}`}>
                            {data.title}
                        </Link>
                    </h4>

                    <span className="newakefont">
                    <div className="newakefont" dangerouslySetInnerHTML={{__html: limitedContent}}></div>

                    </span>
                </div>
            </div>
        </div>
    )
}
BlogListAstral.propTypes = {
    data: PropTypes.object
};
export default BlogListAstral;

import React, { useState,useEffect } from 'react';
import {Link} from "react-router-dom";
import CalltoActionSeven from "../../elements/calltoaction/CalltoActionSeven";
import footerOne from "../../data/footer/footerOne.json";
import ScrollTop from "./ScrollTop";
import NewsletterOne from "./NewsletterOne";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import Logo from "../../elements/logo/Logo";
import exampleGif from '../../assets/images/sund545.gif'; // Doğru yolu burada belirtin
import { useStore } from "context/store";
import { Alert } from 'react-bootstrap';
const footerIntem =  footerOne[0];
const footerIntemOne =  footerOne[1];
const footerIntemTwo =  footerOne[2];
const footerIntemThree =  footerOne[3];
const footerIntemFour =  footerOne[4];
const footerIntemFive =  footerOne[5];

const indexOneLink = (footerIntemOne.quicklink);
const indexTwoLink = (footerIntemTwo.quicklink);
const indexThreeLink = (footerIntemThree.quicklink);
/*              <button  onClick={toggle}  className="ThemeButton_button__L5Q2F snipcss-rWhGx style-z3w9E" aria-label="Switch mode" id="style-z3w9E">
                <div className="ThemeButton_text__nAAK_ ThemeButton_business__J6vvT style-dIH6V" id="style-dIH6V">
      <div className="circle-container">
        <svg viewBox="0 0 300 300" width="300" height="300">
          <defs>
            <path
              id="circlePath"
              d="M 150, 150 m -130, 0 a 130,130 0 1,1 260,0 a 130,130 0 1,1 -260,0"
            />
          </defs>
          <text className="circle-text">
            <textPath href="#circlePath">
              {isDay ? (
                <>
                <tspan className="bold">FEELING DARK</tspan>
                  <tspan className="regular"> BLACK & RED </tspan>
                  <tspan className="bold">FEELING DARK</tspan>
                  <tspan className="regular"> BLACK & RED </tspan>
                  </>

              ) : (
                <>
                <tspan className="bold">FEELING SHINE</tspan>
                  <tspan className="regular"> WHITE & RED </tspan>
                  <tspan className="bold">FEELING SHINE</tspan>
                  <tspan className="regular"> WHITE & RED </tspan>
                </>
              )}
            </textPath>
          </text>
        </svg>
      </div>
    </div>
    <div className="ThemeButton_text__nAAK_ ThemeButton_gold__SOQk6 style-tTgQF" id="style-tTgQF"><svg viewBox="0 0 109 108" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_36_783)">
                <path d="M54.6062 7.6324L55.1213 0L58.2053 0.206107C58.9973 0.257634 59.5639 0.476622 59.8922 0.856632C60.2206 1.23664 60.3623 1.74547 60.3236 2.38311C60.3043 2.67939 60.2464 2.93702 60.1498 3.15601C60.0532 3.375 59.9244 3.55534 59.757 3.6906C59.5961 3.82586 59.3965 3.91603 59.1647 3.95468C59.3256 4.0062 59.4802 4.08349 59.6347 4.19299C59.7828 4.30248 59.918 4.4313 60.0274 4.59876C60.1369 4.75978 60.2206 4.95301 60.2721 5.16555C60.3236 5.38454 60.3429 5.62929 60.3236 5.89981C60.2914 6.40864 60.1755 6.80797 59.9759 7.10425C59.7763 7.40052 59.5059 7.60663 59.1454 7.71613C58.7848 7.83206 58.3534 7.87071 57.8383 7.83206L54.6126 7.61307L54.6062 7.6324ZM56.4927 6.15744L57.8899 6.25406C58.0379 6.26694 58.1731 6.24117 58.2826 6.18965C58.3921 6.13812 58.4822 6.04795 58.5401 5.93201C58.6045 5.81608 58.6432 5.66794 58.656 5.48115C58.6689 5.31369 58.6496 5.17199 58.5981 5.05606C58.5466 4.94012 58.4758 4.83707 58.3727 4.76622C58.2762 4.69537 58.1474 4.65029 57.9993 4.64385L56.6021 4.54723L56.4927 6.16388V6.15744ZM56.6923 3.19466L57.9993 3.28483C58.1796 3.29771 58.3212 3.2333 58.4307 3.10448C58.5401 2.97567 58.6045 2.79532 58.6174 2.58278C58.6367 2.29938 58.5917 2.10615 58.4822 1.99022C58.3727 1.87428 58.244 1.81632 58.0894 1.80344L56.7953 1.71326L56.6923 3.19466Z" fill="currentColor"></path>
                <path d="M60.388 7.99951L64.2512 1.00476L66.4274 1.4685L67.1163 9.42938L65.3135 9.04293L65.2169 7.60018L62.8411 7.0978L62.1779 8.37952L60.3816 7.99951H60.388ZM63.5751 5.62284L65.1461 5.95777L65.0109 2.72447L63.5751 5.62284Z" fill="currentColor"></path>
                <path d="M69.8721 10.5244C69.46 10.3762 69.1059 10.1959 68.8162 9.98332C68.5264 9.77077 68.314 9.49382 68.1852 9.15245C68.05 8.81109 68.0113 8.37955 68.0693 7.8514C68.1208 7.32325 68.2882 6.67273 68.5651 5.90627C68.8355 5.15913 69.1188 4.56657 69.4149 4.13504C69.7111 3.7035 70.0202 3.40078 70.3421 3.22044C70.664 3.04009 71.0053 2.95636 71.3658 2.96924C71.7264 2.98213 72.1063 3.05942 72.5054 3.20111C72.866 3.32993 73.1879 3.47163 73.4712 3.61333C73.7545 3.75503 73.9992 3.89673 74.1988 4.03198C74.3984 4.16724 74.5593 4.28962 74.6817 4.39911L74.2181 5.70016C74.1022 5.62931 73.9541 5.55202 73.7674 5.45541C73.5807 5.36524 73.3682 5.26218 73.1171 5.15913C72.8724 5.04964 72.6085 4.94658 72.3187 4.84353C72.0805 4.7598 71.8616 4.70827 71.6749 4.70183C71.4817 4.68895 71.3143 4.74048 71.1598 4.85641C71.0053 4.97235 70.8572 5.15913 70.7027 5.4232C70.5546 5.69372 70.3936 6.05441 70.2326 6.51171C70.0781 6.94324 69.9687 7.31037 69.9043 7.60665C69.8399 7.90293 69.827 8.14768 69.8721 8.34091C69.9172 8.53413 70.0073 8.68871 70.1554 8.81753C70.3035 8.94634 70.5159 9.05584 70.7864 9.15245C71.2757 9.32635 71.6813 9.46161 71.9968 9.55178C72.3123 9.64195 72.5634 9.70636 72.763 9.75145L72.2994 11.0525C72.1063 11.0525 71.8938 11.0396 71.662 11.001C71.4302 10.9623 71.1598 10.9044 70.8701 10.8335C70.5739 10.7562 70.2455 10.6532 69.8785 10.5179L69.8721 10.5244Z" fill="currentColor"></path>
                <path d="M72.8917 11.587L76.4071 4.7984L77.991 5.61639L76.581 8.33442L77.3794 8.74664L80.0127 6.66624L81.6867 7.53576L78.2743 10.0928L78.2421 14.363L76.5681 13.4935L76.6389 10.1765L75.8405 9.76429L74.4691 12.405L72.8853 11.587H72.8917Z" fill="currentColor"></path>
                <path d="M81.97 17.0232L85.7043 12.2698L84.1333 11.0332L85.1184 9.77722L89.6705 13.3583L88.6854 14.6143L87.1079 13.3712L83.3736 18.1245L81.97 17.0232Z" fill="currentColor"></path>
                <path d="M87.3525 21.8924C86.9855 21.5124 86.7022 21.1388 86.4962 20.7781C86.2902 20.4175 86.1936 20.0439 86.2 19.6574C86.2064 19.271 86.3481 18.8523 86.625 18.4015C86.8954 17.9506 87.3268 17.4418 87.9127 16.875C88.4986 16.3146 89.0201 15.9024 89.4772 15.6384C89.9408 15.3807 90.3593 15.2519 90.7456 15.2583C91.1319 15.2583 91.5054 15.3743 91.8595 15.5868C92.2136 15.7994 92.5742 16.1021 92.9412 16.4821C93.3146 16.8686 93.5979 17.2421 93.8039 17.6028C94.0035 17.9635 94.1001 18.3371 94.0872 18.7235C94.0743 19.11 93.9327 19.5222 93.6558 19.9795C93.379 20.4303 92.954 20.9392 92.3681 21.4995C91.7822 22.0663 91.2543 22.4785 90.7971 22.7362C90.34 22.9938 89.915 23.1162 89.5287 23.1162C89.1424 23.1162 88.7754 23.0002 88.4213 22.7877C88.0736 22.5751 87.7066 22.2724 87.3396 21.886L87.3525 21.8924ZM88.4986 20.7846C88.6917 20.9843 88.8784 21.1324 89.0587 21.2226C89.239 21.3127 89.4193 21.3385 89.606 21.2999C89.7927 21.2612 90.0116 21.1582 90.2627 20.9843C90.5138 20.8104 90.8036 20.5592 91.1448 20.2242C91.4925 19.8829 91.7629 19.593 91.9496 19.3547C92.1363 19.1164 92.2522 18.8974 92.2909 18.7106C92.3295 18.5239 92.3102 18.3435 92.22 18.1696C92.1299 17.9957 91.9883 17.8089 91.7951 17.6093C91.6019 17.4096 91.4217 17.2679 91.2543 17.1713C91.0869 17.0747 90.9066 17.0489 90.7199 17.0811C90.5267 17.1198 90.3078 17.2228 90.0567 17.3967C89.8056 17.5706 89.503 17.8282 89.1553 18.1696C88.8141 18.4981 88.5501 18.7815 88.3698 19.0198C88.1831 19.2581 88.0736 19.4771 88.0286 19.6639C87.9835 19.8507 88.0092 20.0375 88.0865 20.2178C88.1702 20.3981 88.3054 20.5849 88.505 20.791L88.4986 20.7846Z" fill="currentColor"></path>
                <path d="M91.6085 26.7488L98.0342 22.6138L99.7082 25.2159C100.14 25.8857 100.301 26.4654 100.191 26.9549C100.082 27.4444 99.7597 27.8631 99.2188 28.2109C98.9677 28.3719 98.7295 28.4749 98.4913 28.5329C98.2531 28.5844 98.0341 28.5844 97.8281 28.5329C97.6221 28.4814 97.4289 28.3783 97.2615 28.2173C97.313 28.3783 97.3452 28.5522 97.3517 28.739C97.3517 28.9258 97.3259 29.1061 97.2615 29.2929C97.1971 29.4797 97.0941 29.6536 96.9525 29.8275C96.8108 30.0014 96.6241 30.1624 96.3923 30.3106C95.9674 30.5875 95.5746 30.7357 95.2205 30.755C94.8664 30.7743 94.538 30.6777 94.229 30.4523C93.9264 30.2333 93.6302 29.9048 93.3533 29.4733L91.6021 26.7488H91.6085ZM93.9135 27.3864L94.6732 28.5651C94.7569 28.6939 94.8535 28.7841 94.963 28.8421C95.0724 28.9 95.1948 28.9194 95.3235 28.9C95.4523 28.8807 95.6004 28.8227 95.7549 28.7197C95.8966 28.6295 95.9931 28.5265 96.064 28.417C96.1283 28.3075 96.1605 28.1851 96.1605 28.0627C96.1605 27.9403 96.1155 27.818 96.0382 27.6892L95.2785 26.5105L93.9199 27.3864H93.9135ZM96.4116 25.7827L97.1199 26.884C97.2165 27.0322 97.3517 27.1159 97.5255 27.1224C97.6993 27.1288 97.8732 27.0773 98.0535 26.9613C98.2917 26.8068 98.4205 26.6522 98.4462 26.4976C98.472 26.343 98.4462 26.2013 98.3625 26.0725L97.6607 24.9776L96.4116 25.7827Z" fill="currentColor"></path>
                <path d="M96.1476 34.7548C95.9673 34.3683 95.8385 33.9948 95.7484 33.6276C95.6583 33.2669 95.6389 32.9191 95.6904 32.5842C95.742 32.2493 95.8836 31.9466 96.1218 31.6632C96.3601 31.3862 96.7142 31.135 97.1906 30.916L101.871 28.7712L102.612 30.3943L97.9375 32.5391C97.7315 32.6357 97.5834 32.7581 97.4997 32.9063C97.416 33.0544 97.3773 33.2283 97.3967 33.428C97.416 33.6276 97.4804 33.8466 97.5963 34.0914C97.7057 34.3297 97.828 34.5229 97.9697 34.6646C98.1049 34.8063 98.2594 34.8965 98.4333 34.9287C98.6007 34.9609 98.7874 34.9287 98.9999 34.8321L103.674 32.6873L104.415 34.3104L99.7338 36.4552C99.2574 36.6742 98.8389 36.7836 98.4719 36.7772C98.1049 36.7708 97.7765 36.687 97.4932 36.5067C97.2099 36.3263 96.9588 36.088 96.7399 35.7853C96.5275 35.4826 96.3279 35.1412 96.154 34.7483L96.1476 34.7548Z" fill="currentColor"></path>
                <path d="M98.3432 40.5773C98.2789 40.3712 98.2274 40.1651 98.1758 39.9525C98.1243 39.74 98.0857 39.5274 98.0471 39.3213C98.0084 39.1088 97.9762 38.9091 97.9569 38.7159C97.9312 38.5226 97.9183 38.3423 97.9119 38.1684L99.264 37.7626C99.309 37.9816 99.3605 38.2199 99.4249 38.4776C99.4893 38.7352 99.5601 39.0057 99.631 39.2698C99.7018 39.5403 99.779 39.7915 99.8499 40.0298C99.9207 40.2617 99.9979 40.4485 100.082 40.603C100.165 40.7512 100.268 40.8607 100.378 40.9186C100.487 40.9766 100.629 40.983 100.783 40.938L100.97 40.88C101.15 40.8285 101.266 40.7254 101.324 40.5837C101.382 40.442 101.382 40.2745 101.324 40.0942L101.157 39.5339C100.938 38.7996 100.925 38.1877 101.131 37.7047C101.337 37.2216 101.82 36.8673 102.586 36.6355L102.934 36.5324C103.649 36.3199 104.247 36.3714 104.717 36.687C105.187 37.009 105.548 37.563 105.786 38.3487C105.87 38.6386 105.941 38.922 105.992 39.1989C106.044 39.4759 106.089 39.7464 106.121 39.9976C106.153 40.2552 106.179 40.4807 106.185 40.6803L104.833 41.0861C104.769 40.7769 104.692 40.4291 104.595 40.0491C104.498 39.6691 104.408 39.3213 104.318 39.0121C104.254 38.806 104.183 38.6321 104.099 38.484C104.016 38.3359 103.913 38.2328 103.79 38.1813C103.668 38.1297 103.513 38.1233 103.333 38.1813L103.185 38.2264C102.979 38.2908 102.85 38.3938 102.786 38.5484C102.721 38.6965 102.728 38.9026 102.805 39.1474L103.011 39.8301C103.146 40.2874 103.179 40.6868 103.095 41.0281C103.011 41.3695 102.85 41.6658 102.606 41.9041C102.354 42.1424 102.052 42.3163 101.678 42.4258L101.311 42.5353C100.706 42.7156 100.217 42.7478 99.837 42.6319C99.4571 42.516 99.1545 42.2776 98.9227 41.917C98.6909 41.5563 98.4978 41.1119 98.3368 40.5837L98.3432 40.5773Z" fill="currentColor"></path>
                <path d="M99.3154 44.0167L106.81 42.516L107.158 44.2615L99.6631 45.7622L99.3154 44.0167Z" fill="currentColor"></path>
                <path d="M99.9207 46.7669L107.531 46.0778L107.66 47.4819L103.314 50.8118L107.917 50.3931L108.078 52.1708L100.468 52.86L100.339 51.443L104.672 48.1131L100.075 48.5317L99.9143 46.7669H99.9207Z" fill="currentColor"></path>
                <path d="M100.294 55.7391C100.3 55.5716 100.326 55.3784 100.371 55.1594C100.416 54.9404 100.5 54.7278 100.622 54.5282C100.745 54.3285 100.931 54.1675 101.17 54.0387C101.414 53.9099 101.736 53.8583 102.142 53.8777L106.25 54.0773C106.604 54.0966 106.9 54.161 107.132 54.2834C107.363 54.4058 107.544 54.5604 107.673 54.7407C107.801 54.9275 107.891 55.1272 107.937 55.3526C107.982 55.578 108.001 55.7906 107.994 55.9967C107.969 56.5442 107.937 57.0208 107.898 57.4266C107.859 57.8323 107.827 58.1866 107.788 58.4957C107.75 58.8049 107.718 59.0819 107.685 59.3266L106.185 59.2558L106.32 56.454C106.327 56.2672 106.295 56.1255 106.211 56.016C106.127 55.9065 105.999 55.8485 105.812 55.8421L104.968 55.8035L104.724 58.6825L103.327 58.6181L103.365 55.7326L102.573 55.694C102.425 55.6875 102.303 55.7069 102.213 55.7519C102.123 55.797 102.058 55.855 102.013 55.9387C101.968 56.016 101.942 56.1062 101.936 56.2028L101.801 59.0561L100.307 58.9852C100.288 58.6825 100.275 58.3412 100.262 57.974C100.255 57.6005 100.249 57.2269 100.255 56.8469C100.255 56.4669 100.268 56.0997 100.288 55.7584L100.294 55.7391Z" fill="currentColor"></path>
                <path d="M99.6245 62.051C99.6631 61.8385 99.7082 61.6324 99.7597 61.4198C99.8112 61.2073 99.8692 61.0012 99.9271 60.7951C99.9851 60.589 100.049 60.3957 100.12 60.209C100.185 60.0222 100.255 59.8547 100.326 59.7001L101.717 59.9449C101.659 60.1574 101.601 60.3957 101.537 60.6534C101.472 60.911 101.421 61.1815 101.363 61.452C101.305 61.7226 101.26 61.9802 101.215 62.2249C101.176 62.4633 101.157 62.6629 101.163 62.8368C101.17 63.0107 101.215 63.146 101.286 63.249C101.363 63.3521 101.479 63.4229 101.64 63.4487L101.833 63.4809C102.013 63.5131 102.168 63.4809 102.277 63.3779C102.387 63.2748 102.47 63.1331 102.503 62.9399L102.606 62.3602C102.741 61.6002 103.005 61.0527 103.41 60.7178C103.816 60.3829 104.402 60.2798 105.187 60.4215L105.542 60.4859C106.276 60.6147 106.784 60.9303 107.061 61.4327C107.338 61.9351 107.402 62.5856 107.261 63.3972C107.209 63.6935 107.145 63.9769 107.061 64.2538C106.984 64.5243 106.9 64.782 106.816 65.0267C106.733 65.2715 106.649 65.484 106.565 65.6643L105.175 65.4196C105.258 65.1104 105.342 64.7691 105.432 64.3891C105.522 64.0091 105.593 63.6548 105.651 63.3392C105.69 63.1267 105.702 62.9399 105.696 62.7724C105.69 62.605 105.645 62.4633 105.561 62.3602C105.477 62.2571 105.342 62.1863 105.155 62.1541L105.001 62.1283C104.788 62.0897 104.627 62.1283 104.498 62.2314C104.37 62.3344 104.292 62.5212 104.247 62.7789L104.125 63.4809C104.041 63.9511 103.887 64.3182 103.661 64.5952C103.436 64.8657 103.159 65.0525 102.831 65.1555C102.503 65.2521 102.148 65.2715 101.769 65.2006L101.395 65.1362C100.771 65.0267 100.32 64.8335 100.037 64.5565C99.7533 64.2796 99.5859 63.9253 99.5408 63.5067C99.4957 63.0816 99.5215 62.5985 99.6181 62.051H99.6245Z" fill="currentColor"></path>
                <path d="M98.253 67.7383C98.3174 67.5322 98.3882 67.3326 98.4655 67.1329C98.5427 66.9332 98.6264 66.7336 98.7101 66.5339C98.7938 66.3407 98.884 66.1539 98.9741 65.98C99.0642 65.8061 99.1544 65.6451 99.2445 65.5034L100.59 65.922C100.506 66.1281 100.416 66.3536 100.32 66.6047C100.223 66.8559 100.133 67.1136 100.043 67.3776C99.9528 67.6417 99.8691 67.8929 99.7982 68.1248C99.7274 68.3566 99.6823 68.5563 99.6695 68.7238C99.6566 68.8912 99.6823 69.0394 99.7403 69.1489C99.8047 69.2648 99.9141 69.3421 100.069 69.3936L100.255 69.4516C100.429 69.5096 100.584 69.4902 100.712 69.4065C100.841 69.3228 100.931 69.1875 100.989 69.0007L101.163 68.4404C101.395 67.7061 101.73 67.1973 102.168 66.9139C102.605 66.6305 103.211 66.6112 103.97 66.8431L104.312 66.9525C105.026 67.178 105.49 67.5515 105.702 68.0861C105.915 68.6143 105.896 69.2777 105.651 70.0635C105.561 70.3533 105.458 70.6238 105.348 70.8879C105.232 71.1455 105.123 71.3903 105.007 71.6222C104.891 71.854 104.782 72.0537 104.679 72.2212L103.333 71.8025C103.455 71.5062 103.584 71.1777 103.719 70.8106C103.855 70.4435 103.97 70.1021 104.067 69.7994C104.131 69.5933 104.17 69.4129 104.183 69.239C104.196 69.0716 104.17 68.9299 104.099 68.8139C104.028 68.698 103.906 68.6143 103.719 68.5563L103.571 68.5112C103.365 68.4468 103.198 68.4597 103.063 68.5499C102.927 68.64 102.818 68.8075 102.741 69.0587L102.528 69.735C102.387 70.1858 102.187 70.5337 101.929 70.7784C101.672 71.0232 101.369 71.1713 101.034 71.2293C100.7 71.2872 100.346 71.255 99.9785 71.1391L99.618 71.0232C99.0127 70.8364 98.5878 70.5852 98.3431 70.276C98.092 69.9669 97.9761 69.5933 97.989 69.1682C98.0019 68.7431 98.0856 68.2665 98.253 67.7319V67.7383Z" fill="currentColor"></path>
                <path d="M95.1818 75.6928L101.112 80.5105L100.558 81.38L94.1774 79.5959L98.4912 84.6133L97.931 85.4893L91.0547 82.1465L91.5955 81.3028L97.3258 84.0852L97.2164 84.2204L92.8704 79.3125L93.3854 78.5074L99.6759 80.3688L99.5857 80.5299L94.6474 76.5301L95.1882 75.6863L95.1818 75.6928Z" fill="currentColor"></path>
                <path d="M90.3593 82.8936L96.0446 87.9239L94.0035 90.2297C93.8555 90.3972 93.6752 90.5517 93.4756 90.6934C93.276 90.8351 93.0378 90.9253 92.7802 90.964C92.5227 91.0026 92.2265 90.964 91.8981 90.8351C91.5698 90.7063 91.2028 90.468 90.7972 90.1073C90.3915 89.7466 90.1018 89.4117 89.9344 89.0897C89.767 88.7676 89.6833 88.4778 89.6897 88.2008C89.6897 87.9303 89.7477 87.6791 89.8636 87.4601C89.9795 87.2412 90.1082 87.0479 90.2499 86.8805C90.3207 86.7967 90.4237 86.6872 90.5525 86.552C90.6813 86.4167 90.8229 86.275 90.971 86.1269C91.1191 85.9788 91.2543 85.8435 91.3831 85.7211C91.5118 85.5987 91.6149 85.5021 91.6857 85.4377L89.6704 83.6536L90.3593 82.8743V82.8936ZM92.2845 85.9852L91.0354 87.3957C90.8873 87.5632 90.7972 87.7435 90.7521 87.9368C90.7135 88.1364 90.7456 88.349 90.8487 88.5744C90.9581 88.7998 91.1642 89.051 91.4732 89.328C91.7758 89.5985 92.0462 89.766 92.2845 89.8433C92.5227 89.9206 92.7287 89.927 92.909 89.8626C93.0893 89.7982 93.2438 89.6951 93.3726 89.547L94.6603 88.0914L92.2845 85.9852Z" fill="currentColor"></path>
                <path d="M85.0798 88.4134C85.3244 88.2009 85.5691 88.0463 85.8137 87.9626C86.0649 87.8724 86.316 87.8788 86.5671 87.9626C86.8246 88.0527 87.0821 88.246 87.3461 88.5487L91.3187 93.0315L90.5396 93.7207L86.6701 89.3538C86.522 89.1863 86.3803 89.0833 86.2451 89.0446C86.1099 89.006 85.9812 89.0124 85.8588 89.064C85.7365 89.1155 85.627 89.1863 85.5176 89.2829L83.5538 91.0284L82.9229 90.3199L85.0733 88.4134H85.0798Z" fill="currentColor"></path>
                <path d="M80.1867 92.3359L84.1335 98.8218L81.7255 100.29C81.146 100.645 80.6245 100.767 80.1673 100.657C79.7102 100.548 79.3175 100.219 78.9826 99.6656C78.8152 99.395 78.7187 99.1374 78.6736 98.8926C78.635 98.6479 78.6414 98.4225 78.7058 98.2099C78.7637 97.9974 78.8668 97.817 79.0084 97.6689C78.8668 97.7075 78.7058 97.7333 78.5319 97.7462C78.3581 97.7591 78.1714 97.7333 77.9847 97.6753C77.7915 97.6174 77.6048 97.5208 77.4245 97.3791C77.2442 97.2374 77.0704 97.0377 76.9223 96.7865C76.6455 96.3356 76.5038 95.9299 76.4974 95.5821C76.4909 95.2343 76.5875 94.9122 76.7935 94.6353C76.9996 94.3583 77.2957 94.1007 77.6885 93.8624L80.1931 92.3359H80.1867ZM79.781 93.6691L78.1907 94.6417C77.9782 94.7705 77.8173 94.9186 77.7078 95.0797C77.5984 95.2407 77.5469 95.4339 77.5597 95.6529C77.5726 95.8719 77.6692 96.1295 77.8495 96.4258C77.9847 96.6448 78.1392 96.7994 78.3324 96.896C78.5191 96.9926 78.7187 97.0377 78.9247 97.0248C79.1307 97.0119 79.3303 96.9475 79.5235 96.8316L81.1138 95.859L79.781 93.6691ZM81.5323 96.5482L79.9871 97.4885C79.736 97.6431 79.5879 97.8621 79.5428 98.1391C79.4977 98.416 79.5686 98.7123 79.7424 99.015C79.9613 99.4015 80.206 99.6205 80.4635 99.6656C80.7275 99.7106 80.985 99.6527 81.249 99.4916L82.7621 98.5706L81.5323 96.5482Z" fill="currentColor"></path>
                <path d="M73.5099 96.0587C73.8061 95.9234 74.0829 95.8525 74.3469 95.8461C74.6109 95.8397 74.8491 95.9105 75.068 96.0715C75.2869 96.2326 75.4736 96.4966 75.641 96.8573L78.107 102.313L77.1605 102.744L74.759 97.4306C74.6688 97.2309 74.5658 97.0892 74.4435 97.0119C74.3276 96.9346 74.1988 96.9024 74.07 96.9153C73.9413 96.9282 73.8125 96.9668 73.6773 97.0248L71.2822 98.1068L70.8894 97.2438L73.5099 96.0587Z" fill="currentColor"></path>
                <path d="M67.6764 98.2228C68.0756 98.1004 68.4555 98.0231 68.8096 97.9909C69.1637 97.9587 69.4921 97.9909 69.7883 98.094C70.0845 98.1906 70.342 98.3774 70.5609 98.635C70.7798 98.8991 70.9601 99.2598 71.1017 99.7171L72.6277 104.702L71.6361 105.005L70.1102 100.033C69.9943 99.6591 69.8398 99.3886 69.6402 99.2211C69.4406 99.0537 69.2024 98.9699 68.9126 98.957C68.6293 98.9506 68.301 98.9957 67.9404 99.1116C67.5734 99.2276 67.2708 99.3628 67.039 99.5367C66.8072 99.7042 66.6527 99.9103 66.5819 100.161C66.5111 100.413 66.5304 100.722 66.6463 101.095L68.1722 106.068L67.1807 106.37L65.6547 101.385C65.5131 100.928 65.4616 100.522 65.5002 100.181C65.5388 99.8394 65.6483 99.5432 65.835 99.2984C66.0217 99.0537 66.2728 98.8411 66.5819 98.6672C66.8974 98.4933 67.2515 98.3452 67.6636 98.2164L67.6764 98.2228Z" fill="currentColor"></path>
                <path d="M62.1779 99.5754C62.3453 99.5496 62.532 99.5367 62.7252 99.5367C62.9183 99.5367 63.1115 99.5689 63.2982 99.6398C63.4849 99.7106 63.6523 99.8395 63.7939 100.026C63.9356 100.213 64.0322 100.477 64.0901 100.825L64.8177 105.282C64.8692 105.578 64.8627 105.836 64.8048 106.055C64.7468 106.274 64.6503 106.454 64.5215 106.596C64.3927 106.738 64.2382 106.854 64.0579 106.931C63.8776 107.015 63.6974 107.066 63.5171 107.098C63.0728 107.163 62.6672 107.221 62.3002 107.272C61.9268 107.324 61.5855 107.369 61.2636 107.407C60.9417 107.446 60.6326 107.485 60.3429 107.517L60.2077 106.673L63.0921 106.203C63.3433 106.164 63.53 106.068 63.6523 105.913C63.7746 105.759 63.8133 105.546 63.7682 105.269L63.5171 103.71L60.2721 104.174L60.1433 103.382L63.3626 102.783L63.1115 101.25C63.0728 101.018 63.0084 100.851 62.9119 100.748C62.8153 100.645 62.6994 100.587 62.5706 100.561C62.4419 100.535 62.3002 100.542 62.1586 100.561L59.2934 101.031L59.1582 100.187C59.4673 100.116 59.7956 100.046 60.1369 99.9683C60.4781 99.8974 60.8258 99.8266 61.1735 99.7557C61.5211 99.6913 61.8624 99.6269 62.1908 99.5754H62.1779Z" fill="currentColor"></path>
                <path d="M52.9901 100.381L52.3784 108L49.3008 107.755C48.5089 107.691 47.9487 107.465 47.6268 107.085C47.3049 106.699 47.1697 106.19 47.2147 105.552C47.2405 105.256 47.2984 104.999 47.4015 104.78C47.5045 104.561 47.6332 104.38 47.8006 104.245C47.968 104.11 48.1612 104.026 48.393 103.987C48.232 103.936 48.0775 103.852 47.9294 103.743C47.7813 103.633 47.6526 103.498 47.5431 103.337C47.4336 103.176 47.3564 102.983 47.3049 102.764C47.2534 102.545 47.2405 102.3 47.2598 102.023C47.2984 101.514 47.4208 101.115 47.6204 100.818C47.82 100.522 48.0968 100.323 48.4574 100.213C48.8179 100.104 49.2558 100.071 49.7708 100.11L52.9965 100.368L52.9901 100.381ZM51.0843 101.83L49.6871 101.72C49.5391 101.707 49.4038 101.727 49.2944 101.785C49.1849 101.836 49.0948 101.92 49.0304 102.036C48.966 102.152 48.9274 102.3 48.9145 102.487C48.9016 102.648 48.9145 102.796 48.966 102.912C49.0175 103.034 49.0884 103.131 49.1849 103.202C49.2815 103.272 49.4038 103.318 49.5584 103.33L50.9555 103.44L51.0843 101.83ZM50.8461 104.786L49.539 104.683C49.3588 104.67 49.2171 104.728 49.1077 104.863C48.9982 104.999 48.9338 105.166 48.9145 105.379C48.8952 105.662 48.9338 105.855 49.0433 105.971C49.1527 106.087 49.2815 106.151 49.4296 106.164L50.7237 106.267L50.8396 104.786H50.8461Z" fill="currentColor"></path>
                <path d="M47.2276 99.9425L43.2743 106.892L41.1046 106.403L40.5122 98.429L42.3086 98.8347L42.3858 100.277L44.7552 100.812L45.4312 99.5368L47.2212 99.9425H47.2276ZM44.0083 102.281L42.4373 101.926L42.5339 105.16L44.0083 102.281Z" fill="currentColor"></path>
                <path d="M37.7695 97.2889C38.1816 97.4435 38.5293 97.6303 38.8126 97.8428C39.0958 98.0618 39.3083 98.3388 39.4371 98.6801C39.5659 99.0215 39.5981 99.453 39.5401 99.9812C39.4822 100.509 39.3083 101.153 39.0186 101.92C38.7417 102.667 38.452 103.253 38.1494 103.678C37.8468 104.103 37.5313 104.406 37.2094 104.58C36.881 104.754 36.5397 104.838 36.1792 104.818C35.8186 104.799 35.4388 104.715 35.0396 104.567C34.679 104.432 34.3571 104.29 34.0738 104.142C33.7905 103.994 33.5523 103.852 33.3527 103.71C33.1531 103.575 32.9921 103.453 32.8762 103.337L33.3591 102.042C33.4686 102.113 33.6231 102.197 33.8034 102.293C33.9901 102.39 34.2026 102.493 34.4472 102.603C34.6919 102.712 34.9559 102.822 35.2392 102.925C35.4774 103.015 35.6899 103.066 35.883 103.073C36.0697 103.086 36.2436 103.034 36.3981 102.925C36.5526 102.815 36.7071 102.628 36.8617 102.358C37.0162 102.094 37.1772 101.733 37.351 101.276C37.512 100.844 37.6279 100.477 37.6922 100.181C37.7566 99.8846 37.7759 99.6398 37.7373 99.4466C37.6987 99.2534 37.6085 99.0988 37.4605 98.97C37.3124 98.8412 37.1063 98.7252 36.8359 98.6286C36.3466 98.4483 35.9474 98.3066 35.6319 98.2099C35.3164 98.1133 35.0653 98.0489 34.8657 98.0038L35.3486 96.7092C35.5418 96.7092 35.7542 96.7286 35.986 96.7672C36.2178 96.8058 36.4818 96.8638 36.778 96.9475C37.0741 97.0248 37.4025 97.1343 37.7695 97.2696V97.2889Z" fill="currentColor"></path>
                <path d="M34.7692 96.1939L31.1636 102.937L29.5926 102.1L31.0348 99.4015L30.2429 98.9764L27.5837 101.025L25.9226 100.136L29.3672 97.6173L29.4509 93.3535L31.1121 94.2424L30.9962 97.5594L31.7881 97.9845L33.1917 95.3566L34.7627 96.1939H34.7692Z" fill="currentColor"></path>
                <path d="M25.7617 90.6484L21.9694 95.3566L23.5275 96.6126L22.5231 97.8557L18.0161 94.223L19.0205 92.98L20.5851 94.2424L24.3774 89.5341L25.7617 90.6548V90.6484Z" fill="currentColor"></path>
                <path d="M20.4433 85.7147C20.8039 86.1012 21.0872 86.4747 21.2868 86.8354C21.4864 87.1961 21.5829 87.5761 21.5636 87.9626C21.5443 88.349 21.4027 88.7677 21.1258 89.2121C20.8489 89.6565 20.4111 90.1589 19.8188 90.7192C19.2264 91.2732 18.7049 91.6789 18.2349 91.9301C17.7713 92.1813 17.3464 92.3037 16.9601 92.2972C16.5738 92.2908 16.2068 92.1749 15.8526 91.9559C15.4985 91.7369 15.1444 91.4342 14.7774 91.0477C14.4104 90.6548 14.1271 90.2813 13.9275 89.9141C13.7279 89.547 13.6378 89.1734 13.6571 88.7934C13.6764 88.407 13.8245 87.9948 14.1014 87.5439C14.3847 87.093 14.816 86.5907 15.4084 86.0367C16.0007 85.4764 16.5287 85.0706 16.9923 84.8194C17.4558 84.5682 17.8808 84.4459 18.2671 84.4587C18.6534 84.4652 19.0204 84.5811 19.3681 84.8001C19.7158 85.0191 20.0763 85.3218 20.4433 85.7147ZM19.2779 86.8096C19.0848 86.6035 18.8981 86.4554 18.7242 86.3652C18.5504 86.2751 18.3701 86.2493 18.1769 86.2815C17.9838 86.3137 17.7649 86.4168 17.5202 86.5907C17.2691 86.7646 16.9729 87.0093 16.6317 87.3378C16.2776 87.6727 16.0072 87.9561 15.814 88.2009C15.6273 88.4392 15.505 88.6582 15.4663 88.845C15.4213 89.0317 15.4406 89.2121 15.5307 89.386C15.6209 89.5599 15.7561 89.7467 15.9492 89.9528C16.1359 90.1524 16.3162 90.3006 16.4836 90.3972C16.651 90.4938 16.8313 90.526 17.018 90.4938C17.2112 90.4616 17.4301 90.3586 17.6812 90.1847C17.9323 90.0107 18.2413 89.7596 18.5955 89.4246C18.9431 89.1026 19.2071 88.8192 19.3938 88.5809C19.5805 88.3426 19.6964 88.13 19.7415 87.9432C19.7866 87.7564 19.7673 87.5697 19.69 87.3893C19.6127 87.209 19.4711 87.0158 19.2779 86.8096Z" fill="currentColor"></path>
                <path d="M16.239 80.8003L9.76179 84.8581L8.11997 82.2367C7.69502 81.5604 7.5405 80.9807 7.65639 80.4912C7.77229 80.0017 8.10065 79.5895 8.64149 79.2481C8.89259 79.0935 9.13726 78.9905 9.36904 78.9389C9.60727 78.8874 9.82618 78.8874 10.0322 78.9389C10.2382 78.9905 10.425 79.1 10.5924 79.261C10.5409 79.1 10.5151 78.9261 10.5087 78.7393C10.5087 78.5525 10.5409 78.3721 10.6052 78.1854C10.6696 77.9986 10.7791 77.8247 10.9207 77.6508C11.0624 77.4769 11.2555 77.3223 11.4873 77.1742C11.9187 76.9036 12.3115 76.7619 12.6656 76.7491C13.0197 76.7362 13.3481 76.8392 13.6507 77.0647C13.9533 77.2901 14.243 77.6186 14.5134 78.0565L16.2325 80.8003H16.239ZM11.4229 81.7085L10.7276 80.6007C10.631 80.4525 10.5022 80.3688 10.3284 80.3559C10.1545 80.3495 9.9807 80.401 9.80042 80.5105C9.5622 80.6586 9.42699 80.8132 9.40124 80.9678C9.37548 81.1224 9.40124 81.2641 9.4785 81.3929L10.1674 82.4943L11.4229 81.7085ZM13.9404 80.1369L13.2 78.9518C13.1227 78.823 13.0261 78.7328 12.9167 78.6749C12.8072 78.6169 12.6849 78.5976 12.5561 78.6169C12.4273 78.6362 12.2793 78.6942 12.1247 78.7908C11.9831 78.881 11.8801 78.9776 11.8157 79.0871C11.7449 79.1966 11.7127 79.3125 11.7127 79.4349C11.7127 79.5573 11.7513 79.6796 11.835 79.8085L12.5754 80.9936L13.9468 80.1369H13.9404Z" fill="currentColor"></path>
                <path d="M11.8029 72.7364C11.9767 73.1229 12.1055 73.5029 12.1892 73.8636C12.2729 74.2243 12.2922 74.5785 12.2343 74.907C12.1763 75.2355 12.0282 75.5446 11.79 75.8216C11.5453 76.0986 11.1912 76.3433 10.7083 76.5559L6.00176 78.6427L5.28064 77.0131L9.98077 74.9263C10.1868 74.8361 10.3349 74.7138 10.425 74.5656C10.5152 74.4175 10.5474 74.2436 10.5345 74.0439C10.5152 73.8443 10.4572 73.6253 10.3478 73.3805C10.2448 73.1422 10.1224 72.949 9.98721 72.8008C9.852 72.6527 9.69748 72.5625 9.53007 72.5303C9.36267 72.4981 9.17595 72.5303 8.96348 72.6205L4.26335 74.7073L3.54224 73.0778L8.24881 70.991C8.72526 70.7784 9.1502 70.6754 9.5172 70.6818C9.88419 70.6818 10.2126 70.7849 10.4959 70.9652C10.7792 71.1455 11.0238 71.3903 11.2363 71.693C11.4488 71.9957 11.6355 72.3435 11.8093 72.7364H11.8029Z" fill="currentColor"></path>
                <path d="M9.67823 66.8817C9.73617 67.0878 9.78768 67.2939 9.83919 67.5065C9.88426 67.719 9.92933 67.9316 9.96152 68.1441C10.0002 68.3567 10.0259 68.5563 10.0452 68.7495C10.0645 68.9428 10.0774 69.1231 10.0839 69.297L8.72532 69.6835C8.68669 69.4645 8.63519 69.2262 8.5708 68.9685C8.51285 68.7045 8.44203 68.4404 8.37764 68.1699C8.30682 67.8994 8.236 67.6482 8.17161 67.4099C8.10722 67.178 8.02996 66.9848 7.94626 66.8366C7.86256 66.682 7.76598 66.579 7.65009 66.521C7.5342 66.4631 7.39899 66.4566 7.24446 66.5017L7.05774 66.5532C6.87746 66.6048 6.76157 66.7014 6.70362 66.8431C6.64568 66.9848 6.63924 67.1458 6.69719 67.3326L6.85815 67.8994C7.07062 68.6401 7.07062 69.2455 6.85815 69.7286C6.64568 70.2052 6.15635 70.5594 5.39016 70.7784L5.04248 70.875C4.32137 71.0811 3.72902 71.0232 3.25901 70.6947C2.78899 70.3662 2.44131 69.8059 2.21596 69.0136C2.13226 68.7238 2.06788 68.4404 2.01637 68.157C1.9713 67.88 1.92623 67.6095 1.90048 67.3519C1.86828 67.0943 1.84897 66.8688 1.84253 66.6692L3.20106 66.2827C3.25901 66.5919 3.33627 66.9397 3.42641 67.3261C3.51655 67.7126 3.60025 68.0539 3.69039 68.3631C3.74834 68.5692 3.81916 68.7431 3.90286 68.8912C3.98656 69.0394 4.08314 69.1424 4.20547 69.2004C4.3278 69.2584 4.47589 69.2584 4.66261 69.2068L4.81069 69.1618C5.01673 69.1038 5.15194 69.0007 5.21632 68.8462C5.28071 68.698 5.27427 68.4919 5.20344 68.2407L5.01029 67.558C4.88152 67.1007 4.85576 66.7014 4.93947 66.3536C5.02317 66.0058 5.19057 65.7159 5.44167 65.484C5.69277 65.2522 6.00182 65.0847 6.37526 64.9752L6.74226 64.8722C7.34748 64.6983 7.84325 64.6725 8.22312 64.7949C8.60299 64.9173 8.9056 65.162 9.13095 65.5227C9.3563 65.8834 9.54302 66.3342 9.69754 66.8688L9.67823 66.8817Z" fill="currentColor"></path>
                <path d="M8.75742 63.4358L1.24365 64.8399L0.915283 63.088L8.42905 61.6839L8.75742 63.4358Z" fill="currentColor"></path>
                <path d="M8.17796 60.6663L0.55473 61.2588L0.445275 59.8483L4.82992 56.5699L0.219926 56.9306L0.0847168 55.1529L7.70794 54.5604L7.8174 55.9774L3.44563 59.2557L8.04919 58.8951L8.18439 60.6598L8.17796 60.6663Z" fill="currentColor"></path>
                <path d="M7.92038 51.7071C7.9075 51.8745 7.88175 52.0678 7.83668 52.2868C7.79161 52.5057 7.70147 52.7183 7.57914 52.918C7.4568 53.1176 7.27009 53.2786 7.02542 53.401C6.78076 53.5234 6.45883 53.5749 6.0532 53.5492L1.95186 53.298C1.59774 53.2786 1.30156 53.2014 1.06978 53.079C0.837989 52.9566 0.664149 52.802 0.535378 52.6152C0.406607 52.4285 0.322906 52.2223 0.277837 52.0034C0.232767 51.7779 0.213451 51.5654 0.226328 51.3593C0.258521 50.8118 0.297152 50.3352 0.335783 49.9294C0.374415 49.5236 0.419484 49.1694 0.458116 48.8602C0.496747 48.5511 0.535378 48.2741 0.567571 48.0294L2.06775 48.1195L1.90035 50.9149C1.88747 51.1016 1.9261 51.2498 2.00336 51.3528C2.08063 51.4623 2.21584 51.5203 2.40255 51.5332L3.246 51.5847L3.52286 48.7121L4.92002 48.7958L4.8492 51.6813L5.64114 51.7264C5.78922 51.7328 5.91155 51.72 6.00169 51.6749C6.09183 51.6298 6.15622 51.5718 6.20129 51.4881C6.24636 51.4108 6.27211 51.3206 6.27855 51.224L6.45239 48.3772L7.94613 48.4673C7.95901 48.7701 7.97189 49.1114 7.97832 49.4785C7.97832 49.8521 7.97832 50.2257 7.97832 50.6057C7.97189 50.9857 7.95257 51.3464 7.93325 51.6942L7.92038 51.7071Z" fill="currentColor"></path>
                <path d="M8.66717 45.4015C8.62854 45.614 8.58347 45.8201 8.52552 46.0327C8.47401 46.2452 8.40963 46.4513 8.35168 46.6574C8.28729 46.8636 8.22291 47.0568 8.15208 47.2371C8.08126 47.4175 8.01044 47.5849 7.93961 47.7395L6.55533 47.4754C6.61971 47.2629 6.67766 47.0246 6.74204 46.7669C6.80643 46.5093 6.86438 46.2388 6.92876 45.9683C6.99315 45.6978 7.03822 45.4401 7.08329 45.1954C7.12836 44.9571 7.14767 44.7574 7.14123 44.5835C7.14123 44.4096 7.09616 44.2743 7.0189 44.1648C6.94164 44.0618 6.82575 43.9909 6.66478 43.9587L6.47163 43.9201C6.29135 43.8879 6.13682 43.9201 6.02093 44.0167C5.90504 44.1133 5.82777 44.2615 5.78914 44.4482L5.67969 45.0279C5.53804 45.7815 5.26118 46.329 4.85555 46.6574C4.44993 46.9924 3.85758 47.0825 3.07208 46.9344L2.71796 46.87C1.98397 46.7283 1.48176 46.4063 1.2049 45.9039C0.934486 45.4015 0.876539 44.7445 1.03106 43.933C1.08901 43.6367 1.15983 43.3533 1.24354 43.0828C1.32724 42.8123 1.41094 42.5546 1.50108 42.3163C1.59122 42.0716 1.67492 41.8655 1.75862 41.6851L3.1429 41.9492C3.0592 42.2519 2.96906 42.5997 2.87248 42.9797C2.77591 43.3597 2.70508 43.7075 2.6407 44.0296C2.60207 44.2357 2.58275 44.4289 2.58919 44.5964C2.58919 44.7638 2.6407 44.9055 2.71796 45.0086C2.80166 45.1181 2.93687 45.1889 3.12359 45.2211L3.27811 45.2469C3.48415 45.2855 3.65155 45.2533 3.78032 45.1503C3.90265 45.0472 3.99279 44.8669 4.03786 44.6093L4.17307 43.9136C4.26321 43.4499 4.41773 43.0828 4.64952 42.8058C4.88131 42.5353 5.15816 42.355 5.48653 42.2584C5.8149 42.1617 6.16901 42.1553 6.54889 42.2261L6.92232 42.297C7.54042 42.4129 7.99756 42.6126 8.27442 42.896C8.55771 43.1794 8.71867 43.5272 8.75731 43.9523C8.79594 44.3774 8.75731 44.8605 8.66073 45.4079L8.66717 45.4015Z" fill="currentColor"></path>
                <path d="M10.116 39.7206C10.0517 39.9203 9.97439 40.1264 9.89713 40.3261C9.81986 40.5257 9.72973 40.7254 9.64602 40.9186C9.55589 41.1119 9.46575 41.2986 9.37561 41.4661C9.28547 41.64 9.18889 41.7946 9.09875 41.9427L7.75953 41.5047C7.84967 41.3051 7.93981 41.0732 8.03639 40.8285C8.13297 40.5773 8.22955 40.3196 8.31969 40.062C8.40983 39.7979 8.49996 39.5532 8.57723 39.3149C8.65449 39.0894 8.69956 38.8833 8.71244 38.7159C8.73175 38.542 8.71244 38.4003 8.64805 38.2908C8.5901 38.1748 8.48065 38.0975 8.31969 38.046L8.13297 37.988C7.95913 37.9301 7.8046 37.943 7.67583 38.0267C7.54706 38.1104 7.45048 38.2457 7.39254 38.426L7.21226 38.9864C6.97403 39.7206 6.63279 40.223 6.18853 40.5C5.74427 40.7769 5.14549 40.7898 4.38574 40.5451L4.0445 40.4356C3.33626 40.2037 2.87269 39.8172 2.66665 39.2891C2.46062 38.7545 2.48637 38.0975 2.74391 37.3118C2.84049 37.0219 2.94351 36.7514 3.0594 36.4938C3.1753 36.2361 3.29119 35.9914 3.41352 35.766C3.52942 35.5405 3.64531 35.3409 3.74833 35.1734L5.08754 35.6114C4.96521 35.9012 4.83 36.2297 4.68835 36.5968C4.5467 36.964 4.42437 37.2989 4.32779 37.608C4.26341 37.8077 4.21834 37.9945 4.20546 38.162C4.19259 38.3294 4.21834 38.4711 4.28272 38.587C4.34711 38.703 4.47588 38.7932 4.65616 38.8511L4.80425 38.8962C5.00384 38.9606 5.17768 38.9542 5.31289 38.864C5.4481 38.7738 5.55755 38.6064 5.64126 38.3552L5.86017 37.6789C6.00825 37.228 6.21429 36.8802 6.47183 36.6419C6.72937 36.4036 7.03198 36.2555 7.37322 36.2039C7.71446 36.1524 8.06214 36.1846 8.42914 36.307L8.7897 36.4229C9.38848 36.6226 9.81343 36.8738 10.0581 37.1894C10.3028 37.505 10.4186 37.8721 10.3993 38.2972C10.38 38.7223 10.2899 39.1989 10.116 39.7271V39.7206Z" fill="currentColor"></path>
                <path d="M13.2772 31.8177L7.40527 26.9227L7.97186 26.0596L14.3331 27.921L10.0837 22.8457L10.6567 21.9761L17.488 25.4091L16.9407 26.2464L11.2491 23.3931L11.365 23.2579L15.6466 28.2173L15.1186 29.016L8.84751 27.0773L8.93764 26.9163L13.8245 30.9804L13.2772 31.8177Z" fill="currentColor"></path>
                <path d="M18.1898 24.6813L12.5625 19.5802L14.6293 17.3001C14.7838 17.1326 14.9576 16.9781 15.1637 16.8428C15.3697 16.7075 15.6015 16.6174 15.859 16.5787C16.1166 16.5401 16.4127 16.5852 16.7411 16.7204C17.0695 16.8492 17.43 17.1004 17.8357 17.4611C18.2348 17.8283 18.5181 18.1696 18.6855 18.4917C18.8529 18.8137 18.9302 19.11 18.9238 19.3805C18.9238 19.651 18.8594 19.9022 18.7435 20.1212C18.6276 20.3402 18.4988 20.5334 18.3507 20.6944C18.2799 20.7782 18.1769 20.8812 18.0417 21.0165C17.9065 21.1517 17.7713 21.2934 17.6232 21.4351C17.4751 21.5768 17.3335 21.7121 17.2047 21.8345C17.0759 21.9568 16.9729 22.047 16.8956 22.1114L18.8851 23.9213L18.1833 24.6942L18.1898 24.6813ZM16.3033 21.5639L17.5717 20.1663C17.7198 20.0053 17.8163 19.8249 17.8614 19.6252C17.9065 19.4256 17.8743 19.213 17.7713 18.9876C17.6683 18.7622 17.4622 18.5045 17.1596 18.2276C16.857 17.9571 16.593 17.7767 16.3548 17.6994C16.123 17.6221 15.9105 17.6093 15.7367 17.6737C15.5564 17.7316 15.4019 17.8347 15.2667 17.9828L13.9597 19.4256L16.3097 21.5575L16.3033 21.5639Z" fill="currentColor"></path>
                <path d="M23.5338 19.2259C23.2891 19.4385 23.0444 19.5866 22.7933 19.6703C22.5422 19.7541 22.2911 19.7476 22.04 19.6574C21.7889 19.5608 21.5314 19.3676 21.2674 19.0649L17.3528 14.537L18.1383 13.8542L21.9499 18.2662C22.0915 18.4337 22.2332 18.5367 22.3684 18.5754C22.5036 18.614 22.6324 18.614 22.7547 18.5625C22.877 18.511 22.9929 18.4401 23.096 18.3435L25.0855 16.6238L25.7036 17.3387L23.5273 19.2195L23.5338 19.2259Z" fill="currentColor"></path>
                <path d="M28.4787 15.355L24.6091 8.81752L27.0365 7.38121C27.6224 7.0334 28.1439 6.91747 28.5946 7.0334C29.0517 7.14934 29.438 7.48426 29.7728 8.03818C29.9338 8.30869 30.0304 8.56632 30.069 8.81108C30.1076 9.05583 30.0948 9.28126 30.0304 9.49381C29.966 9.70635 29.863 9.88026 29.7213 10.0284C29.8694 9.98975 30.0239 9.97043 30.2042 9.95755C30.3781 9.95111 30.5648 9.97687 30.7515 10.0348C30.9382 10.0928 31.1249 10.1959 31.3116 10.3376C31.4919 10.4793 31.6593 10.6789 31.8074 10.9366C32.0778 11.3939 32.213 11.7932 32.2195 12.1474C32.2259 12.5017 32.1229 12.8173 31.9104 13.0942C31.698 13.3712 31.4018 13.6224 31.009 13.8543L28.4851 15.3485L28.4787 15.355ZM27.1845 11.1298L28.7427 10.2087C28.9938 10.0606 29.1483 9.84161 29.1934 9.56466C29.2384 9.2877 29.1741 8.99142 29.0066 8.6887C28.7942 8.29581 28.5495 8.07682 28.292 8.02529C28.0344 7.97377 27.7705 8.02529 27.5 8.18632L25.9741 9.08803L27.1845 11.1298ZM28.9036 14.0282L30.5068 13.0749C30.7193 12.9461 30.8803 12.8044 30.9962 12.6434C31.1121 12.4824 31.1571 12.2891 31.1507 12.0766C31.1442 11.864 31.0477 11.6 30.8674 11.2972C30.7386 11.0783 30.5777 10.9172 30.3909 10.8206C30.2042 10.7176 30.0046 10.6725 29.7986 10.6854C29.5926 10.6982 29.393 10.7562 29.1998 10.8721L27.5966 11.8254L28.9036 14.0346V14.0282Z" fill="currentColor"></path>
                <path d="M35.207 11.7223C34.9108 11.8511 34.634 11.922 34.37 11.9284C34.106 11.9349 33.8678 11.8576 33.6553 11.6901C33.4429 11.5227 33.2561 11.2586 33.0952 10.8979L30.6936 5.41029L31.6465 4.99164L33.9837 10.3375C34.0738 10.5372 34.1769 10.6789 34.2927 10.7626C34.4086 10.8464 34.531 10.8786 34.6662 10.8657C34.7949 10.8528 34.9302 10.8206 35.0589 10.7626L37.4669 9.70633L37.8468 10.5758L35.2135 11.7288L35.207 11.7223Z" fill="currentColor"></path>
                <path d="M41.0596 9.62908C40.6604 9.74501 40.2805 9.81586 39.92 9.84807C39.5594 9.87383 39.2375 9.83518 38.9413 9.73213C38.6452 9.62908 38.3876 9.44229 38.1751 9.17822C37.9562 8.91414 37.7824 8.54702 37.6472 8.08972L36.1792 3.08518L37.1772 2.79534L38.6387 7.787C38.7482 8.16057 38.9027 8.43752 39.0959 8.60498C39.289 8.77244 39.5337 8.86262 39.817 8.8755C40.1003 8.88838 40.4286 8.84329 40.7892 8.7338C41.1562 8.62431 41.4588 8.48905 41.697 8.32159C41.9352 8.15412 42.0833 7.94802 42.1606 7.70327C42.2379 7.45851 42.2185 7.14291 42.1091 6.76934L40.6475 1.77769L41.6455 1.48785L43.1135 6.49239C43.2487 6.95613 43.2938 7.35546 43.2552 7.69682C43.2101 8.03819 43.0942 8.33447 42.9075 8.57922C42.7208 8.82397 42.4632 9.03008 42.1542 9.20398C41.8387 9.37788 41.4781 9.51958 41.066 9.64196L41.0596 9.62908Z" fill="currentColor"></path>
                <path d="M46.5839 8.34734C46.4165 8.3731 46.2298 8.38598 46.0366 8.37954C45.8434 8.37954 45.6503 8.3409 45.4636 8.27005C45.2769 8.1992 45.1095 8.07038 44.9742 7.87716C44.8326 7.69037 44.7425 7.41986 44.6845 7.07849L44.0149 2.61499C43.9698 2.31871 43.9763 2.05464 44.0407 1.84209C44.105 1.62954 44.1952 1.44276 44.3304 1.30106C44.4592 1.15936 44.6201 1.04987 44.794 0.972578C44.9678 0.895288 45.1545 0.83732 45.3348 0.811557C45.7791 0.753589 46.1847 0.702063 46.5581 0.656977C46.9316 0.611891 47.2792 0.573246 47.5947 0.534601C47.9167 0.502397 48.2257 0.470192 48.5154 0.437988L48.6442 1.28174L45.7533 1.71327C45.5022 1.75192 45.3155 1.84853 45.1931 1.99667C45.0708 2.14481 45.0322 2.3638 45.0708 2.64076L45.3026 4.19944L48.5541 3.77434L48.6764 4.56657L45.4443 5.12692L45.676 6.66628C45.7082 6.89815 45.7726 7.06561 45.8692 7.16867C45.9658 7.27172 46.0752 7.33613 46.2104 7.35545C46.3392 7.37477 46.4809 7.37477 46.6225 7.35545L49.4941 6.92391L49.6229 7.76766C49.3138 7.83207 48.9854 7.90292 48.6442 7.97377C48.303 8.04462 47.9553 8.10903 47.6076 8.17344C47.2599 8.23784 46.9187 8.28937 46.5839 8.3409V8.34734Z" fill="currentColor"></path>
            </g>
            <defs>
                <clipPath id="clip0_36_783">
                    <rect width="108" height="108" fill="white" transform="translate(0.0847168)"></rect>
                </clipPath>
            </defs>
        </svg></div>
    <div class="ThemeButton_circle__mWPxD ThemeButton_business__J6vvT">
        <div class="ThemeButton_innerCircle__qL1xU" style={{transform:"none"}}>
    
        <div className="cont_principal">
      <div className="cont_centro">
        <div
          className={`cont_circle ${isDay ? 'cont_circle_noche' : 'cont_circle_dia'}`}
        >
          <div className="sun"></div>
          <div className="moonx">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g transform="translate(0,-1028.3622)">
                <path
                  style={{ fill: '#ffffff' }}
                  d="m 12.5,1051.8622 a 11.5,11.5 0 0 0 9.93359,-5.7305 11.5,11.5 0 0 1 -10.14844,-11.4121 11.5,11.5 0 0 1 1.56446,-5.7676 11.5,11.5 0 0 0 -1.34961,-0.09 11.5,11.5 0 0 0 -11.5,11.5 11.5,11.5 0 0 0 11.5,11.5 z"
                />
              </g>
            </svg>
          </div>

          <div className="cont_olas_bottom">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="428"
    height="140"
    viewBox="0 0 428 140"
  >
    <g style={{ fill: '#ffffff', fillOpacity: 0.786 }}>
      <path
        d="m 0,1028.2403 c 23.31769,-8.6016 55.72675,-17.4895 79.04444,-18.1827 11.2904,-0.3156 26.68128,7.4409 42.09759,11.1455 15.41631,3.7046 26.81743,2.8522 40.22985,2.4916 12.95522,-0.4126 28.2055,-6.151 52.31482,-14.5357 l -0.644,30.193 -213.09500238,0.016 z"
      />
      <path
        d="m 426.18304,1015.7276 c -26.34815,1.5 -54.33844,-0.6778 -66.03938,-1.876 -12.30054,-1.4521 -31.28361,-7.4363 -46.76147,-11.8498 -9.13204,-2.60397 -38.62494,-3.50794 -51.85031,-2.30725 -12.95522,0.97645 -28.22921,4.43235 -47.85598,9.47015 L 213.04683,1039.38 425,1039.343 Z"
      />
      <rect
        y="1039.3678"
        x="-0.052302379"
        height="96.800781"
        width="425.14133"
        style={{ fill: '#ffffff', fillOpacity: 0.786 }}
      />
    </g>
    <g style={{ fill: '#ffffff', fillOpacity: 0.781 }}>
      <path
        style={{ fill: '#ffffff', fillOpacity: 0.781 }}
        d="m 425.9497,1038.22 c 26.34814,1.5 45.12014,-1.2008 56.31601,-2.0203 12.17428,-1.1995 37.78171,-5.1492 53.25957,-9.5626 9.13204,-2.604 41.52933,-12.7208 54.88097,-12.7076 13.04897,0.025 62.82855,10.2944 82.45532,15.3322 l -40.41163,23.1005 -206.50024,0 z"
      />
      <path
        style={{ fill: '#ffffff', fillOpacity: 0.781 }}
        d="m 852.0083,1015.8985 c -25.46427,-6.2025 -64.78376,0.7136 -86.08114,6.2076 -11.66922,3.4725 -20.60985,4.9409 -36.02616,8.6454 -35.32671,8.3971 -50.21019,0.1889 -57.05774,-1.4881 l -40.29957,23.0988 219.45631,0 z"
      />
      <rect
        width="426.01624"
        height="83.800438"
        x="425.94769"
        y="1052.3678"
        style={{ fill: '#ffffff', fillOpacity: 0.781 }}
      />
    </g>
  </svg>
</div>
   <div className="cont_olas_bottom">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="428"
        height="140"
        viewBox="0 0 428 140"
        version="1.1"
        id="svg4185"
      >
        <g transform="translate(0,-912.36216)">
          <g
            transform="translate(0.05230238,-83.806076)"
            style={{ fill: "#ffffff", fillOpacity: 0.78604653 }}
          >
            <path
              d="M0 1028.2403c23.31769-8.6016 55.72675-17.4895 79.04444-18.1827 11.2904-.3156 26.68128 7.4409 42.09759 11.1455 15.41631 3.7046 26.81743 2.8522 40.22985 2.4916 12.95522-.4126 28.2055-6.151 52.31482-14.5357l-.644 30.193-213.09500238.016z"
              style={{ fill: "#ffffff", fillOpacity: 0.78604653, stroke: "none" }}
            />
            <path
              d="M426.18304 1015.7276c-26.34815 1.5-54.33844-.6778-66.03938-1.876-12.30054-1.4521-31.28361-7.4363-46.76147-11.8498-9.13204-2.60397-38.62494-3.50794-51.85031-2.30725-12.95522.97645-28.22921 4.43235-47.85598 9.47015L213.04683 1039.38 425 1039.343z"
              style={{ fill: "#ffffff", fillOpacity: 0.78604653, stroke: "none" }}
            />
            <rect
              y="1039.3678"
              width="425.14133"
              height="96.800781"
              style={{ fill: "#ffffff", fillOpacity: 0.78604653, stroke: "none" }}
            />
          </g>
          <g transform="matrix(1.0046942,0,0,1,-427.94717,-83.806076)">
            <path
              d="M425.9497 1038.22c26.34814 1.5 45.12014-1.2008 56.31601-2.0203 12.17428-1.1995 37.78171-5.1492 53.25957-9.5626 9.13204-2.604 41.52933-12.7208 54.88097-12.7076 13.04897.025 62.82855 10.2944 82.45532 15.3322l-40.41163 23.1005-206.50024 0z"
              style={{ fill: "#ffffff", fillOpacity: 0.78139535, stroke: "none" }}
            />
            <path
              d="M852.0083 1015.8985c-25.46427-6.2025-64.78376.7136-86.08114 6.2076-11.66922 3.4725-20.60985 4.9409-36.02616 8.6454-35.32671 8.3971-50.21019.1889-57.05774-1.4881l-40.29957 23.0988 219.45631 0z"
              style={{ fill: "#ffffff", fillOpacity: 0.78139535, stroke: "none" }}
            />
            <rect
              width="426.01624"
              height="83.800438"
              x="425.94769"
              y="1052.3678"
              style={{ fill: "#ffffff", fillOpacity: 0.78139535, stroke: "none" }}
            />
          </g>
        </g>
      </svg>
    </div>
        </div>
      </div>
  
    </div>
        </div>
    </div>
    <div className="ThemeButton_circle__mWPxD"><svg width="33" height="24" viewBox="0 0 33 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ opacity: 0, transform: 'scale(0) rotateZ(180deg)' }}>
            <path d="M0.0847168 5.3832V3.01857C2.50231 1.02367 5.65135 -0.363579 8.86162 0.084392C11.2581 0.375096 13.2971 1.5696 15.3343 2.76309C16.3354 3.34957 17.3364 3.93597 18.3789 4.41457C22.5667 6.46697 27.3195 8.57708 32.0847 7.43858V9.39551C26.013 10.2539 20.2637 7.48881 15.1081 4.67416C14.722 4.46078 14.3367 4.23635 13.9495 4.01087C12.1225 2.94661 10.2546 1.85858 8.07764 1.79934C4.97907 1.54396 2.14163 3.24357 0.0847168 5.3832Z" fill="currentColor"></path>
            <path d="M32.0847 23.8301C29.9657 24.0882 27.792 24.0908 25.7128 23.5662C21.0315 22.4671 16.7744 20.1391 12.6719 17.742C10.6094 16.5242 8.08609 15.8641 5.70046 16.4496C3.50567 16.9402 1.62917 18.2768 0.0847168 19.8384V17.5108C1.74676 16.1314 3.6913 15.0194 5.85776 14.6463L5.85494 14.6435C8.48225 14.0939 11.2191 14.8755 13.4923 16.19C16.9401 18.1839 20.4526 20.1529 24.2797 21.368C26.7835 22.1571 29.4822 22.541 32.0847 21.9885V23.8301Z" fill="currentColor"></path>
            <path d="M32.0847 16.566V14.7578C27.2261 15.7556 22.4524 13.6751 18.2046 11.5699C17.2213 11.1077 16.2683 10.5484 15.3136 9.98796C13.0735 8.67315 10.8234 7.35249 8.14791 7.26729V7.26177C5.16235 7.04747 2.34368 8.41297 0.0847168 10.238V12.6333C1.75099 10.9404 3.81096 9.46608 6.23709 9.12584C8.43447 8.77236 10.6881 9.34677 12.5763 10.4569C16.5215 12.7629 20.5846 14.997 25.044 16.1817C27.334 16.7918 29.7409 16.9616 32.0847 16.566Z" fill="currentColor"></path>
        </svg></div>
</button>
*/
const FooterFour = () => {
    const { isDay, setIsDay } = useStore();
    const [isDayLocal, setisDayLocal] = useState(true);

    const toggle = () => {
      // `setState` asenkron olduğu için güncel değeri callback ile alıyoruz
      setisDayLocal(prevIsDayLocal => {
        const newIsDayLocal = !prevIsDayLocal;
        setIsDay(newIsDayLocal);

        if (!newIsDayLocal) {
          document.documentElement.style.setProperty('--color-dark', '#000000');
          document.documentElement.style.setProperty('--color-darktr', 'rgba(0,0,0,.9)');



          document.documentElement.style.setProperty('--color-darkwhite', '#ffffff');

          document.documentElement.style.setProperty('--color-antidark', 'rgba(255, 255, 255, 1)');

          document.documentElement.style.setProperty('--color-border', 'rgba(255, 255, 255, 0.1)');
          document.documentElement.style.setProperty('--color-body', '#acacac');
          document.documentElement.style.setProperty('--color-darker', 'rgba(6, 6, 6, 0.7)');
          document.documentElement.style.setProperty('--color-body2wh', '#acacac');
          document.documentElement.style.setProperty('--color-body2rd', '#acacac');
          document.documentElement.style.setProperty('--color-fws', 'rgba(255, 255, 255, .7)');
          document.documentElement.style.setProperty('--color-new', '#e0d2d2');
          document.documentElement.style.setProperty('--color-newdkxc', 'black');
          document.documentElement.style.setProperty('--color-lang', '#0f0f11');
          document.documentElement.style.setProperty('--color-astralservo', 'rgb(10, 10, 10)');



          

        } else {
          document.documentElement.style.setProperty('--color-darkwhite', 'black');
          document.documentElement.style.setProperty('--color-darktr', 'rgba(255,255,255,.9)');

          document.documentElement.style.setProperty('--color-dark', '#ffffff');
          document.documentElement.style.setProperty('--color-border', 'rgba(255, 0, 0, 0.3)');
          document.documentElement.style.setProperty('--color-body', '#5f5f5f');
          document.documentElement.style.setProperty('--color-darker', 'rgb(197 197 197)');
          document.documentElement.style.setProperty('--color-body2wh', 'white');
          document.documentElement.style.setProperty('--color-body2rd', 'rgb(237, 31, 34)');
          document.documentElement.style.setProperty('--color-fws', 'white');
          document.documentElement.style.setProperty('--color-new', 'white');
          document.documentElement.style.setProperty('--color-newdkxc', 'gray');
          document.documentElement.style.setProperty('--color-antidark', 'black');
          document.documentElement.style.setProperty('--color-lang', '#d8d8d8');
          document.documentElement.style.setProperty('--color-astralservo', '#d0d0d0');




        }

        return newIsDayLocal;
      });
    };

    useEffect(() => {
      if (!isDay) {

        document.documentElement.style.setProperty('--color-dark', '#000000');
        document.documentElement.style.setProperty('--color-darktr', 'rgba(0,0,0,.9)');



        document.documentElement.style.setProperty('--color-darkwhite', '#ffffff');

        document.documentElement.style.setProperty('--color-antidark', 'rgba(255, 255, 255, 1)');

        document.documentElement.style.setProperty('--color-border', 'rgba(255, 255, 255, 0.1)');
        document.documentElement.style.setProperty('--color-body', '#acacac');
        document.documentElement.style.setProperty('--color-darker', 'rgba(6, 6, 6, 0.7)');
        document.documentElement.style.setProperty('--color-body2wh', '#acacac');
        document.documentElement.style.setProperty('--color-body2rd', '#acacac');
        document.documentElement.style.setProperty('--color-fws', 'rgba(255, 255, 255, .7)');
        document.documentElement.style.setProperty('--color-new', '#e0d2d2');
        document.documentElement.style.setProperty('--color-newdkxc', 'black');
        document.documentElement.style.setProperty('--color-lang', '#0f0f11');
        document.documentElement.style.setProperty('--color-astralservo', 'rgb(10, 10, 10)');



        

      } else {
        console.log("w")

        document.documentElement.style.setProperty('--color-darkwhite', 'black');
        document.documentElement.style.setProperty('--color-darktr', 'rgba(255,255,255,.9)');

        document.documentElement.style.setProperty('--color-dark', '#ffffff');
        document.documentElement.style.setProperty('--color-border', 'rgba(255, 0, 0, 0.3)');
        document.documentElement.style.setProperty('--color-body', '#5f5f5f');
        document.documentElement.style.setProperty('--color-darker', 'rgb(197 197 197)');
        document.documentElement.style.setProperty('--color-body2wh', 'white');
        document.documentElement.style.setProperty('--color-body2rd', 'rgb(237, 31, 34)');
        document.documentElement.style.setProperty('--color-fws', 'white');
        document.documentElement.style.setProperty('--color-new', 'white');
        document.documentElement.style.setProperty('--color-newdkxc', 'gray');
        document.documentElement.style.setProperty('--color-antidark', 'black');
        document.documentElement.style.setProperty('--color-lang', '#d8d8d8');
        document.documentElement.style.setProperty('--color-astralservo', '#d0d0d0');




      }
  
  }, []);
    return (
        <>
            <footer className="rn-footer footer-style-default no-border">
                <div className="footer-top">
                    <div className="container">
                      
                    </div>
                </div>
                <NewsletterOne newsletetrStyle="rn-newsletter-default" extraClass={isDay ? "border-top-bottomwhite" : "border-top-bottom"}/>
                <div  className="copyright-area copyright-style-one no-border">
                    <div  className="container">
                        <div className="row align-items-center">
                            <div style={{position:"relative",zIndex:16}} className="col-lg-4 col-md-6 col-sm-12 col-12 rart">
                                <div className="copyright-left">
                                <ul className={`ft-menu link-hover ${isDay ? "newakefont333" : "newakefont444"}`}>
                                        <li><a className={isDay ? "ulliwhite" : "ulliblack" } href="#">Privacy Policy</a></li>
                                        <li><a className={isDay ? "ulliwhite" :"ulliblack" } href="#">Terms And Condition</a></li>
                                        <li><a className={isDay ? "ulliwhite" : "ulliblack"} href="/iletisim">Contact Us</a></li>
                                    </ul>
                                    
                                </div>
                            </div>
                            <div style={{position:"relative",zIndex:16}} className='col-lg-5 cold-md-6 col-sm-12 col-12 rart'>  
                            <ul className="social-icon social-default ulliblack">
                            <li style={{padding:0, position:"relative"}}><Link className={isDay ? "ullicirclewhite" :"ullicircleblack" } to="facebook.com"><FiFacebook /></Link></li>
                            <li style={{padding:0,position:"relative"}}><Link className={isDay ? "ullicirclewhite" :"ullicircleblack" } to="twitter.com"><FiTwitter /></Link></li>
                            <li style={{padding:0,position:"relative"}}><Link className={isDay ? "ullicirclewhite" :"ullicircleblack" } to="instagram.com"><FiInstagram /></Link></li>
                            <li style={{padding:0,position:"relative"}}><Link className={isDay ? "ullicirclewhite" :"ullicircleblack" } to="linkdin.com"><FiLinkedin /></Link></li>
                        </ul></div>
                        <div style={{position:"relative",zIndex:16}} className="col-lg-3 col-md-6 col-sm-12 col-12 rart">
                                <div className="copyright-right text-center text-md-end newakefont333" >
                                
                                    <p style={{fontSize:25}}  className={isDay ? "ullitextwh" : "ullitextbl"}>© Evolog Logistics  {new Date().getFullYear()}</p>
                                </div>
                            </div>
                            <div className="glass-bg"></div>
                            <div className="marquee-horizontal">
                            <div className="track-horizontal-alt">
                                <div className='div-block-4'>
                                    <div className='marquee-text'>get in touch</div>
                                    <div className='marquee-text'>get in touch</div>

                                </div>
                                </div>
                            </div>

                            <button  onClick={toggle}  className="ThemeButton_button__L5Q2F snipcss-rWhGx style-z3w9E" aria-label="Switch mode" id="style-z3w9E">
                <div className="ThemeButton_text__nAAK_ ThemeButton_business__J6vvT style-dIH6V" id="style-dIH6V">
      <div className="circle-container">
        <svg viewBox="0 0 300 300" width="300" height="300">
          <defs>
            <path
              id="circlePath"
              d="M 150, 150 m -130, 0 a 130,130 0 1,1 260,0 a 130,130 0 1,1 -260,0"
            />
          </defs>
          <text className="circle-text">
            <textPath href="#circlePath">
              {isDay ? (
                <>
                <tspan className="bold">FEELING DARK</tspan>
                  <tspan className="regular"> BLACK & RED </tspan>
                  <tspan className="bold">FEELING DARK</tspan>
                  <tspan className="regular"> BLACK & RED </tspan>
                  </>

              ) : (
                <>
                <tspan className="bold">FEELING SHINE</tspan>
                  <tspan className="regular"> WHITE & RED </tspan>
                  <tspan className="bold">FEELING SHINE</tspan>
                  <tspan className="regular"> WHITE & RED </tspan>
                </>
              )}
            </textPath>
          </text>
        </svg>
      </div>
    </div>
    <div className="ThemeButton_text__nAAK_ ThemeButton_gold__SOQk6 style-tTgQF" id="style-tTgQF"><svg viewBox="0 0 109 108" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_36_783)">
                <path d="M54.6062 7.6324L55.1213 0L58.2053 0.206107C58.9973 0.257634 59.5639 0.476622 59.8922 0.856632C60.2206 1.23664 60.3623 1.74547 60.3236 2.38311C60.3043 2.67939 60.2464 2.93702 60.1498 3.15601C60.0532 3.375 59.9244 3.55534 59.757 3.6906C59.5961 3.82586 59.3965 3.91603 59.1647 3.95468C59.3256 4.0062 59.4802 4.08349 59.6347 4.19299C59.7828 4.30248 59.918 4.4313 60.0274 4.59876C60.1369 4.75978 60.2206 4.95301 60.2721 5.16555C60.3236 5.38454 60.3429 5.62929 60.3236 5.89981C60.2914 6.40864 60.1755 6.80797 59.9759 7.10425C59.7763 7.40052 59.5059 7.60663 59.1454 7.71613C58.7848 7.83206 58.3534 7.87071 57.8383 7.83206L54.6126 7.61307L54.6062 7.6324ZM56.4927 6.15744L57.8899 6.25406C58.0379 6.26694 58.1731 6.24117 58.2826 6.18965C58.3921 6.13812 58.4822 6.04795 58.5401 5.93201C58.6045 5.81608 58.6432 5.66794 58.656 5.48115C58.6689 5.31369 58.6496 5.17199 58.5981 5.05606C58.5466 4.94012 58.4758 4.83707 58.3727 4.76622C58.2762 4.69537 58.1474 4.65029 57.9993 4.64385L56.6021 4.54723L56.4927 6.16388V6.15744ZM56.6923 3.19466L57.9993 3.28483C58.1796 3.29771 58.3212 3.2333 58.4307 3.10448C58.5401 2.97567 58.6045 2.79532 58.6174 2.58278C58.6367 2.29938 58.5917 2.10615 58.4822 1.99022C58.3727 1.87428 58.244 1.81632 58.0894 1.80344L56.7953 1.71326L56.6923 3.19466Z" fill="currentColor"></path>
                <path d="M60.388 7.99951L64.2512 1.00476L66.4274 1.4685L67.1163 9.42938L65.3135 9.04293L65.2169 7.60018L62.8411 7.0978L62.1779 8.37952L60.3816 7.99951H60.388ZM63.5751 5.62284L65.1461 5.95777L65.0109 2.72447L63.5751 5.62284Z" fill="currentColor"></path>
                <path d="M69.8721 10.5244C69.46 10.3762 69.1059 10.1959 68.8162 9.98332C68.5264 9.77077 68.314 9.49382 68.1852 9.15245C68.05 8.81109 68.0113 8.37955 68.0693 7.8514C68.1208 7.32325 68.2882 6.67273 68.5651 5.90627C68.8355 5.15913 69.1188 4.56657 69.4149 4.13504C69.7111 3.7035 70.0202 3.40078 70.3421 3.22044C70.664 3.04009 71.0053 2.95636 71.3658 2.96924C71.7264 2.98213 72.1063 3.05942 72.5054 3.20111C72.866 3.32993 73.1879 3.47163 73.4712 3.61333C73.7545 3.75503 73.9992 3.89673 74.1988 4.03198C74.3984 4.16724 74.5593 4.28962 74.6817 4.39911L74.2181 5.70016C74.1022 5.62931 73.9541 5.55202 73.7674 5.45541C73.5807 5.36524 73.3682 5.26218 73.1171 5.15913C72.8724 5.04964 72.6085 4.94658 72.3187 4.84353C72.0805 4.7598 71.8616 4.70827 71.6749 4.70183C71.4817 4.68895 71.3143 4.74048 71.1598 4.85641C71.0053 4.97235 70.8572 5.15913 70.7027 5.4232C70.5546 5.69372 70.3936 6.05441 70.2326 6.51171C70.0781 6.94324 69.9687 7.31037 69.9043 7.60665C69.8399 7.90293 69.827 8.14768 69.8721 8.34091C69.9172 8.53413 70.0073 8.68871 70.1554 8.81753C70.3035 8.94634 70.5159 9.05584 70.7864 9.15245C71.2757 9.32635 71.6813 9.46161 71.9968 9.55178C72.3123 9.64195 72.5634 9.70636 72.763 9.75145L72.2994 11.0525C72.1063 11.0525 71.8938 11.0396 71.662 11.001C71.4302 10.9623 71.1598 10.9044 70.8701 10.8335C70.5739 10.7562 70.2455 10.6532 69.8785 10.5179L69.8721 10.5244Z" fill="currentColor"></path>
                <path d="M72.8917 11.587L76.4071 4.7984L77.991 5.61639L76.581 8.33442L77.3794 8.74664L80.0127 6.66624L81.6867 7.53576L78.2743 10.0928L78.2421 14.363L76.5681 13.4935L76.6389 10.1765L75.8405 9.76429L74.4691 12.405L72.8853 11.587H72.8917Z" fill="currentColor"></path>
                <path d="M81.97 17.0232L85.7043 12.2698L84.1333 11.0332L85.1184 9.77722L89.6705 13.3583L88.6854 14.6143L87.1079 13.3712L83.3736 18.1245L81.97 17.0232Z" fill="currentColor"></path>
                <path d="M87.3525 21.8924C86.9855 21.5124 86.7022 21.1388 86.4962 20.7781C86.2902 20.4175 86.1936 20.0439 86.2 19.6574C86.2064 19.271 86.3481 18.8523 86.625 18.4015C86.8954 17.9506 87.3268 17.4418 87.9127 16.875C88.4986 16.3146 89.0201 15.9024 89.4772 15.6384C89.9408 15.3807 90.3593 15.2519 90.7456 15.2583C91.1319 15.2583 91.5054 15.3743 91.8595 15.5868C92.2136 15.7994 92.5742 16.1021 92.9412 16.4821C93.3146 16.8686 93.5979 17.2421 93.8039 17.6028C94.0035 17.9635 94.1001 18.3371 94.0872 18.7235C94.0743 19.11 93.9327 19.5222 93.6558 19.9795C93.379 20.4303 92.954 20.9392 92.3681 21.4995C91.7822 22.0663 91.2543 22.4785 90.7971 22.7362C90.34 22.9938 89.915 23.1162 89.5287 23.1162C89.1424 23.1162 88.7754 23.0002 88.4213 22.7877C88.0736 22.5751 87.7066 22.2724 87.3396 21.886L87.3525 21.8924ZM88.4986 20.7846C88.6917 20.9843 88.8784 21.1324 89.0587 21.2226C89.239 21.3127 89.4193 21.3385 89.606 21.2999C89.7927 21.2612 90.0116 21.1582 90.2627 20.9843C90.5138 20.8104 90.8036 20.5592 91.1448 20.2242C91.4925 19.8829 91.7629 19.593 91.9496 19.3547C92.1363 19.1164 92.2522 18.8974 92.2909 18.7106C92.3295 18.5239 92.3102 18.3435 92.22 18.1696C92.1299 17.9957 91.9883 17.8089 91.7951 17.6093C91.6019 17.4096 91.4217 17.2679 91.2543 17.1713C91.0869 17.0747 90.9066 17.0489 90.7199 17.0811C90.5267 17.1198 90.3078 17.2228 90.0567 17.3967C89.8056 17.5706 89.503 17.8282 89.1553 18.1696C88.8141 18.4981 88.5501 18.7815 88.3698 19.0198C88.1831 19.2581 88.0736 19.4771 88.0286 19.6639C87.9835 19.8507 88.0092 20.0375 88.0865 20.2178C88.1702 20.3981 88.3054 20.5849 88.505 20.791L88.4986 20.7846Z" fill="currentColor"></path>
                <path d="M91.6085 26.7488L98.0342 22.6138L99.7082 25.2159C100.14 25.8857 100.301 26.4654 100.191 26.9549C100.082 27.4444 99.7597 27.8631 99.2188 28.2109C98.9677 28.3719 98.7295 28.4749 98.4913 28.5329C98.2531 28.5844 98.0341 28.5844 97.8281 28.5329C97.6221 28.4814 97.4289 28.3783 97.2615 28.2173C97.313 28.3783 97.3452 28.5522 97.3517 28.739C97.3517 28.9258 97.3259 29.1061 97.2615 29.2929C97.1971 29.4797 97.0941 29.6536 96.9525 29.8275C96.8108 30.0014 96.6241 30.1624 96.3923 30.3106C95.9674 30.5875 95.5746 30.7357 95.2205 30.755C94.8664 30.7743 94.538 30.6777 94.229 30.4523C93.9264 30.2333 93.6302 29.9048 93.3533 29.4733L91.6021 26.7488H91.6085ZM93.9135 27.3864L94.6732 28.5651C94.7569 28.6939 94.8535 28.7841 94.963 28.8421C95.0724 28.9 95.1948 28.9194 95.3235 28.9C95.4523 28.8807 95.6004 28.8227 95.7549 28.7197C95.8966 28.6295 95.9931 28.5265 96.064 28.417C96.1283 28.3075 96.1605 28.1851 96.1605 28.0627C96.1605 27.9403 96.1155 27.818 96.0382 27.6892L95.2785 26.5105L93.9199 27.3864H93.9135ZM96.4116 25.7827L97.1199 26.884C97.2165 27.0322 97.3517 27.1159 97.5255 27.1224C97.6993 27.1288 97.8732 27.0773 98.0535 26.9613C98.2917 26.8068 98.4205 26.6522 98.4462 26.4976C98.472 26.343 98.4462 26.2013 98.3625 26.0725L97.6607 24.9776L96.4116 25.7827Z" fill="currentColor"></path>
                <path d="M96.1476 34.7548C95.9673 34.3683 95.8385 33.9948 95.7484 33.6276C95.6583 33.2669 95.6389 32.9191 95.6904 32.5842C95.742 32.2493 95.8836 31.9466 96.1218 31.6632C96.3601 31.3862 96.7142 31.135 97.1906 30.916L101.871 28.7712L102.612 30.3943L97.9375 32.5391C97.7315 32.6357 97.5834 32.7581 97.4997 32.9063C97.416 33.0544 97.3773 33.2283 97.3967 33.428C97.416 33.6276 97.4804 33.8466 97.5963 34.0914C97.7057 34.3297 97.828 34.5229 97.9697 34.6646C98.1049 34.8063 98.2594 34.8965 98.4333 34.9287C98.6007 34.9609 98.7874 34.9287 98.9999 34.8321L103.674 32.6873L104.415 34.3104L99.7338 36.4552C99.2574 36.6742 98.8389 36.7836 98.4719 36.7772C98.1049 36.7708 97.7765 36.687 97.4932 36.5067C97.2099 36.3263 96.9588 36.088 96.7399 35.7853C96.5275 35.4826 96.3279 35.1412 96.154 34.7483L96.1476 34.7548Z" fill="currentColor"></path>
                <path d="M98.3432 40.5773C98.2789 40.3712 98.2274 40.1651 98.1758 39.9525C98.1243 39.74 98.0857 39.5274 98.0471 39.3213C98.0084 39.1088 97.9762 38.9091 97.9569 38.7159C97.9312 38.5226 97.9183 38.3423 97.9119 38.1684L99.264 37.7626C99.309 37.9816 99.3605 38.2199 99.4249 38.4776C99.4893 38.7352 99.5601 39.0057 99.631 39.2698C99.7018 39.5403 99.779 39.7915 99.8499 40.0298C99.9207 40.2617 99.9979 40.4485 100.082 40.603C100.165 40.7512 100.268 40.8607 100.378 40.9186C100.487 40.9766 100.629 40.983 100.783 40.938L100.97 40.88C101.15 40.8285 101.266 40.7254 101.324 40.5837C101.382 40.442 101.382 40.2745 101.324 40.0942L101.157 39.5339C100.938 38.7996 100.925 38.1877 101.131 37.7047C101.337 37.2216 101.82 36.8673 102.586 36.6355L102.934 36.5324C103.649 36.3199 104.247 36.3714 104.717 36.687C105.187 37.009 105.548 37.563 105.786 38.3487C105.87 38.6386 105.941 38.922 105.992 39.1989C106.044 39.4759 106.089 39.7464 106.121 39.9976C106.153 40.2552 106.179 40.4807 106.185 40.6803L104.833 41.0861C104.769 40.7769 104.692 40.4291 104.595 40.0491C104.498 39.6691 104.408 39.3213 104.318 39.0121C104.254 38.806 104.183 38.6321 104.099 38.484C104.016 38.3359 103.913 38.2328 103.79 38.1813C103.668 38.1297 103.513 38.1233 103.333 38.1813L103.185 38.2264C102.979 38.2908 102.85 38.3938 102.786 38.5484C102.721 38.6965 102.728 38.9026 102.805 39.1474L103.011 39.8301C103.146 40.2874 103.179 40.6868 103.095 41.0281C103.011 41.3695 102.85 41.6658 102.606 41.9041C102.354 42.1424 102.052 42.3163 101.678 42.4258L101.311 42.5353C100.706 42.7156 100.217 42.7478 99.837 42.6319C99.4571 42.516 99.1545 42.2776 98.9227 41.917C98.6909 41.5563 98.4978 41.1119 98.3368 40.5837L98.3432 40.5773Z" fill="currentColor"></path>
                <path d="M99.3154 44.0167L106.81 42.516L107.158 44.2615L99.6631 45.7622L99.3154 44.0167Z" fill="currentColor"></path>
                <path d="M99.9207 46.7669L107.531 46.0778L107.66 47.4819L103.314 50.8118L107.917 50.3931L108.078 52.1708L100.468 52.86L100.339 51.443L104.672 48.1131L100.075 48.5317L99.9143 46.7669H99.9207Z" fill="currentColor"></path>
                <path d="M100.294 55.7391C100.3 55.5716 100.326 55.3784 100.371 55.1594C100.416 54.9404 100.5 54.7278 100.622 54.5282C100.745 54.3285 100.931 54.1675 101.17 54.0387C101.414 53.9099 101.736 53.8583 102.142 53.8777L106.25 54.0773C106.604 54.0966 106.9 54.161 107.132 54.2834C107.363 54.4058 107.544 54.5604 107.673 54.7407C107.801 54.9275 107.891 55.1272 107.937 55.3526C107.982 55.578 108.001 55.7906 107.994 55.9967C107.969 56.5442 107.937 57.0208 107.898 57.4266C107.859 57.8323 107.827 58.1866 107.788 58.4957C107.75 58.8049 107.718 59.0819 107.685 59.3266L106.185 59.2558L106.32 56.454C106.327 56.2672 106.295 56.1255 106.211 56.016C106.127 55.9065 105.999 55.8485 105.812 55.8421L104.968 55.8035L104.724 58.6825L103.327 58.6181L103.365 55.7326L102.573 55.694C102.425 55.6875 102.303 55.7069 102.213 55.7519C102.123 55.797 102.058 55.855 102.013 55.9387C101.968 56.016 101.942 56.1062 101.936 56.2028L101.801 59.0561L100.307 58.9852C100.288 58.6825 100.275 58.3412 100.262 57.974C100.255 57.6005 100.249 57.2269 100.255 56.8469C100.255 56.4669 100.268 56.0997 100.288 55.7584L100.294 55.7391Z" fill="currentColor"></path>
                <path d="M99.6245 62.051C99.6631 61.8385 99.7082 61.6324 99.7597 61.4198C99.8112 61.2073 99.8692 61.0012 99.9271 60.7951C99.9851 60.589 100.049 60.3957 100.12 60.209C100.185 60.0222 100.255 59.8547 100.326 59.7001L101.717 59.9449C101.659 60.1574 101.601 60.3957 101.537 60.6534C101.472 60.911 101.421 61.1815 101.363 61.452C101.305 61.7226 101.26 61.9802 101.215 62.2249C101.176 62.4633 101.157 62.6629 101.163 62.8368C101.17 63.0107 101.215 63.146 101.286 63.249C101.363 63.3521 101.479 63.4229 101.64 63.4487L101.833 63.4809C102.013 63.5131 102.168 63.4809 102.277 63.3779C102.387 63.2748 102.47 63.1331 102.503 62.9399L102.606 62.3602C102.741 61.6002 103.005 61.0527 103.41 60.7178C103.816 60.3829 104.402 60.2798 105.187 60.4215L105.542 60.4859C106.276 60.6147 106.784 60.9303 107.061 61.4327C107.338 61.9351 107.402 62.5856 107.261 63.3972C107.209 63.6935 107.145 63.9769 107.061 64.2538C106.984 64.5243 106.9 64.782 106.816 65.0267C106.733 65.2715 106.649 65.484 106.565 65.6643L105.175 65.4196C105.258 65.1104 105.342 64.7691 105.432 64.3891C105.522 64.0091 105.593 63.6548 105.651 63.3392C105.69 63.1267 105.702 62.9399 105.696 62.7724C105.69 62.605 105.645 62.4633 105.561 62.3602C105.477 62.2571 105.342 62.1863 105.155 62.1541L105.001 62.1283C104.788 62.0897 104.627 62.1283 104.498 62.2314C104.37 62.3344 104.292 62.5212 104.247 62.7789L104.125 63.4809C104.041 63.9511 103.887 64.3182 103.661 64.5952C103.436 64.8657 103.159 65.0525 102.831 65.1555C102.503 65.2521 102.148 65.2715 101.769 65.2006L101.395 65.1362C100.771 65.0267 100.32 64.8335 100.037 64.5565C99.7533 64.2796 99.5859 63.9253 99.5408 63.5067C99.4957 63.0816 99.5215 62.5985 99.6181 62.051H99.6245Z" fill="currentColor"></path>
                <path d="M98.253 67.7383C98.3174 67.5322 98.3882 67.3326 98.4655 67.1329C98.5427 66.9332 98.6264 66.7336 98.7101 66.5339C98.7938 66.3407 98.884 66.1539 98.9741 65.98C99.0642 65.8061 99.1544 65.6451 99.2445 65.5034L100.59 65.922C100.506 66.1281 100.416 66.3536 100.32 66.6047C100.223 66.8559 100.133 67.1136 100.043 67.3776C99.9528 67.6417 99.8691 67.8929 99.7982 68.1248C99.7274 68.3566 99.6823 68.5563 99.6695 68.7238C99.6566 68.8912 99.6823 69.0394 99.7403 69.1489C99.8047 69.2648 99.9141 69.3421 100.069 69.3936L100.255 69.4516C100.429 69.5096 100.584 69.4902 100.712 69.4065C100.841 69.3228 100.931 69.1875 100.989 69.0007L101.163 68.4404C101.395 67.7061 101.73 67.1973 102.168 66.9139C102.605 66.6305 103.211 66.6112 103.97 66.8431L104.312 66.9525C105.026 67.178 105.49 67.5515 105.702 68.0861C105.915 68.6143 105.896 69.2777 105.651 70.0635C105.561 70.3533 105.458 70.6238 105.348 70.8879C105.232 71.1455 105.123 71.3903 105.007 71.6222C104.891 71.854 104.782 72.0537 104.679 72.2212L103.333 71.8025C103.455 71.5062 103.584 71.1777 103.719 70.8106C103.855 70.4435 103.97 70.1021 104.067 69.7994C104.131 69.5933 104.17 69.4129 104.183 69.239C104.196 69.0716 104.17 68.9299 104.099 68.8139C104.028 68.698 103.906 68.6143 103.719 68.5563L103.571 68.5112C103.365 68.4468 103.198 68.4597 103.063 68.5499C102.927 68.64 102.818 68.8075 102.741 69.0587L102.528 69.735C102.387 70.1858 102.187 70.5337 101.929 70.7784C101.672 71.0232 101.369 71.1713 101.034 71.2293C100.7 71.2872 100.346 71.255 99.9785 71.1391L99.618 71.0232C99.0127 70.8364 98.5878 70.5852 98.3431 70.276C98.092 69.9669 97.9761 69.5933 97.989 69.1682C98.0019 68.7431 98.0856 68.2665 98.253 67.7319V67.7383Z" fill="currentColor"></path>
                <path d="M95.1818 75.6928L101.112 80.5105L100.558 81.38L94.1774 79.5959L98.4912 84.6133L97.931 85.4893L91.0547 82.1465L91.5955 81.3028L97.3258 84.0852L97.2164 84.2204L92.8704 79.3125L93.3854 78.5074L99.6759 80.3688L99.5857 80.5299L94.6474 76.5301L95.1882 75.6863L95.1818 75.6928Z" fill="currentColor"></path>
                <path d="M90.3593 82.8936L96.0446 87.9239L94.0035 90.2297C93.8555 90.3972 93.6752 90.5517 93.4756 90.6934C93.276 90.8351 93.0378 90.9253 92.7802 90.964C92.5227 91.0026 92.2265 90.964 91.8981 90.8351C91.5698 90.7063 91.2028 90.468 90.7972 90.1073C90.3915 89.7466 90.1018 89.4117 89.9344 89.0897C89.767 88.7676 89.6833 88.4778 89.6897 88.2008C89.6897 87.9303 89.7477 87.6791 89.8636 87.4601C89.9795 87.2412 90.1082 87.0479 90.2499 86.8805C90.3207 86.7967 90.4237 86.6872 90.5525 86.552C90.6813 86.4167 90.8229 86.275 90.971 86.1269C91.1191 85.9788 91.2543 85.8435 91.3831 85.7211C91.5118 85.5987 91.6149 85.5021 91.6857 85.4377L89.6704 83.6536L90.3593 82.8743V82.8936ZM92.2845 85.9852L91.0354 87.3957C90.8873 87.5632 90.7972 87.7435 90.7521 87.9368C90.7135 88.1364 90.7456 88.349 90.8487 88.5744C90.9581 88.7998 91.1642 89.051 91.4732 89.328C91.7758 89.5985 92.0462 89.766 92.2845 89.8433C92.5227 89.9206 92.7287 89.927 92.909 89.8626C93.0893 89.7982 93.2438 89.6951 93.3726 89.547L94.6603 88.0914L92.2845 85.9852Z" fill="currentColor"></path>
                <path d="M85.0798 88.4134C85.3244 88.2009 85.5691 88.0463 85.8137 87.9626C86.0649 87.8724 86.316 87.8788 86.5671 87.9626C86.8246 88.0527 87.0821 88.246 87.3461 88.5487L91.3187 93.0315L90.5396 93.7207L86.6701 89.3538C86.522 89.1863 86.3803 89.0833 86.2451 89.0446C86.1099 89.006 85.9812 89.0124 85.8588 89.064C85.7365 89.1155 85.627 89.1863 85.5176 89.2829L83.5538 91.0284L82.9229 90.3199L85.0733 88.4134H85.0798Z" fill="currentColor"></path>
                <path d="M80.1867 92.3359L84.1335 98.8218L81.7255 100.29C81.146 100.645 80.6245 100.767 80.1673 100.657C79.7102 100.548 79.3175 100.219 78.9826 99.6656C78.8152 99.395 78.7187 99.1374 78.6736 98.8926C78.635 98.6479 78.6414 98.4225 78.7058 98.2099C78.7637 97.9974 78.8668 97.817 79.0084 97.6689C78.8668 97.7075 78.7058 97.7333 78.5319 97.7462C78.3581 97.7591 78.1714 97.7333 77.9847 97.6753C77.7915 97.6174 77.6048 97.5208 77.4245 97.3791C77.2442 97.2374 77.0704 97.0377 76.9223 96.7865C76.6455 96.3356 76.5038 95.9299 76.4974 95.5821C76.4909 95.2343 76.5875 94.9122 76.7935 94.6353C76.9996 94.3583 77.2957 94.1007 77.6885 93.8624L80.1931 92.3359H80.1867ZM79.781 93.6691L78.1907 94.6417C77.9782 94.7705 77.8173 94.9186 77.7078 95.0797C77.5984 95.2407 77.5469 95.4339 77.5597 95.6529C77.5726 95.8719 77.6692 96.1295 77.8495 96.4258C77.9847 96.6448 78.1392 96.7994 78.3324 96.896C78.5191 96.9926 78.7187 97.0377 78.9247 97.0248C79.1307 97.0119 79.3303 96.9475 79.5235 96.8316L81.1138 95.859L79.781 93.6691ZM81.5323 96.5482L79.9871 97.4885C79.736 97.6431 79.5879 97.8621 79.5428 98.1391C79.4977 98.416 79.5686 98.7123 79.7424 99.015C79.9613 99.4015 80.206 99.6205 80.4635 99.6656C80.7275 99.7106 80.985 99.6527 81.249 99.4916L82.7621 98.5706L81.5323 96.5482Z" fill="currentColor"></path>
                <path d="M73.5099 96.0587C73.8061 95.9234 74.0829 95.8525 74.3469 95.8461C74.6109 95.8397 74.8491 95.9105 75.068 96.0715C75.2869 96.2326 75.4736 96.4966 75.641 96.8573L78.107 102.313L77.1605 102.744L74.759 97.4306C74.6688 97.2309 74.5658 97.0892 74.4435 97.0119C74.3276 96.9346 74.1988 96.9024 74.07 96.9153C73.9413 96.9282 73.8125 96.9668 73.6773 97.0248L71.2822 98.1068L70.8894 97.2438L73.5099 96.0587Z" fill="currentColor"></path>
                <path d="M67.6764 98.2228C68.0756 98.1004 68.4555 98.0231 68.8096 97.9909C69.1637 97.9587 69.4921 97.9909 69.7883 98.094C70.0845 98.1906 70.342 98.3774 70.5609 98.635C70.7798 98.8991 70.9601 99.2598 71.1017 99.7171L72.6277 104.702L71.6361 105.005L70.1102 100.033C69.9943 99.6591 69.8398 99.3886 69.6402 99.2211C69.4406 99.0537 69.2024 98.9699 68.9126 98.957C68.6293 98.9506 68.301 98.9957 67.9404 99.1116C67.5734 99.2276 67.2708 99.3628 67.039 99.5367C66.8072 99.7042 66.6527 99.9103 66.5819 100.161C66.5111 100.413 66.5304 100.722 66.6463 101.095L68.1722 106.068L67.1807 106.37L65.6547 101.385C65.5131 100.928 65.4616 100.522 65.5002 100.181C65.5388 99.8394 65.6483 99.5432 65.835 99.2984C66.0217 99.0537 66.2728 98.8411 66.5819 98.6672C66.8974 98.4933 67.2515 98.3452 67.6636 98.2164L67.6764 98.2228Z" fill="currentColor"></path>
                <path d="M62.1779 99.5754C62.3453 99.5496 62.532 99.5367 62.7252 99.5367C62.9183 99.5367 63.1115 99.5689 63.2982 99.6398C63.4849 99.7106 63.6523 99.8395 63.7939 100.026C63.9356 100.213 64.0322 100.477 64.0901 100.825L64.8177 105.282C64.8692 105.578 64.8627 105.836 64.8048 106.055C64.7468 106.274 64.6503 106.454 64.5215 106.596C64.3927 106.738 64.2382 106.854 64.0579 106.931C63.8776 107.015 63.6974 107.066 63.5171 107.098C63.0728 107.163 62.6672 107.221 62.3002 107.272C61.9268 107.324 61.5855 107.369 61.2636 107.407C60.9417 107.446 60.6326 107.485 60.3429 107.517L60.2077 106.673L63.0921 106.203C63.3433 106.164 63.53 106.068 63.6523 105.913C63.7746 105.759 63.8133 105.546 63.7682 105.269L63.5171 103.71L60.2721 104.174L60.1433 103.382L63.3626 102.783L63.1115 101.25C63.0728 101.018 63.0084 100.851 62.9119 100.748C62.8153 100.645 62.6994 100.587 62.5706 100.561C62.4419 100.535 62.3002 100.542 62.1586 100.561L59.2934 101.031L59.1582 100.187C59.4673 100.116 59.7956 100.046 60.1369 99.9683C60.4781 99.8974 60.8258 99.8266 61.1735 99.7557C61.5211 99.6913 61.8624 99.6269 62.1908 99.5754H62.1779Z" fill="currentColor"></path>
                <path d="M52.9901 100.381L52.3784 108L49.3008 107.755C48.5089 107.691 47.9487 107.465 47.6268 107.085C47.3049 106.699 47.1697 106.19 47.2147 105.552C47.2405 105.256 47.2984 104.999 47.4015 104.78C47.5045 104.561 47.6332 104.38 47.8006 104.245C47.968 104.11 48.1612 104.026 48.393 103.987C48.232 103.936 48.0775 103.852 47.9294 103.743C47.7813 103.633 47.6526 103.498 47.5431 103.337C47.4336 103.176 47.3564 102.983 47.3049 102.764C47.2534 102.545 47.2405 102.3 47.2598 102.023C47.2984 101.514 47.4208 101.115 47.6204 100.818C47.82 100.522 48.0968 100.323 48.4574 100.213C48.8179 100.104 49.2558 100.071 49.7708 100.11L52.9965 100.368L52.9901 100.381ZM51.0843 101.83L49.6871 101.72C49.5391 101.707 49.4038 101.727 49.2944 101.785C49.1849 101.836 49.0948 101.92 49.0304 102.036C48.966 102.152 48.9274 102.3 48.9145 102.487C48.9016 102.648 48.9145 102.796 48.966 102.912C49.0175 103.034 49.0884 103.131 49.1849 103.202C49.2815 103.272 49.4038 103.318 49.5584 103.33L50.9555 103.44L51.0843 101.83ZM50.8461 104.786L49.539 104.683C49.3588 104.67 49.2171 104.728 49.1077 104.863C48.9982 104.999 48.9338 105.166 48.9145 105.379C48.8952 105.662 48.9338 105.855 49.0433 105.971C49.1527 106.087 49.2815 106.151 49.4296 106.164L50.7237 106.267L50.8396 104.786H50.8461Z" fill="currentColor"></path>
                <path d="M47.2276 99.9425L43.2743 106.892L41.1046 106.403L40.5122 98.429L42.3086 98.8347L42.3858 100.277L44.7552 100.812L45.4312 99.5368L47.2212 99.9425H47.2276ZM44.0083 102.281L42.4373 101.926L42.5339 105.16L44.0083 102.281Z" fill="currentColor"></path>
                <path d="M37.7695 97.2889C38.1816 97.4435 38.5293 97.6303 38.8126 97.8428C39.0958 98.0618 39.3083 98.3388 39.4371 98.6801C39.5659 99.0215 39.5981 99.453 39.5401 99.9812C39.4822 100.509 39.3083 101.153 39.0186 101.92C38.7417 102.667 38.452 103.253 38.1494 103.678C37.8468 104.103 37.5313 104.406 37.2094 104.58C36.881 104.754 36.5397 104.838 36.1792 104.818C35.8186 104.799 35.4388 104.715 35.0396 104.567C34.679 104.432 34.3571 104.29 34.0738 104.142C33.7905 103.994 33.5523 103.852 33.3527 103.71C33.1531 103.575 32.9921 103.453 32.8762 103.337L33.3591 102.042C33.4686 102.113 33.6231 102.197 33.8034 102.293C33.9901 102.39 34.2026 102.493 34.4472 102.603C34.6919 102.712 34.9559 102.822 35.2392 102.925C35.4774 103.015 35.6899 103.066 35.883 103.073C36.0697 103.086 36.2436 103.034 36.3981 102.925C36.5526 102.815 36.7071 102.628 36.8617 102.358C37.0162 102.094 37.1772 101.733 37.351 101.276C37.512 100.844 37.6279 100.477 37.6922 100.181C37.7566 99.8846 37.7759 99.6398 37.7373 99.4466C37.6987 99.2534 37.6085 99.0988 37.4605 98.97C37.3124 98.8412 37.1063 98.7252 36.8359 98.6286C36.3466 98.4483 35.9474 98.3066 35.6319 98.2099C35.3164 98.1133 35.0653 98.0489 34.8657 98.0038L35.3486 96.7092C35.5418 96.7092 35.7542 96.7286 35.986 96.7672C36.2178 96.8058 36.4818 96.8638 36.778 96.9475C37.0741 97.0248 37.4025 97.1343 37.7695 97.2696V97.2889Z" fill="currentColor"></path>
                <path d="M34.7692 96.1939L31.1636 102.937L29.5926 102.1L31.0348 99.4015L30.2429 98.9764L27.5837 101.025L25.9226 100.136L29.3672 97.6173L29.4509 93.3535L31.1121 94.2424L30.9962 97.5594L31.7881 97.9845L33.1917 95.3566L34.7627 96.1939H34.7692Z" fill="currentColor"></path>
                <path d="M25.7617 90.6484L21.9694 95.3566L23.5275 96.6126L22.5231 97.8557L18.0161 94.223L19.0205 92.98L20.5851 94.2424L24.3774 89.5341L25.7617 90.6548V90.6484Z" fill="currentColor"></path>
                <path d="M20.4433 85.7147C20.8039 86.1012 21.0872 86.4747 21.2868 86.8354C21.4864 87.1961 21.5829 87.5761 21.5636 87.9626C21.5443 88.349 21.4027 88.7677 21.1258 89.2121C20.8489 89.6565 20.4111 90.1589 19.8188 90.7192C19.2264 91.2732 18.7049 91.6789 18.2349 91.9301C17.7713 92.1813 17.3464 92.3037 16.9601 92.2972C16.5738 92.2908 16.2068 92.1749 15.8526 91.9559C15.4985 91.7369 15.1444 91.4342 14.7774 91.0477C14.4104 90.6548 14.1271 90.2813 13.9275 89.9141C13.7279 89.547 13.6378 89.1734 13.6571 88.7934C13.6764 88.407 13.8245 87.9948 14.1014 87.5439C14.3847 87.093 14.816 86.5907 15.4084 86.0367C16.0007 85.4764 16.5287 85.0706 16.9923 84.8194C17.4558 84.5682 17.8808 84.4459 18.2671 84.4587C18.6534 84.4652 19.0204 84.5811 19.3681 84.8001C19.7158 85.0191 20.0763 85.3218 20.4433 85.7147ZM19.2779 86.8096C19.0848 86.6035 18.8981 86.4554 18.7242 86.3652C18.5504 86.2751 18.3701 86.2493 18.1769 86.2815C17.9838 86.3137 17.7649 86.4168 17.5202 86.5907C17.2691 86.7646 16.9729 87.0093 16.6317 87.3378C16.2776 87.6727 16.0072 87.9561 15.814 88.2009C15.6273 88.4392 15.505 88.6582 15.4663 88.845C15.4213 89.0317 15.4406 89.2121 15.5307 89.386C15.6209 89.5599 15.7561 89.7467 15.9492 89.9528C16.1359 90.1524 16.3162 90.3006 16.4836 90.3972C16.651 90.4938 16.8313 90.526 17.018 90.4938C17.2112 90.4616 17.4301 90.3586 17.6812 90.1847C17.9323 90.0107 18.2413 89.7596 18.5955 89.4246C18.9431 89.1026 19.2071 88.8192 19.3938 88.5809C19.5805 88.3426 19.6964 88.13 19.7415 87.9432C19.7866 87.7564 19.7673 87.5697 19.69 87.3893C19.6127 87.209 19.4711 87.0158 19.2779 86.8096Z" fill="currentColor"></path>
                <path d="M16.239 80.8003L9.76179 84.8581L8.11997 82.2367C7.69502 81.5604 7.5405 80.9807 7.65639 80.4912C7.77229 80.0017 8.10065 79.5895 8.64149 79.2481C8.89259 79.0935 9.13726 78.9905 9.36904 78.9389C9.60727 78.8874 9.82618 78.8874 10.0322 78.9389C10.2382 78.9905 10.425 79.1 10.5924 79.261C10.5409 79.1 10.5151 78.9261 10.5087 78.7393C10.5087 78.5525 10.5409 78.3721 10.6052 78.1854C10.6696 77.9986 10.7791 77.8247 10.9207 77.6508C11.0624 77.4769 11.2555 77.3223 11.4873 77.1742C11.9187 76.9036 12.3115 76.7619 12.6656 76.7491C13.0197 76.7362 13.3481 76.8392 13.6507 77.0647C13.9533 77.2901 14.243 77.6186 14.5134 78.0565L16.2325 80.8003H16.239ZM11.4229 81.7085L10.7276 80.6007C10.631 80.4525 10.5022 80.3688 10.3284 80.3559C10.1545 80.3495 9.9807 80.401 9.80042 80.5105C9.5622 80.6586 9.42699 80.8132 9.40124 80.9678C9.37548 81.1224 9.40124 81.2641 9.4785 81.3929L10.1674 82.4943L11.4229 81.7085ZM13.9404 80.1369L13.2 78.9518C13.1227 78.823 13.0261 78.7328 12.9167 78.6749C12.8072 78.6169 12.6849 78.5976 12.5561 78.6169C12.4273 78.6362 12.2793 78.6942 12.1247 78.7908C11.9831 78.881 11.8801 78.9776 11.8157 79.0871C11.7449 79.1966 11.7127 79.3125 11.7127 79.4349C11.7127 79.5573 11.7513 79.6796 11.835 79.8085L12.5754 80.9936L13.9468 80.1369H13.9404Z" fill="currentColor"></path>
                <path d="M11.8029 72.7364C11.9767 73.1229 12.1055 73.5029 12.1892 73.8636C12.2729 74.2243 12.2922 74.5785 12.2343 74.907C12.1763 75.2355 12.0282 75.5446 11.79 75.8216C11.5453 76.0986 11.1912 76.3433 10.7083 76.5559L6.00176 78.6427L5.28064 77.0131L9.98077 74.9263C10.1868 74.8361 10.3349 74.7138 10.425 74.5656C10.5152 74.4175 10.5474 74.2436 10.5345 74.0439C10.5152 73.8443 10.4572 73.6253 10.3478 73.3805C10.2448 73.1422 10.1224 72.949 9.98721 72.8008C9.852 72.6527 9.69748 72.5625 9.53007 72.5303C9.36267 72.4981 9.17595 72.5303 8.96348 72.6205L4.26335 74.7073L3.54224 73.0778L8.24881 70.991C8.72526 70.7784 9.1502 70.6754 9.5172 70.6818C9.88419 70.6818 10.2126 70.7849 10.4959 70.9652C10.7792 71.1455 11.0238 71.3903 11.2363 71.693C11.4488 71.9957 11.6355 72.3435 11.8093 72.7364H11.8029Z" fill="currentColor"></path>
                <path d="M9.67823 66.8817C9.73617 67.0878 9.78768 67.2939 9.83919 67.5065C9.88426 67.719 9.92933 67.9316 9.96152 68.1441C10.0002 68.3567 10.0259 68.5563 10.0452 68.7495C10.0645 68.9428 10.0774 69.1231 10.0839 69.297L8.72532 69.6835C8.68669 69.4645 8.63519 69.2262 8.5708 68.9685C8.51285 68.7045 8.44203 68.4404 8.37764 68.1699C8.30682 67.8994 8.236 67.6482 8.17161 67.4099C8.10722 67.178 8.02996 66.9848 7.94626 66.8366C7.86256 66.682 7.76598 66.579 7.65009 66.521C7.5342 66.4631 7.39899 66.4566 7.24446 66.5017L7.05774 66.5532C6.87746 66.6048 6.76157 66.7014 6.70362 66.8431C6.64568 66.9848 6.63924 67.1458 6.69719 67.3326L6.85815 67.8994C7.07062 68.6401 7.07062 69.2455 6.85815 69.7286C6.64568 70.2052 6.15635 70.5594 5.39016 70.7784L5.04248 70.875C4.32137 71.0811 3.72902 71.0232 3.25901 70.6947C2.78899 70.3662 2.44131 69.8059 2.21596 69.0136C2.13226 68.7238 2.06788 68.4404 2.01637 68.157C1.9713 67.88 1.92623 67.6095 1.90048 67.3519C1.86828 67.0943 1.84897 66.8688 1.84253 66.6692L3.20106 66.2827C3.25901 66.5919 3.33627 66.9397 3.42641 67.3261C3.51655 67.7126 3.60025 68.0539 3.69039 68.3631C3.74834 68.5692 3.81916 68.7431 3.90286 68.8912C3.98656 69.0394 4.08314 69.1424 4.20547 69.2004C4.3278 69.2584 4.47589 69.2584 4.66261 69.2068L4.81069 69.1618C5.01673 69.1038 5.15194 69.0007 5.21632 68.8462C5.28071 68.698 5.27427 68.4919 5.20344 68.2407L5.01029 67.558C4.88152 67.1007 4.85576 66.7014 4.93947 66.3536C5.02317 66.0058 5.19057 65.7159 5.44167 65.484C5.69277 65.2522 6.00182 65.0847 6.37526 64.9752L6.74226 64.8722C7.34748 64.6983 7.84325 64.6725 8.22312 64.7949C8.60299 64.9173 8.9056 65.162 9.13095 65.5227C9.3563 65.8834 9.54302 66.3342 9.69754 66.8688L9.67823 66.8817Z" fill="currentColor"></path>
                <path d="M8.75742 63.4358L1.24365 64.8399L0.915283 63.088L8.42905 61.6839L8.75742 63.4358Z" fill="currentColor"></path>
                <path d="M8.17796 60.6663L0.55473 61.2588L0.445275 59.8483L4.82992 56.5699L0.219926 56.9306L0.0847168 55.1529L7.70794 54.5604L7.8174 55.9774L3.44563 59.2557L8.04919 58.8951L8.18439 60.6598L8.17796 60.6663Z" fill="currentColor"></path>
                <path d="M7.92038 51.7071C7.9075 51.8745 7.88175 52.0678 7.83668 52.2868C7.79161 52.5057 7.70147 52.7183 7.57914 52.918C7.4568 53.1176 7.27009 53.2786 7.02542 53.401C6.78076 53.5234 6.45883 53.5749 6.0532 53.5492L1.95186 53.298C1.59774 53.2786 1.30156 53.2014 1.06978 53.079C0.837989 52.9566 0.664149 52.802 0.535378 52.6152C0.406607 52.4285 0.322906 52.2223 0.277837 52.0034C0.232767 51.7779 0.213451 51.5654 0.226328 51.3593C0.258521 50.8118 0.297152 50.3352 0.335783 49.9294C0.374415 49.5236 0.419484 49.1694 0.458116 48.8602C0.496747 48.5511 0.535378 48.2741 0.567571 48.0294L2.06775 48.1195L1.90035 50.9149C1.88747 51.1016 1.9261 51.2498 2.00336 51.3528C2.08063 51.4623 2.21584 51.5203 2.40255 51.5332L3.246 51.5847L3.52286 48.7121L4.92002 48.7958L4.8492 51.6813L5.64114 51.7264C5.78922 51.7328 5.91155 51.72 6.00169 51.6749C6.09183 51.6298 6.15622 51.5718 6.20129 51.4881C6.24636 51.4108 6.27211 51.3206 6.27855 51.224L6.45239 48.3772L7.94613 48.4673C7.95901 48.7701 7.97189 49.1114 7.97832 49.4785C7.97832 49.8521 7.97832 50.2257 7.97832 50.6057C7.97189 50.9857 7.95257 51.3464 7.93325 51.6942L7.92038 51.7071Z" fill="currentColor"></path>
                <path d="M8.66717 45.4015C8.62854 45.614 8.58347 45.8201 8.52552 46.0327C8.47401 46.2452 8.40963 46.4513 8.35168 46.6574C8.28729 46.8636 8.22291 47.0568 8.15208 47.2371C8.08126 47.4175 8.01044 47.5849 7.93961 47.7395L6.55533 47.4754C6.61971 47.2629 6.67766 47.0246 6.74204 46.7669C6.80643 46.5093 6.86438 46.2388 6.92876 45.9683C6.99315 45.6978 7.03822 45.4401 7.08329 45.1954C7.12836 44.9571 7.14767 44.7574 7.14123 44.5835C7.14123 44.4096 7.09616 44.2743 7.0189 44.1648C6.94164 44.0618 6.82575 43.9909 6.66478 43.9587L6.47163 43.9201C6.29135 43.8879 6.13682 43.9201 6.02093 44.0167C5.90504 44.1133 5.82777 44.2615 5.78914 44.4482L5.67969 45.0279C5.53804 45.7815 5.26118 46.329 4.85555 46.6574C4.44993 46.9924 3.85758 47.0825 3.07208 46.9344L2.71796 46.87C1.98397 46.7283 1.48176 46.4063 1.2049 45.9039C0.934486 45.4015 0.876539 44.7445 1.03106 43.933C1.08901 43.6367 1.15983 43.3533 1.24354 43.0828C1.32724 42.8123 1.41094 42.5546 1.50108 42.3163C1.59122 42.0716 1.67492 41.8655 1.75862 41.6851L3.1429 41.9492C3.0592 42.2519 2.96906 42.5997 2.87248 42.9797C2.77591 43.3597 2.70508 43.7075 2.6407 44.0296C2.60207 44.2357 2.58275 44.4289 2.58919 44.5964C2.58919 44.7638 2.6407 44.9055 2.71796 45.0086C2.80166 45.1181 2.93687 45.1889 3.12359 45.2211L3.27811 45.2469C3.48415 45.2855 3.65155 45.2533 3.78032 45.1503C3.90265 45.0472 3.99279 44.8669 4.03786 44.6093L4.17307 43.9136C4.26321 43.4499 4.41773 43.0828 4.64952 42.8058C4.88131 42.5353 5.15816 42.355 5.48653 42.2584C5.8149 42.1617 6.16901 42.1553 6.54889 42.2261L6.92232 42.297C7.54042 42.4129 7.99756 42.6126 8.27442 42.896C8.55771 43.1794 8.71867 43.5272 8.75731 43.9523C8.79594 44.3774 8.75731 44.8605 8.66073 45.4079L8.66717 45.4015Z" fill="currentColor"></path>
                <path d="M10.116 39.7206C10.0517 39.9203 9.97439 40.1264 9.89713 40.3261C9.81986 40.5257 9.72973 40.7254 9.64602 40.9186C9.55589 41.1119 9.46575 41.2986 9.37561 41.4661C9.28547 41.64 9.18889 41.7946 9.09875 41.9427L7.75953 41.5047C7.84967 41.3051 7.93981 41.0732 8.03639 40.8285C8.13297 40.5773 8.22955 40.3196 8.31969 40.062C8.40983 39.7979 8.49996 39.5532 8.57723 39.3149C8.65449 39.0894 8.69956 38.8833 8.71244 38.7159C8.73175 38.542 8.71244 38.4003 8.64805 38.2908C8.5901 38.1748 8.48065 38.0975 8.31969 38.046L8.13297 37.988C7.95913 37.9301 7.8046 37.943 7.67583 38.0267C7.54706 38.1104 7.45048 38.2457 7.39254 38.426L7.21226 38.9864C6.97403 39.7206 6.63279 40.223 6.18853 40.5C5.74427 40.7769 5.14549 40.7898 4.38574 40.5451L4.0445 40.4356C3.33626 40.2037 2.87269 39.8172 2.66665 39.2891C2.46062 38.7545 2.48637 38.0975 2.74391 37.3118C2.84049 37.0219 2.94351 36.7514 3.0594 36.4938C3.1753 36.2361 3.29119 35.9914 3.41352 35.766C3.52942 35.5405 3.64531 35.3409 3.74833 35.1734L5.08754 35.6114C4.96521 35.9012 4.83 36.2297 4.68835 36.5968C4.5467 36.964 4.42437 37.2989 4.32779 37.608C4.26341 37.8077 4.21834 37.9945 4.20546 38.162C4.19259 38.3294 4.21834 38.4711 4.28272 38.587C4.34711 38.703 4.47588 38.7932 4.65616 38.8511L4.80425 38.8962C5.00384 38.9606 5.17768 38.9542 5.31289 38.864C5.4481 38.7738 5.55755 38.6064 5.64126 38.3552L5.86017 37.6789C6.00825 37.228 6.21429 36.8802 6.47183 36.6419C6.72937 36.4036 7.03198 36.2555 7.37322 36.2039C7.71446 36.1524 8.06214 36.1846 8.42914 36.307L8.7897 36.4229C9.38848 36.6226 9.81343 36.8738 10.0581 37.1894C10.3028 37.505 10.4186 37.8721 10.3993 38.2972C10.38 38.7223 10.2899 39.1989 10.116 39.7271V39.7206Z" fill="currentColor"></path>
                <path d="M13.2772 31.8177L7.40527 26.9227L7.97186 26.0596L14.3331 27.921L10.0837 22.8457L10.6567 21.9761L17.488 25.4091L16.9407 26.2464L11.2491 23.3931L11.365 23.2579L15.6466 28.2173L15.1186 29.016L8.84751 27.0773L8.93764 26.9163L13.8245 30.9804L13.2772 31.8177Z" fill="currentColor"></path>
                <path d="M18.1898 24.6813L12.5625 19.5802L14.6293 17.3001C14.7838 17.1326 14.9576 16.9781 15.1637 16.8428C15.3697 16.7075 15.6015 16.6174 15.859 16.5787C16.1166 16.5401 16.4127 16.5852 16.7411 16.7204C17.0695 16.8492 17.43 17.1004 17.8357 17.4611C18.2348 17.8283 18.5181 18.1696 18.6855 18.4917C18.8529 18.8137 18.9302 19.11 18.9238 19.3805C18.9238 19.651 18.8594 19.9022 18.7435 20.1212C18.6276 20.3402 18.4988 20.5334 18.3507 20.6944C18.2799 20.7782 18.1769 20.8812 18.0417 21.0165C17.9065 21.1517 17.7713 21.2934 17.6232 21.4351C17.4751 21.5768 17.3335 21.7121 17.2047 21.8345C17.0759 21.9568 16.9729 22.047 16.8956 22.1114L18.8851 23.9213L18.1833 24.6942L18.1898 24.6813ZM16.3033 21.5639L17.5717 20.1663C17.7198 20.0053 17.8163 19.8249 17.8614 19.6252C17.9065 19.4256 17.8743 19.213 17.7713 18.9876C17.6683 18.7622 17.4622 18.5045 17.1596 18.2276C16.857 17.9571 16.593 17.7767 16.3548 17.6994C16.123 17.6221 15.9105 17.6093 15.7367 17.6737C15.5564 17.7316 15.4019 17.8347 15.2667 17.9828L13.9597 19.4256L16.3097 21.5575L16.3033 21.5639Z" fill="currentColor"></path>
                <path d="M23.5338 19.2259C23.2891 19.4385 23.0444 19.5866 22.7933 19.6703C22.5422 19.7541 22.2911 19.7476 22.04 19.6574C21.7889 19.5608 21.5314 19.3676 21.2674 19.0649L17.3528 14.537L18.1383 13.8542L21.9499 18.2662C22.0915 18.4337 22.2332 18.5367 22.3684 18.5754C22.5036 18.614 22.6324 18.614 22.7547 18.5625C22.877 18.511 22.9929 18.4401 23.096 18.3435L25.0855 16.6238L25.7036 17.3387L23.5273 19.2195L23.5338 19.2259Z" fill="currentColor"></path>
                <path d="M28.4787 15.355L24.6091 8.81752L27.0365 7.38121C27.6224 7.0334 28.1439 6.91747 28.5946 7.0334C29.0517 7.14934 29.438 7.48426 29.7728 8.03818C29.9338 8.30869 30.0304 8.56632 30.069 8.81108C30.1076 9.05583 30.0948 9.28126 30.0304 9.49381C29.966 9.70635 29.863 9.88026 29.7213 10.0284C29.8694 9.98975 30.0239 9.97043 30.2042 9.95755C30.3781 9.95111 30.5648 9.97687 30.7515 10.0348C30.9382 10.0928 31.1249 10.1959 31.3116 10.3376C31.4919 10.4793 31.6593 10.6789 31.8074 10.9366C32.0778 11.3939 32.213 11.7932 32.2195 12.1474C32.2259 12.5017 32.1229 12.8173 31.9104 13.0942C31.698 13.3712 31.4018 13.6224 31.009 13.8543L28.4851 15.3485L28.4787 15.355ZM27.1845 11.1298L28.7427 10.2087C28.9938 10.0606 29.1483 9.84161 29.1934 9.56466C29.2384 9.2877 29.1741 8.99142 29.0066 8.6887C28.7942 8.29581 28.5495 8.07682 28.292 8.02529C28.0344 7.97377 27.7705 8.02529 27.5 8.18632L25.9741 9.08803L27.1845 11.1298ZM28.9036 14.0282L30.5068 13.0749C30.7193 12.9461 30.8803 12.8044 30.9962 12.6434C31.1121 12.4824 31.1571 12.2891 31.1507 12.0766C31.1442 11.864 31.0477 11.6 30.8674 11.2972C30.7386 11.0783 30.5777 10.9172 30.3909 10.8206C30.2042 10.7176 30.0046 10.6725 29.7986 10.6854C29.5926 10.6982 29.393 10.7562 29.1998 10.8721L27.5966 11.8254L28.9036 14.0346V14.0282Z" fill="currentColor"></path>
                <path d="M35.207 11.7223C34.9108 11.8511 34.634 11.922 34.37 11.9284C34.106 11.9349 33.8678 11.8576 33.6553 11.6901C33.4429 11.5227 33.2561 11.2586 33.0952 10.8979L30.6936 5.41029L31.6465 4.99164L33.9837 10.3375C34.0738 10.5372 34.1769 10.6789 34.2927 10.7626C34.4086 10.8464 34.531 10.8786 34.6662 10.8657C34.7949 10.8528 34.9302 10.8206 35.0589 10.7626L37.4669 9.70633L37.8468 10.5758L35.2135 11.7288L35.207 11.7223Z" fill="currentColor"></path>
                <path d="M41.0596 9.62908C40.6604 9.74501 40.2805 9.81586 39.92 9.84807C39.5594 9.87383 39.2375 9.83518 38.9413 9.73213C38.6452 9.62908 38.3876 9.44229 38.1751 9.17822C37.9562 8.91414 37.7824 8.54702 37.6472 8.08972L36.1792 3.08518L37.1772 2.79534L38.6387 7.787C38.7482 8.16057 38.9027 8.43752 39.0959 8.60498C39.289 8.77244 39.5337 8.86262 39.817 8.8755C40.1003 8.88838 40.4286 8.84329 40.7892 8.7338C41.1562 8.62431 41.4588 8.48905 41.697 8.32159C41.9352 8.15412 42.0833 7.94802 42.1606 7.70327C42.2379 7.45851 42.2185 7.14291 42.1091 6.76934L40.6475 1.77769L41.6455 1.48785L43.1135 6.49239C43.2487 6.95613 43.2938 7.35546 43.2552 7.69682C43.2101 8.03819 43.0942 8.33447 42.9075 8.57922C42.7208 8.82397 42.4632 9.03008 42.1542 9.20398C41.8387 9.37788 41.4781 9.51958 41.066 9.64196L41.0596 9.62908Z" fill="currentColor"></path>
                <path d="M46.5839 8.34734C46.4165 8.3731 46.2298 8.38598 46.0366 8.37954C45.8434 8.37954 45.6503 8.3409 45.4636 8.27005C45.2769 8.1992 45.1095 8.07038 44.9742 7.87716C44.8326 7.69037 44.7425 7.41986 44.6845 7.07849L44.0149 2.61499C43.9698 2.31871 43.9763 2.05464 44.0407 1.84209C44.105 1.62954 44.1952 1.44276 44.3304 1.30106C44.4592 1.15936 44.6201 1.04987 44.794 0.972578C44.9678 0.895288 45.1545 0.83732 45.3348 0.811557C45.7791 0.753589 46.1847 0.702063 46.5581 0.656977C46.9316 0.611891 47.2792 0.573246 47.5947 0.534601C47.9167 0.502397 48.2257 0.470192 48.5154 0.437988L48.6442 1.28174L45.7533 1.71327C45.5022 1.75192 45.3155 1.84853 45.1931 1.99667C45.0708 2.14481 45.0322 2.3638 45.0708 2.64076L45.3026 4.19944L48.5541 3.77434L48.6764 4.56657L45.4443 5.12692L45.676 6.66628C45.7082 6.89815 45.7726 7.06561 45.8692 7.16867C45.9658 7.27172 46.0752 7.33613 46.2104 7.35545C46.3392 7.37477 46.4809 7.37477 46.6225 7.35545L49.4941 6.92391L49.6229 7.76766C49.3138 7.83207 48.9854 7.90292 48.6442 7.97377C48.303 8.04462 47.9553 8.10903 47.6076 8.17344C47.2599 8.23784 46.9187 8.28937 46.5839 8.3409V8.34734Z" fill="currentColor"></path>
            </g>
            <defs>
                <clipPath id="clip0_36_783">
                    <rect width="108" height="108" fill="white" transform="translate(0.0847168)"></rect>
                </clipPath>
            </defs>
        </svg></div>
    <div class="ThemeButton_circle__mWPxD ThemeButton_business__J6vvT">
        <div class="ThemeButton_innerCircle__qL1xU" style={{transform:"none"}}>
    
        <div className="cont_principal">
      <div className="cont_centro">
        <div
          className={`cont_circle ${isDay ? 'cont_circle_noche' : 'cont_circle_dia'}`}
        >
          <div className="sun"></div>
          <div className="moonx">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g transform="translate(0,-1028.3622)">
                <path
                  style={{ fill: '#ffffff' }}
                  d="m 12.5,1051.8622 a 11.5,11.5 0 0 0 9.93359,-5.7305 11.5,11.5 0 0 1 -10.14844,-11.4121 11.5,11.5 0 0 1 1.56446,-5.7676 11.5,11.5 0 0 0 -1.34961,-0.09 11.5,11.5 0 0 0 -11.5,11.5 11.5,11.5 0 0 0 11.5,11.5 z"
                />
              </g>
            </svg>
          </div>

          <div className="cont_olas_bottom">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="428"
    height="140"
    viewBox="0 0 428 140"
  >
    <g style={{ fill: '#ffffff', fillOpacity: 0.786 }}>
      <path
        d="m 0,1028.2403 c 23.31769,-8.6016 55.72675,-17.4895 79.04444,-18.1827 11.2904,-0.3156 26.68128,7.4409 42.09759,11.1455 15.41631,3.7046 26.81743,2.8522 40.22985,2.4916 12.95522,-0.4126 28.2055,-6.151 52.31482,-14.5357 l -0.644,30.193 -213.09500238,0.016 z"
      />
      <path
        d="m 426.18304,1015.7276 c -26.34815,1.5 -54.33844,-0.6778 -66.03938,-1.876 -12.30054,-1.4521 -31.28361,-7.4363 -46.76147,-11.8498 -9.13204,-2.60397 -38.62494,-3.50794 -51.85031,-2.30725 -12.95522,0.97645 -28.22921,4.43235 -47.85598,9.47015 L 213.04683,1039.38 425,1039.343 Z"
      />
      <rect
        y="1039.3678"
        x="-0.052302379"
        height="96.800781"
        width="425.14133"
        style={{ fill: '#ffffff', fillOpacity: 0.786 }}
      />
    </g>
    <g style={{ fill: '#ffffff', fillOpacity: 0.781 }}>
      <path
        style={{ fill: '#ffffff', fillOpacity: 0.781 }}
        d="m 425.9497,1038.22 c 26.34814,1.5 45.12014,-1.2008 56.31601,-2.0203 12.17428,-1.1995 37.78171,-5.1492 53.25957,-9.5626 9.13204,-2.604 41.52933,-12.7208 54.88097,-12.7076 13.04897,0.025 62.82855,10.2944 82.45532,15.3322 l -40.41163,23.1005 -206.50024,0 z"
      />
      <path
        style={{ fill: '#ffffff', fillOpacity: 0.781 }}
        d="m 852.0083,1015.8985 c -25.46427,-6.2025 -64.78376,0.7136 -86.08114,6.2076 -11.66922,3.4725 -20.60985,4.9409 -36.02616,8.6454 -35.32671,8.3971 -50.21019,0.1889 -57.05774,-1.4881 l -40.29957,23.0988 219.45631,0 z"
      />
      <rect
        width="426.01624"
        height="83.800438"
        x="425.94769"
        y="1052.3678"
        style={{ fill: '#ffffff', fillOpacity: 0.781 }}
      />
    </g>
  </svg>
</div>
   <div className="cont_olas_bottom">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="428"
        height="140"
        viewBox="0 0 428 140"
        version="1.1"
        id="svg4185"
      >
        <g transform="translate(0,-912.36216)">
          <g
            transform="translate(0.05230238,-83.806076)"
            style={{ fill: "#ffffff", fillOpacity: 0.78604653 }}
          >
            <path
              d="M0 1028.2403c23.31769-8.6016 55.72675-17.4895 79.04444-18.1827 11.2904-.3156 26.68128 7.4409 42.09759 11.1455 15.41631 3.7046 26.81743 2.8522 40.22985 2.4916 12.95522-.4126 28.2055-6.151 52.31482-14.5357l-.644 30.193-213.09500238.016z"
              style={{ fill: "#ffffff", fillOpacity: 0.78604653, stroke: "none" }}
            />
            <path
              d="M426.18304 1015.7276c-26.34815 1.5-54.33844-.6778-66.03938-1.876-12.30054-1.4521-31.28361-7.4363-46.76147-11.8498-9.13204-2.60397-38.62494-3.50794-51.85031-2.30725-12.95522.97645-28.22921 4.43235-47.85598 9.47015L213.04683 1039.38 425 1039.343z"
              style={{ fill: "#ffffff", fillOpacity: 0.78604653, stroke: "none" }}
            />
            <rect
              y="1039.3678"
              width="425.14133"
              height="96.800781"
              style={{ fill: "#ffffff", fillOpacity: 0.78604653, stroke: "none" }}
            />
          </g>
          <g transform="matrix(1.0046942,0,0,1,-427.94717,-83.806076)">
            <path
              d="M425.9497 1038.22c26.34814 1.5 45.12014-1.2008 56.31601-2.0203 12.17428-1.1995 37.78171-5.1492 53.25957-9.5626 9.13204-2.604 41.52933-12.7208 54.88097-12.7076 13.04897.025 62.82855 10.2944 82.45532 15.3322l-40.41163 23.1005-206.50024 0z"
              style={{ fill: "#ffffff", fillOpacity: 0.78139535, stroke: "none" }}
            />
            <path
              d="M852.0083 1015.8985c-25.46427-6.2025-64.78376.7136-86.08114 6.2076-11.66922 3.4725-20.60985 4.9409-36.02616 8.6454-35.32671 8.3971-50.21019.1889-57.05774-1.4881l-40.29957 23.0988 219.45631 0z"
              style={{ fill: "#ffffff", fillOpacity: 0.78139535, stroke: "none" }}
            />
            <rect
              width="426.01624"
              height="83.800438"
              x="425.94769"
              y="1052.3678"
              style={{ fill: "#ffffff", fillOpacity: 0.78139535, stroke: "none" }}
            />
          </g>
        </g>
      </svg>
    </div>
        </div>
      </div>
  
    </div>
        </div>
    </div>
    <div className="ThemeButton_circle__mWPxD"><svg width="33" height="24" viewBox="0 0 33 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ opacity: 0, transform: 'scale(0) rotateZ(180deg)' }}>
            <path d="M0.0847168 5.3832V3.01857C2.50231 1.02367 5.65135 -0.363579 8.86162 0.084392C11.2581 0.375096 13.2971 1.5696 15.3343 2.76309C16.3354 3.34957 17.3364 3.93597 18.3789 4.41457C22.5667 6.46697 27.3195 8.57708 32.0847 7.43858V9.39551C26.013 10.2539 20.2637 7.48881 15.1081 4.67416C14.722 4.46078 14.3367 4.23635 13.9495 4.01087C12.1225 2.94661 10.2546 1.85858 8.07764 1.79934C4.97907 1.54396 2.14163 3.24357 0.0847168 5.3832Z" fill="currentColor"></path>
            <path d="M32.0847 23.8301C29.9657 24.0882 27.792 24.0908 25.7128 23.5662C21.0315 22.4671 16.7744 20.1391 12.6719 17.742C10.6094 16.5242 8.08609 15.8641 5.70046 16.4496C3.50567 16.9402 1.62917 18.2768 0.0847168 19.8384V17.5108C1.74676 16.1314 3.6913 15.0194 5.85776 14.6463L5.85494 14.6435C8.48225 14.0939 11.2191 14.8755 13.4923 16.19C16.9401 18.1839 20.4526 20.1529 24.2797 21.368C26.7835 22.1571 29.4822 22.541 32.0847 21.9885V23.8301Z" fill="currentColor"></path>
            <path d="M32.0847 16.566V14.7578C27.2261 15.7556 22.4524 13.6751 18.2046 11.5699C17.2213 11.1077 16.2683 10.5484 15.3136 9.98796C13.0735 8.67315 10.8234 7.35249 8.14791 7.26729V7.26177C5.16235 7.04747 2.34368 8.41297 0.0847168 10.238V12.6333C1.75099 10.9404 3.81096 9.46608 6.23709 9.12584C8.43447 8.77236 10.6881 9.34677 12.5763 10.4569C16.5215 12.7629 20.5846 14.997 25.044 16.1817C27.334 16.7918 29.7409 16.9616 32.0847 16.566Z" fill="currentColor"></path>
        </svg></div>
</button>
                        </div>
                    </div>
                </div>
  
            </footer>
        </>
    )
}

export default FooterFour;

import React, { useContext } from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import animationData from '../assets/images/ZdBrTv9nso.json';
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterFour from '../common/footer/FooterFour';
import LanguageContext from '../LanguageContext';
import { useStore } from "context/store";
import ShipsGoMap from './containertracker';
function Shipsships() {
  const { translations } = useContext(LanguageContext);
  const { isDay, setIsDay } = useStore();

  return (
    <>
      <div className="page-wrapper">
        <HeaderTopBar />
        <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
        <ShipsGoMap />

        <FooterFour />
      </div>
    </>
  );
}

export default Shipsships;
